@import '../base/colors';

.uk-pagination > * > * {
  padding: 1px 10px !important;
}

.MuiPagination-ul {
  justify-content: center;

  li:first-child .MuiPaginationItem-page {
    background-color: transparent;
  }

  li:last-child .MuiPaginationItem-page {
    background-color: transparent;
  }

  .MuiPaginationItem-page {
    margin-top: 5px;
    color: $primary;
    background-color: #eee;
    font-weight: 600;
    &.Mui-selected {
      background-color: $primary !important;
      color: $white;
    }
  }
}
