@import '../base/colors';

.uk-badge {
  background: $red !important;
  margin: -15px 0 0 2px !important;

  &.hulp-list {
    margin: -4px 0 0 2px !important;
    background: $primary !important;
  }
  &.all-read {
    background: $green !important;
  }
}

$rhvRegistrationBadgeSize: 23px;
.uk-badge.complete-rhv-registration-tab {
  background: $primary-disabled !important;
  margin: -4px 0 0 2px !important;
  width: $rhvRegistrationBadgeSize;
  height: $rhvRegistrationBadgeSize;

  &.registration-tab-active {
    background: $primary !important;

  }
}
