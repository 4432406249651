@import '../base/colors';
@import '../base/constants';
.overview-organisation-title {
  @media only screen and (max-width : 960px) {
    .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
      margin: 15px 0;
    }
    .organisation-details-button-top {
      margin-top: 10px !important;
    }
  }
  @media only screen and (max-width : 960px) {
    .organisation-name {
      margin-top: 5px;
    }
    padding-left: 20px;
    margin-bottom: 10px !important;
  }
  @media only screen and (min-width : 961px) {
    padding-left: 30px;
    margin-bottom: 10px !important;
  }
  font-weight: 400;
  .fa-chevron-left {
    margin-right: 30px;
    margin-top: 11px;
    font-size: 23px;
  }
}

.overview-organisation-name {
  strong {
    color: $primary !important;
  }
  img.marker-indicator {
    display: none !important;
    width: 15px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
img.marker-indicator {
   display: none !important;
   width: 15px;
   position: absolute;
   top: 0;
   right: 0;
 }
$portion: 0.5vw;
.dossier-container {
  margin: 0 auto !important;
 // max-width: 1000px;
  &.list-container-overview-organisation {
    margin: 0 auto !important;
    .client-search-for-company-form-wrapper {
      margin: 20px 0;
      .client-search-for-company-autocomplete {
        margin-left: 40px;
      }
      .client-search-for-company-form-hr {
          margin-left: 0 !important
      }
    }

    .rhv-company-details-for-client-company-outer-wrapper {
      position: relative;

      .buffer {
        height: 158px;
        max-width: 95vw;
      }

      .rhv-company-details-for-client-company-wrapper {
        position: relative;
        width: 95vw;
        .rhv-company-details-for-client-company-name-sub-header {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 67px;
          width: 100%;
          background-color: $assignee-tab-active-background;

          h4 {
            margin: 30px;
          }

          .organisation-details-button {
            margin-right: 30px;
          }
        }

        .organisation-details-wrapper {
          margin-left: 0;
          border: thin red solid;
          @if $scroll-rhv-info-for-client {
            overflow-y: auto;
            max-height: calc(95vh - 180px);
          }

          @media (max-width: 1199px) {
            padding-left: 0 !important;
          }
          .organisation-details-inner {
            margin-left: 0 !important;

            display: flex;
            .organisation-details {
              margin-left: 0 !important;


              &.organisation-details-left-block {
              }

              @media (min-width: 1200px) {
                &.organisation-details-left-block {
                  max-width: 61%;
                  padding: 0 !important;
                  .organisation-details-first-block {

                    .organisation-details-first-block-inner {
                      display: flex;

                      .organisation-details-left-block-description {
                        width: calc(42vw - 18px);
                        padding-right: 18px;
                      }
                      .organisation-details-left-block-qualities {
                        width: calc((17.5vw) - 64px);
                        padding: 0 32px !important;
                        h4 {
                          color: $primary;
                        }
                      }
                    }
                  }

                  .organisation-details-second-block {
                    display: flex;


                    .organisation-details-second-block-inner {
                      display: flex;

                      .organisation-details-second-block-inner-photo {
                        width: calc(22.5vw - 32px);
                        //border: 1px yellow solid  ;
                        padding: 0 32px 0 0;
                        .photo-wrapper {
                          border-radius: 10px;
                          max-width: 100% !important;
                          max-height: 25vh;
                          border: 1px #CCC solid;
                          height: 25vh;
                          margin-bottom: 10px;
                          display: flex;
                          justify-content: center;
                          justify-items: center;
                          align-items: center;
                          align-content: center;
                          img {
                            padding: 10px;
                            max-width: 100% !important;
                            max-height: 22vh;
                            height: 22vh;
                            object-fit: contain;
                            overflow: hidden;
                          }
                        }
                      }

                      .organisation-details-second-block-inner-contact-info {

                        //border: 1px blue solid  ;
                        display: flex;
                        flex-direction: column;
                        width: (37 + 33) * $portion;
                        img {
                          padding: 0 !important
                        }

                        .organisation-details-contact-details-title {
                          //width: (37 + 33) * $portion;
                          padding-left: 32px;
                        }
                        .organisation-details-second-block-inner-contact-wrapper {

                          display: flex;
                          width: 35vw;
                          .organisation-details-contact-details-map {
                            width: calc(18.5vw - 64px);
                            padding: 0 32px !important;
                            #g-map {
                              border: 1px #CCC solid;
                              max-height: 25vh;
                              height: 25vh;
                            }

                          }
                          .organisation-details-contact-details-list {
                            width: calc(16.5vw - 64px);
                            padding: 0 32px !important;
                            a.google-link  {
                              text-decoration: none !important;

                              @media only screen and (min-width: 1660px) {
                                font-size: 18px !important;
                                padding: 4px 20px !important;
                              }
                              @media only screen and (min-width: 1550px) and (max-width: 1659px) {
                                font-size: 18px !important;
                                padding: 3px 10px !important;;
                              }
                              @media only screen and (min-width: 1350px) and (max-width: 1549px) {
                                font-size: 17px !important;
                                letter-spacing: -1px;
                                padding: 3px 10px !important;;
                              }
                              @media only screen and (min-width: 1000px) and (max-width: 1349px) {
                                font-size: 16px !important;
                                letter-spacing: -1px;
                                padding: 3px 10px !important;;
                              }
                            }

                            //border: 1px rebeccapurple solid;

                            //border: 1px darkmagenta solid;
                          }
                        }
                      }
                    }
                  }


                }

              }
              @media (max-width: 1199px) {
                &.organisation-details-left-block {
                  display: flex;
                  .organisation-details-first-block {

                    .organisation-details-first-block-inner {

                      .organisation-details-left-block-description {
                      }
                      .organisation-details-left-block-qualities {
                        margin-top: 40px;
                        h4 {
                          color: $primary;
                        }
                      }
                    }
                  }

                  .organisation-details-second-block {

                    .organisation-details-second-block-inner {

                      .organisation-details-second-block-inner-photo {
                        margin-top: 40px;
                        img {
                          width: 250px;
                          height: 130px;
                          object-fit: contain;
                          overflow: hidden;
                          border-radius: 10px;
                        }
                      }

                      .organisation-details-second-block-inner-contact-info {
                        margin-top: 40px;

                        img {
                          padding: 0 !important
                        }

                        .organisation-details-contact-details-title {

                        }
                        .organisation-details-second-block-inner-contact-wrapper {
                          display: flex;
                          .organisation-details-contact-details-map {
                            padding: 0 32px 0 0 !important;
                            width: 50%;
                            #g-map {
                              height: 300px;
                              max-height: 300px;
                            }
                          }
                          .organisation-details-contact-details-list {
                            padding: 0 32px !important;
                            a.google-link  {
                              text-decoration: none !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            @media (min-width: 1200px) {


              .organisation-details-right-block {
                //b  order: thin pink solid;
                width: calc(36% - 32px);
                padding: 0 16px;

                h4.organisation-details-contact-details-header {
                  color: $primary;
                  padding-left: 16px;
                }

                .rhv-terms-of-service-section-wrapper {
                  .rhv-terms-of-service-section-line-wrapper {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px;

                    .term-of-service-name {
                      display: flex;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      width: calc(16.5vw - 48px);
                      padding: 0 16px;
                      font-size: 16px !important;

                      .terms-of-service-select-info-icon-wrapper {
                        position: relative;
                        bottom: -2px;
                        left: 5px;

                        svg {
                          margin: 0 !important;
                        }
                      }
                    }

                    .term-of-service-markdown-value,
                    .term-of-service-value {
                      word-break: normal;
                      width: calc(19.5vw - 48px);
                      font-size: 16px !important;
                      padding: 0 16px;

                      code {
                        word-break: normal;
                        white-space: normal;
                      }
                    }

                    .term-of-service-value {
                      font-weight: 600;
                    }
                  }
                }
              }
            }
            @media (max-width: 1199px) {
              .organisation-details-right-block {
                margin-top: 40px;
                padding: 0 16px;
                margin-bottom: 40px;

                h4.organisation-details-contact-details-header {
                  color: $primary;
                  padding-left: 16px;
                }

                .rhv-terms-of-service-section-wrapper {
                  .rhv-terms-of-service-section-line-wrapper {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px;

                    .term-of-service-name {
                      display: flex;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      width: 40%;
                      padding: 0 16px;
                      font-size: 16px !important;

                      .terms-of-service-select-info-icon-wrapper {
                        position: relative;
                        bottom: -2px;
                        left: 5px;

                        svg {
                          margin: 0 !important;
                        }
                      }
                    }

                    .term-of-service-markdown-value,
                    .term-of-service-value {
                      width: 59%;
                      font-size: 16px !important;
                      padding: 0 16px;
                    }

                    .term-of-service-value {
                      font-weight: 600;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }

    @media only screen and (min-width: 961px) {
      margin: 60px 50px 95px 50px;
      .uk-card-body {
        padding: 20px 0 20px 30px !important;
      }
      .uk-search-large .uk-search-input {
        font-size: 18px !important;
      }
      svg {
        height: 20px !important;
        margin-top: 20px !important;
      }
      .client-search-for-company-icon {
        svg {
          margin-top: 0 !important;
        }
      }
      .uk-search-input {
        margin-top: 20px !important;
      }
    }
  }
}

.rhv-company-list-overview-for-client-dialog-wrapper.MuiModal-root.MuiDialog-root {
  width: 100vw;
  .MuiPaper-root {
    margin: auto !important;
  }
  .gi-law-close-modal-button {
    z-index: 500000;
  }
  border-radius: 10px;
  .MuiDialog-container {
    .MuiPaper-root {
      overflow-y: hidden;
      border-radius: 10px;
      max-width: 95vw;
      max-height: 95vh;
      margin:  auto !important;
      .dossier-container {
        border-radius: 18px;
        padding: 0;
        .rhv-company-details-for-client-company-outer-wrapper {
          position: relative;
          display: flex;

          padding: 0;
          .card-organisation-header-overview-details-header {
            position: absolute;
            border-radius: 10px;
            border: none !important;
            top:0;
            left: 0;
            right: 0;
            height: 67px;
            z-index: 5;
            .card-organisation-header-overview-details-inner {
              display: flex;
              align-content: center;
              align-items: center;
              justify-content: space-between;
              .gi-law-close-rhv-details-modal-button {
                img {
                 max-width: 100% !important;
                }
              }
            }
          }

        }
      }
    }
  }
}

.card-organisation-header-overview {
  .text-middle-bold {
    color: $white;
  }
  .second-text-header {
    padding-left: 52px;
  }
  &.card-organisation-header-overview-details-header{
    background-color: transparent;
    border: none;
    .uk-card-header {
      border: none;
      border-top-right-radius: 10px;
    }
  }
}

.first-block-organisation-info {
  max-height: 45px !important;

  margin-top: 35px !important;
  .more-info-btn {
    margin-bottom: 5px !important;
  }
}
.organisation-info-wrapper {
  min-height: 150px !important;
}
