@import '../base/colors';
@import '../base/constants';
.rhv-company-info-window {
  display: flex;
  font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
  .rhv-company-info-window-image {
    max-width: 180px;
    max-height: 120px !important;
    overflow:hidden;
  }
  .rhv-company-info-window-data {
    margin-right: 15px;
    p {
      margin: 5px 0
    }
  }
}
$button-width: 15px;

.suitable-help-detail-map-view-wrapper {

  .hover-transparent.accent-button:hover {
    background-color: $accent-color-light !important;
  }
  z-index: -10;
  div[role="dialog"] button[title="Close"] {
    right: 10px !important;
    top: 10px !important;

    opacity: 1 !important;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  .rhv-company-info-window-google-map-marker {
    .rhv-company-info-window-google-map-marker-inner{
      margin-left: -12px;
      .rhv-company-info-window-google-map-marker-inner-wrapper {
        color: black;
        .rhv-company-info-window-google-map-marker-inner-wrapper-inner {
          padding: 13px 30px 0 30px;
          z-index: 2;
        }
        .rhv-company-info-buttons {
          padding: 13px 30px 13px 30px;
          background-color: white;
          position: relative;
          z-index: 3;
          border-top: thin $light-gray solid;
          .more-info-about-rhv-company-button {
            padding-left: 10px !important;
            padding-right: 10px !important;
            font-size: 16px !important;
          }
        }
        .react-markdown-wrapper {
          a, * {
            color: black !important;
          }
        }
      }
    }
    .rhv-type-banner {
      &.in-marker {
        font-size: 18px !important;
        font-family: "Fira Sans Condensed", Montserrat, sans-serif;
        color: $accent-color;
        font-weight: 500;
      }
      width: 340px;
      z-index: 5000;
      margin-left: -12px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      margin-top: -12px;
      padding: 15px;
      text-align: center;
      &.rechtswinkel {
        background-color: $rechtswinkel;
      }
      &.advocaat {
        background-color: $advocaat;
      }
      &.mediator {
        background-color: $mediator;
      }
      &.rechtswinkel {
        background-color: $rechtswinkel;
      }
      &.vakbond {
        background-color: $vakbond;
      }
      &.rechtsbijstandverzekeraar {
        background-color: $rechtsbijstandverzekeraar;
      }
      &.sociaal-raadslid {
        background-color: $sociaal-raadslid;
      }
      &.juridisch-loket {
        background-color: $juridisch-loket;
      }
    }
    .rhv-company-info-window-google-map-marker-company-name {
      font-size: 20px !important;
      margin-top: 12px;
      color: #333 !important;
    }
    // map view
    .info-window-image {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;
      &.missing-photo-wrapper {
        background: $missing-photo-wrapper-background;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button{
      }
      img{
        width: 100%;
        height: 130px;
        object-fit: scale-down;
        overflow: hidden;
        max-width: 100% !important;
        &.missing-photo {
          opacity: 0 !important;
        }
      }
    }

    // map view
    .rhv-company-info-window {
      .rhv-company-profile-area {
        color: $medium-text-color;
        max-height:  $rhv-card-height-closed;
        min-height:  0;
        transition: max-height 1s ease-out;
        overflow-y: hidden;
        &:hover {
          max-height: $rhv-card-height-open;
          transition: max-height 1s ease-in;
          overflow-y: auto;
          margin-right: -10px;
        }
        .react-markdown-wrapper {
          margin: 10px 0;
          * ,
          a{
            color: $medium-text-color;
          }
        }
      }
    }
  }

}
.current-company-moused-over {
  position: relative;
    img.marker-indicator {
      display: block !important;
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
    }
}
