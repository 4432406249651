@import '../base/colors';

.uk-progress.progress-gilaw::-webkit-progress-value {
  background-color: $primary;
}
.uk-progress.progress-gilaw::-moz-progress-bar {
  background-color: $primary;
}
.uk-progress.progress-gilaw::-ms-fill {
  background-color: $primary;
}

.gi-law-linear-progress {
  margin: 20px 0 !important;
}
.rhv-final-registration-modal-window {
  .gi-law-linear-progress {
    margin: 20px 30px !important;
  }
}
