@import '../base/colors';
@import '../base/background-images';
// style={{position: "fixed", right: 0, listStyle: "none", padding: "1em", borderRadius: "10px"}}
$iconSize: 35px;
$toolbarTopMargin: 40px;
$verticalOffset: -30px;
$userBubbleSize: 30px;
$createWidth: 25px;

.assignee-right-vertical-toolbar {
  position: fixed;
  top: 10vh;
  right: 0;
  list-style: none;
  height: 75vh;
  padding: 3em 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 0;
  background-color: $white;
  text-align: center;
  box-shadow: 0 5px 15px #00000069 !important;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  z-index: 2;

  li {
    &.sidebar-active {
      background-color: $assignee-tab-active-background;

      .assignee-rv-toolbar-wrapper {
        .assignee-rv-toolbar-text-wrapper,
        .assignee-rv-toolbar-icon-wrapper {
          opacity: 0.8;
        }

      }
    }

    padding: 1em;
    margin-bottom: 5px;

    &:hover {
      background-color: $assignee-tab-hover-background;

      .assignee-rv-toolbar-wrapper {
        .assignee-rv-toolbar-text-wrapper,
        .assignee-rv-toolbar-icon-wrapper {
          opacity: 0.6;
        }
      }
    }

    .assignee-rv-toolbar-wrapper {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: relative;
      user-select: none;

      .assignee-rv-toolbar-inner-wrapper {
        display: inline-block;
        margin: auto;
        padding: 0;
        width: 41px;
        position: relative;
      }

      .assignee-rv-toolbar-badge-wrapper {
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: $red;
        color: $white;
        padding: 0;
        margin: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        .assignee-rv-toolbar-badge {
          position: absolute;
          line-height: unset;
          right: 5px;
          top: 1px;
          font-weight: 600;
          font-size: 10px !important;
        }

        &.tasks {
          font-size: 10px !important;
        }

        &.messages {

        }
      }

      .assignee-rv-toolbar-text-wrapper {
        color: $primary;
        font-weight: 500;
        font-size: 12px !important;
        padding: 0;
        margin: 0;
        opacity: 0.4;
        user-select: none;
      }


      .assignee-rv-toolbar-icon-wrapper {
        padding: 0;
        margin: 0;
        color: $primary;
        opacity: 0.4;

        img {
          padding: 0;
          margin: 0;
          max-width: 100% !important;
          height: $iconSize;
          width: $iconSize;
        }

        svg {
          padding: 0;
          margin: 0;
          height: $iconSize;
          width: $iconSize;
        }

        &.assignee-rv-toolbar-icon-wrapper-logbook {
          margin-bottom: 6px;
          margin-top: - 6px;
        }

        &.assignee-rv-toolbar-icon-wrapper-overview {
          margin-bottom: 2px;
          margin-top: - 2px;

        }
      }

    }
  }
}

.assignee-toolbar-header {
  display: flex;
  align-items: center;
  background-color: $assignee-tab-active-background;
  right: 110px;
  position: fixed;
  top: 11vh;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 24%;
  box-shadow: -5px -5px 7px -1px rgba(0, 0, 0, 0.04) !important;

  z-index: 2;

  .close-all {
    text-align: center;
    width: 18px;
    padding: 2px 1px 2px 3px;
    font-size: 22px;
    cursor: pointer;
  }

  .inner-content {
    font-size: 14px !important;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    justify-items: flex-end;
    align-items: flex-end;

    .assignee-vt-input-wrapper {
      display: flex;
      justify-content: flex-end;
      position: relative;
      align-items: center;
      width: 60%;

      .assignee-vt-input-search-icon {
        font-size: 14px !important;
        position: absolute;
        left: 6px;
        opacity: 0.2;
      }

      input {
        border-radius: 3px;
        padding: 5px 5px 5px 25px;
        background-color: $white;
        border: none;
        width: 100%;

        &::placeholder {
          color: #CCCCCC;
        }
      }
    }
  }
}


.uk-button.uk-button-secondary.hover-dark-purple {
  &.thin-button {
    font-size: 14px !important;
    line-height: 16px !important;
    max-height: 25px;

  }

  &.assignee-toolbar-overview-equal-width-button {
    min-width: 10em;
  }

  &.thinner-button {
    font-size: 14px !important;
    line-height: 16px !important;
    height: 32px;

  }
}

.assignee-toolbar-content {
  background-color: $assignee-tab-active-background;
  z-index: 1;
  &:not(.notes):not(.messages):not(.tasks) {
    overflow-y: scroll;
    overflow-y: -moz-scrollbars-vertical;
  }
  .assignee-toolbar-widgets-wrapper {
   // background-color: $white;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;


    @media only screen and (min-width: 1450px) and (max-width: 1600px) {
      zoom: 90%;
    }
    @media only screen and (min-width: 1350px) and (max-width: 1449px) {
      zoom: 80%;
    }
    @media only screen and  (max-width: 1349px) {
      zoom: 70%;
    }
  }
  &.notes, &.messages, &.tasks {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  border-top: thin $white solid;
  position: fixed;
  top: 11vh;
  right: 110px;
  width: 23vw;
  min-width: 280px;
  min-height: calc(85px + 75vh);
  max-height: calc(85px + 75vh);
  border-bottom-left-radius: 10px;
  box-shadow: 0 5px 15px #00000069 !important;

  .top-offset-bolster {
    height: 37px;
    border-bottom: thin $white solid;
  }

  .overview-toolbar-hr {
    border-color: white;
    margin: 0;
  }

  .vertical-toolbar-empty-list-placeholder {
    margin-top: 10px;
    padding-left: 18px;
    font-size: 14px !important;

    &.overview-page {
      padding-left: 14px !important;
    }
  }

  .assignee-toolbar-overview-sub {
    margin-top: 10px;
    min-height: 17vh;

    &.inner-content {
      padding: 0 1em;
    }

    .assignee-toolbar-overview-item-hr {
      margin: 0;
    }

    .assignee-toolbar-overview-sub-header {
      display: flex;
      justify-content: space-between;

      margin-bottom: 1px;

      p {
        margin: 0;
        color: $primary;
        font-weight: 500;
      }
    }
  }

  &.logbook {
    .assignee-toolbar-logbook-item-hr {
      margin: 0;
    }
  }

  &.notes {
    .assignee-toolbar-submit-note-form {
      display: flex;
      flex-direction: column;
      margin: 14px;

      textarea {
        font-size: 16px !important;
        padding: 10px;
        line-height: 24px;
        color: #666666;
        width: 100%;
        border-radius: 10px;
        border: thin $white solid;
        min-height: 100px;
      }

      .paperclip-mailbox {
        background-color: $white;
        right: 80px;
        bottom: 0;
        top: unset;
      }

      .choose-visibility-wrapper {
        display: flex;
        font-size: 14px !important;
        margin-top: 10px;
        align-items: center;

        .switch {

          margin-right: 5px;
        }

        .info {
        }
      }
    }
  }

  &.tasks {
    padding-top: 0;

    .assignee-toolbar-content-tasks {
      min-height: 50vh;
      max-height: 75vh;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 200px;

      &.form-now-submitting {
        cursor: progress;

        .assignee-toolbar-tasks-title.uk-accordion-title {
          cursor: progress !important;

        }
      }

      .assignee-toolbar-submit-task-form-wrapper {
        bottom: 0;
        right: 0;
        position: absolute;
        width: 100%;
        background-color: $assignee-tab-active-background;
        border-top: thin $white solid;

        .assignee-toolbar-create-new-task-form {
          margin: 10px 14px;
          font-size: 14px !important;

          .assignee-toolbar-create-new-task-form-deadline,
          .assignee-toolbar-create-new-task-form-assignee,
          .assignee-toolbar-create-new-task-form-task {
            padding-top: 7px;
            font-size: 14px !important;

            input,
            textarea.uk-textarea {
              font-size: 14px !important;

              &:focus {
                border-color: $light-gray;
              }
            }

            .rhv-colleague-autocomplete-search-icon {
              padding-left: 11px;
              padding-right: 10px;
            }

            .assignee-toolbar-create-new-task-form-task-input {

              resize: none;
              border-radius: 4px;
              height: 35px;

              &::placeholder {
                color: #CCCCCC;
              }
            }

            .assignee-toolbar-create-new-task-form-assignee-autocomplete {
              background-color: $white;
              height: 33px;
              overflow-y: hidden;

              .rhv-colleague-autocomplete-root {
                .MuiInput-root {
                  .MuiAutocomplete-endAdornment {
                    display: block;
                  }
                }

                display: block !important;

                #rhv-colleague-autocomplete-id {
                  text-overflow: ellipsis;
                  margin-right: 55px;

                  &::placeholder {
                    font-size: 14px !important;
                    color: #666;
                  }

                  color: #666;
                }
              }
            }

            .label {
              font-size: 14px !important;

              span {
                font-size: 14px !important;
                color: $gray;
              }
            }
          }

          .assignee-toolbar-create-new-task-form-deadline {
            .date-send-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;

              div.uk-width-expand.assignee-toolbar-create-new-task-form-deadline-wrapper {
                display: flex;
                flex-direction: column-reverse;
                margin-right: 5px;

                .MuiFormControl-root.MuiTextField-root {
                  height: 35px;
                  max-height: 35px;
                }

                .MuiOutlinedInput-root.MuiInputBase-root {
                  z-index: 50;
                  color: #666 !important;

                  .MuiOutlinedInput-input.MuiInputBase-input {
                    z-index: 50;
                    padding: 7.5px 10px 7.5px 0;
                  }

                  .MuiInputAdornment-root {
                    cursor: pointer;
                    z-index: 50;
                    margin-right: 0;

                    .MuiIconButton-root {
                      font-size: 14px !important;
                      width: 39px;
                      height: 39px;
                    }
                  }

                  .MuiOutlinedInput-notchedOutline {
                    border: thin $light-gray solid;
                    background-color: $white;
                  }

                  &.Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                      border: thin $light-gray solid;
                    }
                  }
                }

              }
            }
          }
        }
      }

      .assignee-toolbar-content-tasks-wrapper.uk-accordion {
        list-style: none;
        margin: 0 !important;

        .bolster {
          height: 220px;
        }

        .assignee-toolbar-content-tasks-accordion-outer-hr {
          margin: 0;
          border-top-color: $white;
          pointer-events: none;
        }

        .assignee-toolbar-tasks-title {
          &.uk-accordion-title {
            &::before {
              float: left;
              margin-left: 1px;
              margin-right: 10px;
              display: flex;
              background-position-x: left;
              text-align: left;
              background-image: $rightArrowImage;
            }

            &.empty-list {
              cursor: default;

              &::before {
                background-image: $emptyArrowImage;
                background-size: 8px;
              }
            }
          }

          display: flex;
          align-items: center;
          height: 48px;
          padding: 0 14px;
          font-size: 14px;
          text-decoration: none !important;

          span {
            font-size: 14px !important;
            color: #ccc;
          }
        }

        .uk-open {
          .assignee-toolbar-tasks-title.uk-accordion-title {
            &::before {
              background-image: $downArrowImage;
            }

            &.empty-list::before {
              background-image: $emptyArrowImage;
              background-size: 8px;
            }
          }
        }

        .assignee-toolbar-tasks-content {
          list-style: none;
          margin: 0;
          padding-left: 0;

          .assignee-toolbar-task-item {
            display: flex;
            align-items: flex-start;
            font-size: 14px !important;
            padding: 9px 14px;

            &.task-selected {
              background-color: #d6e0f2;
            }

            .completed-indicator {
              font-size: 14px !important;
              margin-right: 8px;
              cursor: pointer;
              height: 30px;
              display: flex;
              align-items: center;

              &.this-now-submitting {
                cursor: progress !important;

                .uk-radio {
                  cursor: progress !important;
                }
              }

              .uk-radio {
                &:focus {
                  border-color: #ccc;
                }

                width: 13px !important;
                height: 13px !important;

                &:checked {
                  background-color: transparent !important;
                  background-size: 15px !important;
                  background-image: $checkedTaskImage;

                  &:focus {
                    border-color: transparent !important;
                  }
                }

                background-size: 14px;
              }

              &.completed-indicator-completed {
                font-size: 14px !important;
                color: $green;
              }
            }

            .task-content-text {
              padding-top: 4px;
              font-size: 14px !important;
              position: relative;
              word-break: break-all;

              textarea.uk-textarea {
                font-size: 14px !important;
                border-color: $light-gray;
                border-radius: 4px;
                resize: none;
                height: 100px;
              }

              .task-content-submit-button-wrapper {
                position: absolute;
                bottom: 5px;
                right: 5px;
                font-size: 14px !important;

                .thin-button {
                  padding: 4px 8px !important;
                }
              }
            }

            .date-time-shower {
              display: flex;
              font-size: 14px !important;
              margin-right: 10px;
              margin-left: 10px;
              height: 30px;
              align-items: center;

              .no-date-icon {
                color: $primary;
              }
            }

            .date-time-picker {
              max-width: 0;
              max-height: 0;
            }

            .assignee-toolbar-task-item-creator-bubble {
              position: relative;

              .assignee-toolbar-task-item-creator-bubble-inner {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 30px;
                height: 30px;
                background-color: $white;
                border-radius: 50%;

                span {
                  font-size: 12px !important;
                }
              }
            }

            .assignee-toolbar-task-item-delete-item {
              min-width: 20px;
              text-align: right;
              cursor: pointer;
              height: 30px;
              display: flex;
              align-items: center;
              margin-left: 10px;
              color: $primary;

              &.this-now-submitting {
                cursor: progress;
              }
            }
          }
        }

        .assignee-toolbar-list-item:not(.uk-open) {
          .date-time-picker-mui-component {
            display: none;
          }
        }
      }

    }

  }

  &.messages {
    .assignee-toolbar-content-messages-wrapper {
      max-height: 75vh;
      overflow-y: scroll;
      overflow-x: hidden;

      .assignee-toolbar-content-messages {
        //margin-bottom: 200px;
      }

      .bolster {
        height: 200px;
      }
    }

    .assignee-toolbar-message-send-message {
      z-index: 200;
      position: absolute;
      display: flex;
      bottom: 0;
      flex-direction: column;
      padding: 14px;
      border-top: thin $white solid;
      background-color: $assignee-tab-active-background;
      .uploaded-file-list-autocomplete-wrapper {
        left: 10px;
        bottom: 10px;
      }

      .message-box-wrapper.uk-margin {
        &.disabled-message-box-wrapper {
          pointer-events: all;
          textarea {
            pointer-events: none;
          }
          button {
            pointer-events: none;
          }
        }
        padding: 0;
        margin: 0 !important;
        border: none;
        position: relative;
        background-color: $white;
        border-radius: 10px;
        textarea {
          height: 181px !important;;
          min-height: 181px !important;
          margin-bottom: 0 !important;
        }
        .assignee-toolbar-widgets-wrapper{

          position: absolute;
          bottom: 0;
        }
        &.message-with-attachments {
          textarea {
            height: 131px !important;;
            min-height: 131px !important;
            margin-bottom: 50px !important;
          }
        }
        .message-box {
          border-radius: 10px;
        }

        .paperclip-mailbox {
          right: 105px;
          z-index: 40;
          bottom: 10px;
          top: unset;
          background-color: $white;
        }

        .message-box-file-upload-list {
          position: absolute;
          right: 15px;
          top: 30px;
          max-height: 40px;
          background-color: $white;
          overflow-y: scroll;
        }

        .button-wrapper {
          position: absolute;
          margin: 0;
          right: 12px;
          bottom: 10px;
          z-index: 40;

          button {
            margin: 0;
            font-size: 16px !important;
            padding: 2px 8px !important;
            line-height: 25px !important;
          }
        }
      }
    }
  }
}

.assignee-sidebar-open {
  .hulp-card.show-card.card-bm {
    margin-left: 0;
    margin-right:  calc(23vw + 110px)  !important;
    @media only screen and (max-width : 1000px) {
      margin-right:  390px;
    }
  }
}

.assignee-toolbar-logbook-item {
  padding: 0 1em;
  font-size: 14px;

  .content-text {
    color: $assigneeLogbookColor;
  }
}


.assignee-toolbar-notes-item {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1em;
  padding: 1em;
  background-color: $white;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;

  .assignee-toolbar-notes-attachment-list {
    list-style: none;
    margin: 5px 0 0 0;
    padding-left: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    right: -1px;
    bottom: -1px;
    border-top: $createWidth solid $light-gray;
    border-right: $createWidth solid $assignee-tab-active-background;
    border-top-left-radius: 4px;
  }

}

.assignee-toolbar-content-notes {
  min-height: 50vh;
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  .assignee-toolbar-content-notes-inner {
    margin-bottom: 237px;
  }

}

.assignee-toolbar-submit-note-form-wrapper {
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: $assignee-tab-active-background;
  border-top: thin $white solid;
  //  border-top: thin $white solid;
}

.assignee-toolbar-note-header,
.assignee-toolbar-submit-note-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .assignee-toolbar-submit-note-form-inner {
    position: relative;
    display: flex;
    background-color: $white;
    border-radius: 10px;
    
    .button-wrapper {
      z-index: 10;
    }

    .notes-file-upload-list {
      z-index: 9;
      background-color: $white;
      position: absolute;
      margin: 0;
      padding: 5px;
      top: 10px;
      right: 20px;
      list-style: none;
      font-size: 14px;
      max-height: 65px !important;
      text-align: right;

      &.with-scroll {
        overflow-y: scroll;
      }

      .delete-note-attachment {
        font-size: 14px !important;
      }
    }

    .button-wrapper {
      position: absolute;
      bottom: 10px;
      right: 25px;

      .attachments {
        margin-right: 5px;
        font-size: 16px !important;
        line-height: 25px !important;
        padding: 2px 8px !important;
      }

      .send-button {
        font-size: 16px !important;
        line-height: 25px !important;
        padding: 2px 8px !important;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .author {
      font-weight: 600;
      margin-right: 15px;
    }

    .time {
      margin-right: 15px;

    }

    .date {

    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;


    .switch {
      position: relative;
      display: flex;
      justify-content: center;

      &.now-submitting {
        cursor: progress;
        pointer-events: all;
      }

      &.not-allowed {
        .MuiSwitch-root {
          .MuiSwitch-input {
            cursor: not-allowed;
          }
        }
      }

      .eye-shared {
        color: $white;
        display: flex;
        align-items: center;
        position: absolute;
        height: 24px;
        font-size: 10px;
        line-height: 24px;
        top: 0;
        left: 5px;
        pointer-events: none;
      }

      .eye-not-shared {
        display: flex;
        align-items: center;
        height: 23px;
        font-size: 10px;
        color: #fff;
        position: absolute;
        top: 0;
        right: 5px;
        pointer-events: none;
      }

      &.not-shared {
        .eye-shared {
          display: none;
        }

        .MuiSwitch-track {
          background-color: $primary-disabled !important;
        }
      }

      &.shared {
        .eye-not-shared {
          display: none;
        }
      }

      .MuiSwitch-root {
        padding: 3px;

        .MuiSwitch-switchBase {

          .MuiSwitch-thumb {
            background-color: $white !important;
          }


          .MuiSwitch-input {
            &:disabled {
              cursor: progress;
            }
          }
        }
      }

      .MuiSwitch-track {
        background-color: $primary !important;
        opacity: 1;
        border-radius: 9px;

      }
    }

    .trash {
      padding: 0 5px;
      font-size: 17px;
      cursor: pointer;

      &.now-submitting {
        cursor: progress;
      }

      &.not-allowed {
        cursor: not-allowed;
      }
    }
  }
}

.assignee-toolbar-overview-item,
.assignee-toolbar-logbook-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  padding: 9px 14px;

  .date-text {
    max-width: 100px;
    margin-right: 10px;
  }

  .content-text {
    margin-right: 2em;
  }

  .user-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    border-radius: 50%;
    width: $userBubbleSize;
    height: $userBubbleSize;
    background-color: $white;
    font-size: 12px !important;

    p {
      margin: 0;
      text-align: center;
      width: $userBubbleSize;
      font-size: 12px !important;
      font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
      color: $primary;
    }
  }
}

.assignee-toolbar-content {
  .assignee-message-box-thread {

    padding: 0 13px;
  }
}

$speechBubbleDimension: 12px;
.assignee-message-box-thread {
  padding: 0 2em;

  .assignee-message-box-message-wrapper {
    border: thin white solid;
    background-color: $white;
    word-break: break-word;
    margin: 10px 0;
    padding: 1em;
    width: 70%;

    &.rhv-own-message {
      align-self: flex-end;
      position: relative;
      border-radius: 8px 0 8px 8px;

      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: $speechBubbleDimension * 2 solid $white;
        border-right: $speechBubbleDimension solid transparent;
        border-bottom: $speechBubbleDimension solid transparent;
        top: -1px;
        right: -$speechBubbleDimension * 2.5;
      }
    }

    &.client-message {
      position: relative;
      z-index: 25;
      border-radius: 0 8px 8px 8px;

      &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: $speechBubbleDimension solid transparent;
        border-right: $speechBubbleDimension * 2 solid $white;
        border-bottom: $speechBubbleDimension solid transparent;
        top: -1px;
        left: -$speechBubbleDimension * 2.5;
        z-index: 20;

      }
    }

    .message-body {
      font-size: 16px;
    }

    .message-header {
      display: flex;
      color: $medium-text-color !important;
      font-size: 14px;
      justify-content: space-between;

      .sender-name {
        font-weight: 600;
      }

      .time-sent,
      .date-sent {
        margin-left: 8px;
      }
    }
  }

}

.date-time-picker {
  .MuiOutlinedInput-root {
    max-width: 0;
    visibility: hidden;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
}

.assignee-toolbar-submit-note-form-wrapper {
  .assignee-toolbar-submit-note-form-inner {
    &.assignee-toolbar-submit-note-without-attachments {

      textarea {
        height: 125px !important;;
        min-height: 125px !important;
        margin-bottom: 0;
        resize: none;
      }
    }
    &.assignee-toolbar-submit-note-with-attachments{
      textarea {
        height: 75px !important;;
        min-height: 75px !important;
        margin-bottom: 50px;
        resize: none;
      }
    }
  }
}
.dossier-messagebox {

  .uploaded-file-list-autocomplete-wrapper {
    left: 10px;
    bottom: 30px;
  }
}

.uploaded-file-list-autocomplete-wrapper {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;

  .uploaded-file-list-autocomplete-inner {
    border: thin $primary solid;
    border-radius: 5px;
    font-size: 14px !important;
    .MuiAutocomplete-root {
      .MuiFormControl-root {
        .MuiInput-root:before,
        .MuiInput-root:after {
          display: none;
        }

        input {
          font-size: 16px !important;
          padding: 2px 5px 2px 10px;
        }

        .MuiAutocomplete-endAdornment {
         // display: none;
        }
      }
    }
  }
  .uploaded-file-list-autocomplete-remove-file {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: -30px;
    justify-items: center;
    align-content: center;
    justify-content: center;
    font-size: 16px !important;
    margin-right: 5px;
    height: 28px;
    width: 28px;
  }
  .uploaded-file-list-autocomplete-preview-file {
    position: relative;
    font-size: 14px !important;
    align-items: center;
    margin-left: -55px;
    justify-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 5px;
    height: 28px;
    width: 28px;
    display: flex;
    color: transparent;
    opacity: 0;
    //display: none;
    //visibility: hidden;
    &:hover {
     // visibility: visible;
      opacity: 1;
      color: $primary !important;
    }

  }
  .uploaded-file-list-autocomplete-extra-files {
    border: thin $primary solid;
    color: $primary;
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 1px;
    font-size: 14px !important;
    height: 28px;
    width: 28px;
  }


}
