// 1. Import SCSS files
@import 'base/background';
@import 'base/background-images';
@import 'base/colors';
@import 'base/font';
@import 'base/icon';
@import 'base/maps';
@import 'base/margin';
@import 'base/video';
@import 'components/accordion';
@import 'components/admin-import-rhv';
@import 'components/alert';
@import 'components/assignee-dashboard';
@import 'components/assignee-settings';
@import 'components/assignee-right-vertical-toolbar';
@import 'components/badge';
@import 'components/banner';
@import 'components/buttons';
@import 'components/cards';
@import 'components/checkbox';
@import 'components/disc';
@import 'components/dossier-mobile-client-footer';
@import 'components/dropdown';
@import 'components/errors';
@import 'components/form';
@import 'components/google-maps';
@import 'components/help-function';
@import 'components/inputfield';
@import 'components/list';
@import 'components/modal';
@import 'components/markdown';
@import 'components/navbar';
@import 'components/not-dialog-feedback';
@import 'components/office-hours-widget';
@import 'components/pagination';
@import 'components/progress-bar';
@import 'components/rhv-terms-and-conditions';
@import 'components/roll-back-suitable-help';
@import 'components/scrollbar';
@import 'components/search';
@import 'components/suitable-help';
@import 'components/tabs';
@import 'components/toasters';
@import 'components/tooltip';
@import 'components/video';
@import 'components/view-debugger';
@import 'pages/question-pages';
@import 'pages/dossier-details';
@import 'pages/dashboard';
@import 'pages/test';
@import 'components/material-ui-themes';
@import 'pages/registration-page';
@import 'pages/assignee-settings-page';
@import 'pages/dashboard';
@import 'pages/login';
@import 'pages/overview-pages-organisation';
@import 'pages/organisation-details';

// 2. Import default variables and available mixins.
@import "../../node_modules/uikit/src/scss/variables-theme.scss";
@import "../../node_modules/uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() {
  color: $button-primary-background;
}

// 4. Import UIkit.
@import "../../node_modules/uikit/src/scss/uikit-theme.scss";

.gi-law-upload-button,
.gi-law-delete-image-button {
  margin-bottom: 1px;
}

.dossier-p {
  p {
    margin: 0;
  }
}
