@import '../base/colors';

$tooltipFontSize: 12px;
$tooltipMinWidth: 15em;
$tooltipMinWidthInner: calc(15em + 5px);

.uk-tooltip {
  z-index: 5000 !important;
  background: $white !important;
  min-width: $tooltipMinWidth + 2em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  &.gi-law-gray-tooltip {
    text-align: center;
    background: $white !important;
    font-size: $tooltipFontSize;
    min-width: $tooltipMinWidth + 2em;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .uk-tooltip-inner {
      min-width: $tooltipMinWidth;
      font-size: $tooltipFontSize;
      color: #333333;
      word-break: break-word;
      margin: 5px;
    }
  }
  .uk-tooltip-inner {
    color: #333333 !important;
    margin: 15px 10px;
    font-size: $tooltipFontSize;
    font-family: 'Montserrat', 'Fira Sans Condensed', sans-serif !important;
    word-break: break-word;
  }
  &.show-add-employees {
    .uk-tooltip-inner {
      font-size: 12px;
      z-index: 900000;
      word-break: break-word;
    }
  }
}
.rhv-terms-of-service-section-tooltip {
  font-size: 16px!important;

}
.show-add-employees-tooltip-wrapper.uk-open  {
  .show-add-employees {
    display: none;
  }
}
.error-field-wrapper .uk-tooltip {
  background: $red !important;
}

.return-to-page-chevron {
  cursor: pointer;
  margin-top: -8px;
}
@media only screen and (min-width : 960px) {
  .company-department-tooltip-wrapper .uk-tooltip {
    max-width: 500px;
    padding: 10px 15px;
    border-radius: 5px;
  }
}

.uk-tooltip.uk-active.shared-with-list {

  max-width: 350px;
  padding-left: 0;
  .uk-tooltip-inner {
    margin-left: -3px;
    .title-holder {
      margin-left:15px;
    }
    ul {
      margin-top:5px;
    }
  }
}

.uk-tooltip.uk-active.gi-law-video-tooltip {
  background-color: transparent !important;

  .uk-tooltip-inner {
    background: $light-gray !important;
    padding: 3px 6px;
    margin-left: 35px;
    word-break: break-word;
    border-radius: 5px;
  }
}
