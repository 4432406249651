@import '../base/colors';

fieldset {
  border: none;
  padding: 0;

  &.disabled-for-administrator {
    .uk-input:disabled,
    .uk-select:disabled,
    .uk-textarea:disabled {
      background-color: unset;
      color: #666;

      &::placeholder {
        color: transparent;
      }
      &::-webkit-datetime-edit {
        display: none;
      }

      &[type="date"]::-webkit-datetime-edit{
        color: #999;
      }
      &[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 0.4;
      }
    }
    .submit-button,
    .client-name{
      display: none;
    }

  }
}
.MuiTextField-root * {
pointer-events: all !important;
}

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0.6;
  pointer-events: all;
}
.assignee-settings-profile-field-wrapper,
.rhv-terms-of-service-markdown-wrapper,
.rhv-terms-of-service-markdown-card-wrapper{
  &:hover {
   // box-shadow: 0 1px 4px 0 #0000001a !important;
  }
  .textarea-field-buffer {
    border: thin #e5e5e5 solid;
    width: 100%;
    border-bottom: none;
  }
  .textarea-field-input {
    width: 100%;
    padding: 0;
    border: thin #e5e5e5 solid;
    border-top: none;

  }
  .text-area-rich-text-editor {
    &:hover {
      box-shadow: 0 1px 4px 0 #0000001a !important;
      border: $light-gray-accent thin solid !important;
      //background-color: $almost-white !important;
    }
    font-family: inherit;
    .public-DraftEditorPlaceholder-root {
      font-family: "Fira Sans Condensed", Montserrat, sans-serif !important ;
      font-size: 16px;
    }
    min-height: 160px;
    .public-DraftEditorPlaceholder-inner {
      font-family: "Fira Sans Condensed", Montserrat, sans-serif !important ;
    }
    &.disable-bold {
      button[title='Bold'] {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
    &.disable-italic {
      button[title='Italic'] {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
    &.disable-underline {
      button[title='Underline'] {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
  span {
    margin: unset;
  }
  .code-to-underline-class-icon,
  .code-to-underline-class span{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=") !important;
  }

}
.reset-forgotten-password-wrapper {
  background-color: $white;
  border-radius: 10px;
}
.uk-input.ignore-field-width {
  width: calc(100% - 144px);
  max-width: calc(100% - 144px);
}
