@import '../base/colors';

$defaultFontSize: 16px;

.day-part-working-hours-spacer {
  margin-bottom: 5px;
}
.office-hours-widget-toggle {
  margin-left: 10px;
}
.delete-office-hours-day-part {
  position: absolute;
  right: -27px;
  cursor: pointer;
  color: $primary;
}
.office-hours-widget-day-add-hours {

}

.default-size-font {
  font-size: $defaultFontSize !important;
}

.office-hours-opening-paper-popper-props {
  .MuiClockPicker-arrowSwitcher {
    display: none;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    display: none;
  }
  .PrivatePickersToolbar-root {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(to right, #4067A1 0%, #5C2C5A 100%);
    height: 100px;

    .MuiTypography-root.MuiTypography-overline {
      display: none;
    }
    .MuiGrid-root.MuiGrid-container.PrivatePickersToolbar-dateTitleContainer {
      justify-content: center;
      justify-items: center !important;
      align-items: center;
      align-content: center;
      .PrivateTimePickerToolbar-hourMinuteLabel {
        .MuiButton-root.MuiButton-text {
          span {
            color: $light-gray;
            &.Mui-selected {
              color: $white
            }
            font-size: 50px !important;
          }
        }
        span.MuiTypography-root.MuiTypography-h3.PrivateTimePickerToolbar-separator {
          font-size: 50px !important;
          margin: 0 2px;
          color: $light-gray
        }
      }
    }
  }
}
.opening-hours-inner-wrapper {
  .day-part-holder {
    z-index: 200000;
    display: flex;
    .day-part-open {
      font-size: $defaultFontSize;
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    .MuiFormControl-root.MuiTextField-root {
      .MuiOutlinedInput-root.MuiInputBase-root {
        .MuiButtonBase-root.MuiIconButton-root {
          display: none;
        }
      }
    }
  }
  position: relative;
  margin-left: 10px;
  p {
    padding: 0 !important;
    min-width: 7em;
    margin: 0;
  }
  .MuiFormControl-root.MuiTextField-root{
    border: thin $light-gray solid;
    border-radius: 2px;
    padding: 0 10px;
    max-width: 100px;
    width: 100px;
  }
}
.add-office-hours-button {
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 12px 13px;
  i {
    color: red;
  }
}

.office-hours-widget-day-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  justify-items: center;
  .office-hours-widget-day-label{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $defaultFontSize;
    min-width: 5em;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;

  }
  .MuiInputBase-input {

    font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
    color: #666 !important;
    height: 38px;
    padding: 0 0 0 0;
    font-size: $defaultFontSize;
  }
  input::-webkit-datetime-edit-hour-field {
    font-family: "Fira Sans Condensed", Montserrat, sans-serif;
  }
  span {
    width: 50px !important;
    display: inline-block;
    margin: 0 !important;
    text-align: center;
    font-size: $defaultFontSize !important;
    color: #666;
  }

  .office-not-open {
    display: flex;
    width: 428px;
    border: none;
    padding:0 10px;
    color: #666;
    height: 38px;
    align-items: center;
  }
  .office-is-open {
    display: flex;
    align-items: center;

  }
  .working-hours-toggle-item {
    cursor: pointer;
  }
  label {
    user-select: none;
  }
}
.global-one-card-container .one-card-with-form .office-hours-widget-day-wrapper input {
  border: none !important;
}

/* -----------------------------
Switch */

.uk-switch {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 50px;
  margin-top: 8px;
}

/* Hide default HTML checkbox */
.uk-switch input {
  display:none;
}
/* Slider */
.uk-switch-slider {
  background-color: rgba(0,0,0,0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.07);
}
/* Switch pointer */
.uk-switch-slider:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  transition-property: transform, box-shadow;
  transition-duration: .2s;
}
/* Slider active color */
input:checked + .uk-switch-slider {
  background-color: $primary !important;
}
/* Pointer active animation */
input:checked + .uk-switch-slider:before {
  transform: translateX(26px);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}
input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255,255,255,0.22);
}
