@import '../base/colors';

*::-webkit-scrollbar {
  &:not(.hidden-scrollbar) {
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  background-color: transparent;
}

*::-webkit-scrollbar {
  background-color: #eaeaea;
  width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: #eaeaea;
}
*::-webkit-scrollbar-thumb {
  background-color: #937d96;
  border-radius: 5px;
  min-height: 50px;

}
.accent-scroll-bar {
  &::-webkit-scrollbar-thumb {
    background-color: $accent-color !important;

  }
  &::-webkit-scrollbar {
    background-color: $accent-color-light !important;
  }
  &::-webkit-scrollbar-track {
    background-color: $accent-color-light !important;
  }
}
.terms-and-conditions-wrapper::-webkit-scrollbar-track {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.terms-and-conditions-wrapper::-webkit-scrollbar {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
