@import '../base/colors';


.admin-import-rhv-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  width: 80vw;
  margin:auto;
  padding-bottom: 10px;
  .single-error-wrapper {

    width: 80vw;
    display: flex;
    padding: 10px 0;
    border: thin #ccc solid;
    border-bottom-width: 0;
    justify-content: center;
    align-items: center;
    .error-original {
      margin-right: 50px;
      width: 30%;
      color: $red;
      text-align: right;
    }
    .error-error {
      width: 70%;
      color: $medium-text-color;
    }
  }
  &.with-rhvs {
    border: thin #ccc solid;
  }
  .admin-import-rhv-header {
    background-color: $red;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    color: $white;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .rhv-list-wrapper {
    width: 80vw;
    display: flex;
    flex-direction: column;
    .rhv-list-item-wrapper-outer {
      border: thin #ccc solid;
      border-left: none;
      border-right: none;
      &.rhv-list-item-with-errors {
        background-color: lighten($red, 47%);
        border-color:$red;
        border-left: thin $red solid;
        border-right: thin $red solid;
      }
      &.rhv-list-item-with-warnings {
        background-color: lighten($orange, 47%);
        border-color:$orange;
        border-left: thin $orange solid;
        border-right: thin $orange solid;
      }
      &:hover {
        background-color: #ccc;
        &.rhv-list-item-with-errors {
          background-color:$red;
          color: $white;
        }
        &.rhv-list-item-with-warnings {
          background-color:$orange;
          color: $white;
        }
      }
      .rhv-list-item-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 20px  10px 20px;

        &.address {
        }


        .rhv-list-item-item {
          &.email {
            width: 22%;
          }

          &.type,
          &.phone {
            width: 10%;
          }

          &.subscription-price,
          &.subscription-size {
            width: 5%;
          }

          &.controls {
            width: 8%;
          }

          width: 15%;
          &.subscription-specialisms {
            width: unset;
          }

        }
      }

      .account {
        padding-top: 15px;
        padding-bottom: 5px;

      }
      .address-outer {
        padding-top: 5px;
        padding-bottom: 15px;
      }
    }
  }

  .rhv-list-header-wrapper {
    background-color: #5C2C5A;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80vw;
    padding: 10px 0;
    .rhv-list-header-item {
      width: 15%;
      color: white;
      &.email {
        width: 22%;
      }
      &.type,
      &.phone {
        width: 10%;
      }
      &.subscription-price,
      &.subscription-size {
        width: 5%;
      }
      &.controls {
        width: 8%;
      }
    }
  }

  .admin-import-rhv-upload-icon {
    font-size: 200px;
  }
}
.insert-rhv-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  &.complete {
    color: $green;

  }
  &.failed {
    color: $red;
  }
  .insert-rhv-complete,
  .insert-rhv-failed {
    font-size: 18px !important;
  }
  .uk-button {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}
.admin-import-rhv-reset-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
