@import '../base/colors';
.view-debugger-outer {
  pointer-events: none;
  z-index: 5000;
  &.view-debugger-outer-hidden {
    visibility: hidden;
  }

  position: absolute;
  width: 100%;
  min-height: 75px;

  .view-debugger-wrapper {
    pointer-events: none;
    min-height: 50px;
    z-index: 5000;

    .font-viewer-outer {
      pointer-events: none;
      z-index: 50000;
      position: fixed;
      top: 45px;
      width: 100%;
      text-align: center;

      .font-viewer-wrapper {
        pointer-events: all;
        color: #582F60;
        text-align: center;
        max-width: 200px;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        min-height: 85px;
        margin: auto;
      }
      .path-viewer {
        background-color: $white;
        padding: 10px;
        margin: 10px;
        position: absolute;
        max-width: 400px;
        border: 3px solid $primary-dark;
      }
    }

    .clear-local-storage-outer {
      z-index: 51000;
      pointer-events: none;
      position: fixed;
      width: 100%;

      .clear-local-storage-wrapper {
        pointer-events: all;
        background-color: rgba(255,255, 255, 0.3);
        margin: auto;
        max-width: 200px;
        text-align: center;
        padding: 20px 10px 10px 10px;
      }
    }

  }
}
.font-family-pseudo {
  color: blue;
}
.toggle-button-outer {
  pointer-events: none;
  z-index: 159000;
  position: fixed;
  top: 0;
  width: 100%;

  .toggle-button-wrapper {
    text-align: right;
  pointer-events: all;

    max-width: 200px;
    padding: 0;
    margin: auto;

    button {
      margin-right: -6px;
      border: thin #ccc solid;
      padding: 6px 2px;
      min-height: 14px;
      border-radius: 50%;
    }
  }

  &.toggle-button-outer-hidden {
    .toggle-button-wrapper {
      button {
        border-color: transparent !important;

        img {
          display: none;
        }
      }

      &:hover {
        button {
          border-color: #ccc !important;
          background-color: rgba(255, 255, 255, .2);

          img {
            display: block;
          }
        }
      }
    }
  }
}

// show central position END
