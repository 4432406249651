@media print {
  .uk-button,
  .uk-navbar-transparent,
  .uk-dropdown.uk-open {
    display: none;
  }

  .dossier-p {
    padding: 15px 30px !important;
  }

  .dossier-container .dossier-header {
    padding: 20px 30px !important;
  }

  .dossier-container .dossier-subtitle {
    padding: 15px 30px 15px 30px !important;
  }

  .dossier-container .dossier-messagebox .dossier-messagebox-details-date {
    width: 25%;
  }

  .uk-accordion-content[hidden] {
    display: flow-root !important;
  }

  .uk-visible\@m {
    display: block !important;
  }

  .uk-child-width-1-1\@m > * {
    width: 100%;
  }
  .uk-child-width-1-2\@m > * {
    width: 50%;
  }
  .uk-child-width-1-3\@m > * {
    width: unquote('calc(100% * 1 / 3.001)');
  }
  .uk-child-width-1-4\@m > * {
    width: 25%;
  }
  .uk-child-width-1-5\@m > * {
    width: 20%;
  }
  .uk-child-width-1-6\@m > * {
    width: unquote('calc(100% * 1 / 6.001)');
  }

  .uk-child-width-auto\@m > * {
    width: auto;
  }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px;
  }

  .uk-form-horizontal .uk-form-label {
    width: 150px;
    margin-top: 7px;
    float: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 150px;
  }

  /* Whole */
  .uk-width-1-1\@m { width: 100%; }

  /* Halves */
  .uk-width-1-2\@m { width: 50%; }

  /* Thirds */
  .uk-width-1-3\@m { width: unquote('calc(100% * 1 / 3.001)'); }
  .uk-width-2-3\@m { width: unquote('calc(100% * 2 / 3.001)'); }

  /* Quarters */
  .uk-width-1-4\@m { width: 25%; }
  .uk-width-3-4\@m { width: 75%; }

  /* Fifths */
  .uk-width-1-5\@m { width: 20%; }
  .uk-width-2-5\@m { width: 40%; }
  .uk-width-3-5\@m { width: 60%; }
  .uk-width-4-5\@m { width: 80%; }

  /* Sixths */
  .uk-width-1-6\@m { width: unquote('calc(100% * 1 / 6.001)'); }
  .uk-width-5-6\@m { width: unquote('calc(100% * 5 / 6.001)'); }

  /* Auto */
  .uk-width-auto\@m { width: auto; }

  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
}
