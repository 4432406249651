.office-profile-photo-preview-wrapper {
  display: flex;
  justify-content: center;

  img.office-profile-photo-preview {
    max-height: 250px;
  }
}

.rhv-company-google-map,
.fix-google-map{
  img {
    max-width: 100% !important;
  }
}

.rhv-company-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80vh;
  padding-left: 0 !important;
  padding-right: 10px;
  margin-left: 40px !important;
  @media only screen and (max-width: 480px) {
    margin-left: 25px !important;
  }
}

.rhv-todo-list-link-to-setting {
  position: absolute;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100px;
  left: -20px;
  bottom: -20px;
  top: -20px;
}
