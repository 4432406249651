.uk-accordion-content {
  &.less-margin-top {
    margin-top: 5px;
  }
}
.uk-accordion-content {
  &.closed {
    display: none;
    max-height: 0;
    padding: 0 !important;
    margin: 0 !important;
  }
  &.opened {
    margin-top: 0;
  }
}

.uk-accordion > :nth-child(n+2) {
  margin-top: 0 !important;
}

.open-accordion-title {
  cursor: pointer;
}

.dossier-container .dossier-messagebox .help-card-question-wrapper {
  position: relative;
  .visible-to-client-warning {
    text-align: right;
    margin-bottom: -15px;
  }
  .paperclip-mailbox{
    right: 10px;
  }
  .uk-accordion-title {
    margin-top: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-info-circle fa-w-16 first-icon' aria-hidden='true' focusable='false' data-prefix='fal' data-icon='info-circle' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' data-fa-i2svg=''%3E%3Cpath fill='%23272727' d='M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z'%3E%3C/path%3E%3C/svg%3E");
      width: 18px;
    }
  }
  &.uk-open > .uk-accordion-title::before
  {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E") !important;
  }
}

.dossier-messagebox-details-sender {
  pointer-events: none;
  .dossier-answer-important-information {
    pointer-events: all;
  }
}
.question-answer-comment-icon {
  position: absolute;
  right: 25px;
  top: 3px;
}
