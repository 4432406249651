@import '../base/colors';

.help-card-video-wrapper {
  position: relative;

  video {

    max-width: 100% !important;
  }

  .with-tooltip {
    pointer-events: all;
    height: 32px;
    width: 40px;
    left: 6px;
    top: 10px;
    position: absolute;
    z-index: 10;
  }

  .help-card-video-mute-indicator {
    position: absolute;
    left: 6px;
    top: 10px;
    color: $primary;
    pointer-events: none;

    &.help-card-video-mute-indicator-muted {
      .video-un-muted {
        display: none;
      }
    }

    &.help-card-video-mute-indicator-un-muted {
      .video-muted {
        display: none;
      }
    }

    .fa-volume-up {
      z-index: 2;
    }

    .fa-volume-mute {
      z-index: 2;
    }

    .fa-circle {
      color: $primary;

      &.background-icon {
        color: $white;
        z-index: 1;
      }
    }

  }
}
