@import '../base/colors';

.uk-search-default .uk-search-input {
  border: 1px solid $primary !important;
  border-radius: 4px !important;
}
.scroll-to-marker,
.scroll-to-first-company{
  top: -350px;
  position: absolute;
}
.assignee-dossier-list-search-assignee-name {
  line-height: 18px;
  span.ignore-span-font {
    font-size: 14px !important;
    color: $green;

  }
}
.assignee-dossier-list-search-dossier-client-name {
  line-height: 20px;

}
.assignee-dossier-list-search-dossier-subject {
  line-height: 20px;

}
.assignee-dossier-list-search-hashed-id {
  line-height: 18px;
  span.ignore-span-font {
    font-size: 13px !important;
  }
}
.assignee-dossier-list-search-wrapper {
  position: absolute;
  right: 0;
  pointer-events: none;
  bottom: 11px;
  z-index: 500;
  .assignee-dossier-list-search-autocomplete {

    &.MuiAutocomplete-root {
      width: 100% ;
      .MuiFormControl-root {

        display: flex;
        justify-content: center;
        .MuiInput-root {
          padding-left: 30px;
          &:before{
            display: none;
          }
        }
      }
    }
  }
  .assignee-dossier-list-select-and-filter-wrapper {
    position: relative;
    color: $primary;
    .rhv-dossier-and-screen-share-list-list-search-select {
      pointer-events: all;
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-left: 5px;
      max-width: 120px;
    }
    .dossier-search-icon {
      position: absolute;
      left: 25px;
      top: 10px;
    }
  }
}
