@import '../base/colors';
@import '../base/constants';
.uk-card-default {
  color: $primary-text-color !important;
 &.change-placeholder {
   border: none !important;
 }
  &.card-list {
    border-left: 1px solid #E4E4E4 !important;
    border-right: 1px solid #E4E4E4 !important;
    border-bottom: 1px solid #E4E4E4 !important;
  }

  &.dossier-list-header,
  &.screen-share-list-header {
    border: 1px solid #E4E4E4 ;
    border-top: none;
    border-bottom: none;
    box-shadow: none;
    border-radius: 0 10px 0 0 !important;

    .dossier-header{
      border-top-left-radius: 0 !important;
    }
  }
  &.white-header {
    border: 1px solid #E4E4E4 !important;
    border-radius: 10px !important;
    &.no-top-radius-empty-placeholder {
      border-top: none !important;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }
}

.page-title {
  padding: 40px 30px 1px 30px;
}
.card-header {
  background-color: #653c6e;
  color: white;
}
.second-card-header-inner.uk-accordion-title {
  &::before {
    margin-top: 20px;
    margin-right: 41px;
  }
  padding: 0 !important;
  .second-card-header-inner-inner {
    margin: 20px 30px;
  }
}
.rhv-add-company {
  margin-top: 17px !important;
}
.second-card-header.uk-card-header {
  color: #653c6e !important;
  background-color: #F0EDF2 !important;
  padding: 0 !important;
  margin: 0!important;
  .accordion-title-with-trash-icon {
    right: 68px;
    padding: 10px;
    top: 15px;
    bottom: 0;
    position: absolute;
    margin: 0;
  }
  .open-accordion-title {
    color: #653c6e !important;
  }
  .uk-accordion-content {
    background-color: $white !important;
    color: $primary-text-color;
    padding: 20px 40px;
    hr {
    }
  }
}

.global-one-card-container {
  @media only screen and (min-width : 961px) {
    margin: 0 120px;
  }
  .page-title {
    margin-bottom: 15px;
  }

  .assignee-setting-left {
    padding-right: 0 !important;
    padding-left: 0 !important;

    .assignee-settings-terms-of-service-languages-error {
      .gi-law-error-field-error {
        margin-left: 265px;
      }
    }

    .languages-input-wrapper {
      .uk-form-controls.languages-input-div {
        border: thin $light-gray solid;
        margin-left: 255px;
        input {
          border-bottom: none;
        }
      }
    }

    .assignee-settings-profile-card-office-photo {
      padding-bottom: 40px;

      .rhv-profile-dialog-photo {
        @media only screen and (min-width: 1300px) and (max-width: 1400px) {
          .rhv-profile-dialog-photo-upload-button {
            padding: 8px;
            span{
              font-size: 15px !important;
            }
          }
          .rhv-left-span-wrapper span {
            font-size: 15px !important;
            img {
              margin-right: 5px;
              width: 40px;
            }
          }
        }
        @media only screen and (min-width: 1200px) and (max-width: 1299px) {
          .rhv-profile-dialog-photo-upload-button {
            padding: 6px;
            span{
              font-size: 14px !important;
            }
          }
          .rhv-left-span-wrapper span {
            font-size: 14px !important;
            img {
              margin-right: 5px;
              width: 30px;
            }
          }
        }
        @media only screen and (min-width: 960px) and (max-width: 1199px) {
          .rhv-profile-dialog-photo-upload-button {
            padding: 4px;
            span{
              font-size: 12px !important;
            }
          }
          .rhv-left-span-wrapper span {
            font-size: 12px !important;
            img {
              margin-right: 4px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  .assignee-setting-right {
    padding-left: 40px;
  }
  .one-form {
    &.no-padding-left {
      padding-left: 0;
      form {
        margin-top: 40px;
      }
      .span-with-eye {
        position: absolute;
        margin-left: -20px;
        margin-top: 10px;
      }
    }
    input {
      border: 1px solid #e5e5e5;
      border-width: 0 0 1px 0;
    }
    .one-card-button-right {
      margin: 60px 30px 20px 0;
    }
    .select-only {
      margin-left: 30px;
    }
    .select-only-two {
      margin-left: 40px;
    }
    .content {
      margin: 30px 40px !important;
    }
  }

 .one-card-left-with-form {
   padding: 30px 40px !important;
   margin-left: 0  !important;
   span {
     margin: 0 20px;
   }
   input {
     border: 1px solid #e5e5e5;
     border-width: 0 0 1px 0;
   }
   .save-btn-on-the-bottom {
     margin-top: 80px;
   }
 }

  .dropbox-images {
    background-color: #EEF2F6;
  }

  .button-right-block-bottom {
    margin-top: 140px;
  }

  .one-card-with-form {
    padding: 30px !important;
    margin-left: 0  !important;
    .no-padding-left {
      padding-left: 0;
    }
    .second-paragraph {
      margin-top: 50px;
    }
    form:not(.rhv-new-employee-single-line-form) {
      margin-left: -30px  !important;
    }
    span:not(.negate-transparency) {
      margin: 0 20px;
    }
    input {
      border: 1px solid #e5e5e5;
      border-width: 0 0 1px 0;
      &.no-border {
        border: none;
      }
    }
    .save-btn-on-the-bottom {
      margin-top: 80px;
    }
  }
}

.purple-header-without-text {
  height: 28px;
}

.header-radius {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.uk-grid > * {
  @media only screen and (max-width : 960px) {
    padding-left: 0 !important;
  }
}
.ensure-pointer {
  pointer-events: all !important;
  cursor: pointer;
  &.remove-padding {
    margin: 0 !important;
  }
}

.global-one-card-container .one-card-with-form.assignee-settings-contact-card-wrapper {
  &.not-dialog {
    overflow-y: hidden;
  }
  padding: 0 !important;
  .assignee-settings-contact-card-inner {
    .fix-content-margin {
      padding-left: 0;
      margin-left: 15px;
      margin-top: 16px;
    }
    padding-left: 10px ;
  }
}
.assignee-settings-contact-card-header,
.assignee-settings-layout-card-header,
.assignee-setting-profile-card-header{
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.global-one-card-container {
  .assignee-settings-contact-card,
  .assignee-settings-profile-card,
  .assignee-settings-layout-card {

    &.specific-profile-card {
      padding: 0 !important;
      .assignee-settings-profile-card-scroll-area {
        padding: 30px 40px 0 40px;
        max-height: calc(#{$assignee-settings-height} - 324px);
        overflow-y: $assignee-settings-if-scroll;
        overflow-x: hidden;
      }
    }
    font-size: 16px !important;
    padding-bottom: 0 !important;

    label,
    input,
    select {
      font-size: 16px !important;
      color: #666666;
    }

    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .rhv-company-qualities-error {
      padding-left: 8px !important;
    }

    .rhv-company-profile-text-error {
      margin-top: 0 !important;
      padding-left: 0 !important;
    }

    .assignee-settings-profile-card-grid.uk-grid {
      margin-left: unset;
    }

    .assignee-settings-contact-card-inner.assignee-settings-contact-card-inner-right {
      padding-left: 0px;
    }
  }
}
.assignee-settings-contact-card {
  .assignee-settings-contact-card-inner.uk-grid {
    margin-left: 0;
    margin-top:   40px;
    .assignee-settings-contact-card-opening-hours {
      padding-left: 0;
    }
  }
}
.assignee-settings-profile-card-grid.uk-grid {
  margin-top:   40px;

}

.hidden-scroll-target {
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
}
