// Specific classes about the question pages for the customer
@import '../base/colors';

// The background
.background-gradient {
  min-height: 100vh;
  background: $secondary-bg-color;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
}

// Navbar
.uk-navbar {
  border-bottom: 1px $white solid;
}

nav {
  @media (max-width: 959px) {
    background-color: $white;
  }
  a {
    text-decoration: none !important;
  }
}

.navbar-mobile {
  button {
    margin-top: 22px;
  }
  a {
    margin-top: 22px;
  }
}

.navbar-mobile-bottom {
  bottom: 0;
  position: fixed;
  width: 100%;
  .navbar-mobile-bottom-buttons {
    padding: 20px 25px 0 25px;
    .uk-align-right {
      margin-left: 0 !important;
    }
  }
}

.navbar-mobile-bottom-space-top {
  height: 150px;
}

.nav-bar-left-mobile {
  margin-left: 9px;
}

.nav-bar-right-mobile {
  margin-right: 9px;
}

.navbar-space-right-desktop {
  margin-right: 25px;
}

// Answers
.card-answers {
  border-radius: 4px !important;
  border: 1px solid $white !important;
  background-color: transparent !important;
  color: $white !important;
  margin-left: -10px;
  @media (max-width: 373px) {
    height: 130px;
    width: 130px;
  }
  @media (min-width: 374px) {
    height: 150px;
    width: 150px;
  }
  @media (min-width: 960px) {
    height: 200px;
    width: 200px;
  }
}

.card-answers-desktop {
  padding: 20px 40px !important;
}

.card-answers-mobile-text {
  @media (max-width: 959px) {
    padding: 20px 35px !important;
    height: 90px !important;
  }
}

.card-answers-mobile-text:hover {
  @media (max-width: 959px) {
    color: $white !important;
    background: rgba(255, 255, 255, 0.3) !important;
  }
}

.card-answers-mobile-p {
  @media (max-width: 959px) {
    font-size: 13px;
  }
}

.card-answers:hover {
  color: $white !important;
  background: rgba(255, 255, 255, 0.3) !important;
}

.card-answers-desktop-text {
  @media (min-width: 960px) {
    padding: 30px !important;
    height: 180px !important;
    width: 350px !important;
    &.first-card {
      float: right;
    }
  }
}

.card-answers-desktop-text:hover {
  @media (min-width: 960px) {
    color: $white !important;
    background: rgba(255, 255, 255, 0.3) !important;
  }
}

.clicked {
  background-color: $white !important;
  color: #4e487d !important;
  &.img-smile {
    @media (min-width: 960px) {
      border-radius: 50%;
    }
  }
  p {
    color: #4e487d !important;
  }
}

.answers-block-desktop {
  @media (min-width: 1250px) {
    margin-left: 73px;
    .subtitle {
      margin: -11px 0 40px -30px;
      font-size: 18px !important;
    }
    p {
      color: $white;
      margin-top: 15px !important;
    }
    .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
      margin-top: 17px !important;
    }
    .first-card {
      float: right;
    }
    .middle-card {
      margin: 0 -109px 30px 7px;
    }
    .last-card {
      float: left;
    }
    .last-card-second-line {
      margin-left: 7px !important;
    }
  }
  @media (max-width: 1249px) {
    .uk-child-width-1-3\@l > * {
      width: 50% !important;
    }
    .middle-card {
      display: none;
    }
  }
}

.answers-block-mobile {
  @media (max-width: 1249px) {
    .answer-card {
      width: 80% !important;
    }
    .subtitle {
      margin: -30px 0 20px 0;
    }
    p {
      color: $white;
    }
    .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
      margin-top: 10px !important;
    }
    .uk-card-body {
      padding: 30px 10px !important;
    }
    .first-card {
      float: right;
    }
    .last-card {
      float: left;
    }
  }
  @media (min-width: 960px) and (max-width: 1248px) {
      margin-left: 60px !important;
    .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
      margin-top: 40px !important;
    }
  }
}

.answers-with-icons-line {
  .uk-card-hover:hover {
    cursor: pointer;
    color: $white;
    background: rgba(255, 255, 255, 0.3) !important;
  }
  .uk-card-body {
    padding: 40px 40px 40px 0 !important;
  }
  @media (max-width: 959px) {
    .uk-grid {
      flex-wrap: nowrap !important;
    }
  }
  .uk-card {
    border: 1px solid #ffffff;
    border-radius: 10px;
    background-color: transparent;
    height: 150px;
    .answers-with-icons-line-img {
      margin: auto 0 auto 30px;
      width: 120px;
      img {
        width: 100%;
      }
    }
    @media (min-width: 960px) {
      margin-left: 60px;
      .answers-with-icons-line-answer {
        margin: auto;
        width: 40vw;
      }
      .answers-with-icons-line-img {
        img {
          margin-left: 16px;
        }
      }
    }
    p {
      color: $white;
    }
  }
}


.datepicker {
  @media (min-width: 960px) {
    margin-left: 60px;
    span {
      font-size: 18px;
    }
  }
  .fa-calendar-alt {
    font-size: 27px !important;
  }
  input {
    font-size: 18px;
    font-family: 'Montserrat', Fira Sans Condensed, sans-serif;
    position: relative;
    cursor: pointer;
    @media (max-width: 959px) {
      width: 58vw;
    }
    @media (min-width: 960px) {
      width: 35vw;
    }
  }
}

.answer-cards {
  @media (min-width: 960px) {
    height: 180px;
    width: 180px;
    cursor: pointer;
    display: block;
    padding-left: 30px !important;
    margin-bottom: 0;
    img {
      height: 60px;
    }
  }
}

.answer-cards-mobile {
  @media (max-width: 959px) {
    cursor: pointer;
    display: block;
    padding-left: 20px !important;
    height: 40%;
    img {
      height: 30px;
    }
    p {
      margin-top: 10px;
      &.without-icon {
        margin-top: 0 !important;
      }
    }
  }
}

.uk-card-default {
  border: 1px solid $white;
  background: transparent;
  color: $white;
}

.uk-card-default.uk-card-hover:hover {
  color: $white;
  background: rgba(255, 255, 255, 0.3);
}

.uk-card-body {
  padding: 20px 50px;
}

.card-answers-title {
  @media (min-width: 960px) {
    margin-left: 60px;
  }
  p {
    font-weight: 400;
    color: #fff;
    margin: -2px auto 50px auto;
    width: 40vw;
  }
  h1 {
    margin: 60px 0 50px 0;
  }
  h2 {
    margin: 40px 0;
    font-size: 1.8rem;
    &.subject-header {
      margin: 50px 0 10px 0;
    }
    &.question-header{
      font-weight: 700;
      margin-bottom: 15px;
      font-weight: 600;
      padding-left: 0;
      &.question-only {
        margin-bottom: 35px !important;
      }
    }
  }
  h4 {
    font-weight: 700;
    padding-bottom: 15px;
  }
  img {
    @media (min-width: 960px) {
      margin-bottom: 45px;
      margin-left: 60px;
    }
  }
  .img-mobile-header {
    @media (max-width: 959px) {
      height: 50px;
    }
  }
  .title-mobile-with-img {
    margin: 15px 0 -5px 0;
  }
  .title-mobile {
    margin: -5px 0 30px 0;
  }
}

.question-answer-mobile {
   margin: 0 25px;
    h4, .uk-h4 {
      line-height: 1.2 !important;
  }
}

.card-answers-center-mobile {
  @media (max-width: 959px) {
    padding-right: 20px !important;
  }
}

.card-answers-body {
  @media (max-width: 959px) {
    margin: 10px auto 10px auto!important;
  }
}

.card-answers-body-mobile {
  @media (max-width: 959px) {
    margin: 10px auto 10px -10px !important;
    padding-left: 57px;
  }
}

.card-answers-space {
  @media (max-width: 959px) {
    padding: 15px !important;
  }
}

.card-answers-space:hover {
  color: $white !important;
  background: rgba(255, 255, 255, 0.3) !important;
}

.card-answers-space:focus {
  color: $primary !important;
  background: $white !important;
}

.card-answers-space-mobile {
  padding: 10px !important;
  p {
    font-size: 13px;
  }
}

.card-answers-space-mobile:hover {
  color: $white !important;
  background: rgba(255, 255, 255, 0.3) !important;
}

.card-answers-space-mobile:focus {
  color: $primary !important;
  background: $white !important;
}

.card-answers-title-mobile {
  padding-top: 20px;
}

.card-answers-center-desktop {
  margin-left: 200px;
  font-weight: 500 !important;
}

.card-answers-next-line {
  @media (max-width: 959px) {
    margin: 20px auto;
  }
}

.card-answers-next-line-desktop {
  @media (min-width: 960px) {
    margin-top: 20px !important;
  }
}

.question-flow-text-area {
  background-color: #ffffff3d;
  border-radius: 3px;
  padding: 25px 20px;

  &.mobile {
    @media (max-width: 959px) {
      width: 72vw;
    }
  }

  &.desktop {
    @media (min-width: 960px) {
      width: 50vw;
      margin-left: 70px;
    }
  }

  ::placeholder {
    color: $white;
    font-size: 16px;
    font-family: 'Montserrat', Fira Sans Condensed, sans-serif;
    text-align: center;
  }

  textarea {
    width: 100%;
    height: 150px;
    border-color: transparent;
    background-color: transparent;
    color: $white;
    resize: none;
  }
}

.slidecontainer {
  position: relative;
  input {
    width: 100%;
  }
}

.slider-title {
  @media (min-width: 960px) {
    margin: 10px auto 30px auto;
  }
}

.slider {
  -webkit-appearance: none;
  margin-bottom: 10px;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background: $white;
  position: relative;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 3px solid $white;
  background: $primary;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid $white;
  background: $primary;
  cursor: pointer;
}
.marker{
  position: absolute;
  top: 12%;
  padding-top: 16px;
  font-size: 13px;
  letter-spacing: 0.05em;
  transform: translateX(-50%);

  &:after{
    content: '';
    width: 3px;
    height: 16px;
    background: $white;
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.marker-0{
  left: 0.5%;
}

.marker-25{
  left: 25%;
}

.marker-50{
  left: 50%;
}

.marker-75{
  left: 75%;
}

.marker-100{
  left: 100.5%;
}

.transparent-bg-lighter {
  background-color: #918cb7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -10px;
  @media (max-width: 959px) {
    padding: 30px;
  }
  @media (min-width: 960px) {
    margin-left: 60px;
    width: 40vw;
    padding: 25px 30px 10px 30px;
    &.center {
      padding: 25px 30px 25px 30px;
    }
  }
  p {
    color: $white !important;
  }
  [class*='uk-align'] {
    margin-bottom: 0 !important;
  }
  .uk-align-left {
    margin-right: 20px !important;
  }
  span {
    color: white;
    padding-left:10px;
  }
}

.transparent-bg {
  background-color:  #ffffff3d;
  border-radius: 10px;
  padding: 30px;
  &.with-skip-question-footer {
    @media (min-width: 960px) {
      padding: 30px 30px 40px 30px;
    }
  }
  &.mobile-smileys {
    @media (max-width: 959px) {
      padding: 12px;
    }
  }
  &.desktop {
    @media (min-width: 960px) {
      margin-left: 60px;
      width: 40vw;
    }
  }
  &.size-block {
    @media (min-width: 960px) {
      width:400px;
    }
  }
  &.size-block-mobile {
    @media (max-width: 480px) {
      width:72vw;
      &.first-screen {
        @media (max-width: 350px) {
          font-size: 12px !important;
          margin-top: -15px !important;
        }
      }
    }
  }
  &.size-block-long-text-mobile {
    @media (max-width: 959px) {
      width: 80vw;
      margin: auto;
      .checkbox-footer {
        margin-top: 20px;
      }
      p {
        font-size: 13px;
      }
      label {
        font-size: 13px;
      }
    }
  }
  h4 {
    color: $white;
  }
  p {
    color: $white;
    font-weight: 300;
  }
  .icon {
    color: $white;
  }
  label {
    font-weight: 300;
    color: $white;
    margin-top: 10px;
  }
  .btn-icon {
    color: $primary;
  }
  .first-button {
    @media (min-width: 960px) {
      margin-bottom: 15px;
    }
  }
  .second-button {
    @media (min-width: 960px) {
      margin-bottom: 30px;
    }
  }
  .title-transparent {
    @media (max-width: 959px) {
      margin-bottom: 25px;
    }
  }
  .different-buttons {
    button {
      margin: 8px 8px 0 8px;
    }
  }
  .c-datepicker-input {
    ::placeholder {
      color: $white !important;
      font-size: 16px !important;
      font-family: 'Montserrat', Fira Sans Condensed, sans-serif !important;
      text-align: center !important;
    }
  }
  textarea {
    font-family: 'Montserrat', Fira Sans Condensed, sans-serif !important;
  }
}

.start-screen {
  @media (max-width: 361px) {
    iframe {
      display: none;
    }
  }
  .text-and-btn {
    margin-right: 15px;
  }
  ifame {
    margin-bottom: 15px;
  }
  .start-screen-last-explanation {
    margin-bottom: 0;
  }
}

.c-datepicker-btn {
  input {
    background-color: transparent;
    border-color: transparent;
    color: $white;
  }
  input::placeholder {
    color: $white;
  }
}

.c-datepicker__header-date {
  background: $secondary-bg-color !important;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  padding: 16px 0 0 0 !important;
}

.c-datepicker__header-day {
  background: $secondary-bg-color !important;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
}

.u-hover-ball-effect:hover:before, .c-datepicker__day-body:hover:before, .c-datepicker__clock__num:hover:before, .c-datepicker__clock__am-pm-toggle label:hover:before{
  background: $primary !important;
}

.c-datepicker--show-time:after {
  opacity: initial !important;
  visibility: unset !important;
}
.c-datepicker__header-date__time {
  opacity: 0 !important;
}

.js-show-calendar {
  opacity: 0 !important;
}

.js-cancel {
  color: #5a5858 !important;
}

.js-ok {
  color: $primary !important;
}

.smiley-answer {
  @media (min-width: 960px){
    img {
      width:60px;
      margin: 0 10px 15px 10px;
      cursor:pointer;
      pointer-events: auto;
    }
  }
}

.smiley-answer-mobile {
  @media (max-width: 959px) {
    img {
      width: 40px;
      margin: 0 5px 15px 5px;
      cursor: pointer;
      pointer-events: auto;
    }
  }
}

// Progress bar on the left
.progress-left-bar-body {
  width: 200px;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed !important;
}

.progress-left-bar {
  position: initial;
  .progress-left-bar-body {
    width: 200px;
    max-width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed !important;
    background-color: $white;
    .uk-border-circle {
      p {
        margin-top: 5px;
      }
    }
    .progress-left-bar-number {
      margin: 20px 5px 20px 1px;
      display: block;
      color: $white;
      height: 35px;
      width: 35px;
      text-align: center;
      &.circle-active {
        background-color: $primary;
      }
      &.circle-done {
        background-color: #B39FB2;
      }
    }
    .progress-left-bar-item {
      margin-left: -20px;
    }
    .progress-left-bar-footer {
      margin-left: -30px;
      h4 {
        margin-bottom: 20px;
      }
    }
    .icon-active {
      margin-left: 53px;
      color: #959F2C;
    }
  }
}

.margin-bottom-white-balk {
  margin-bottom: -4px;
}

.uk-position-bottom-gl {
  bottom: 39px;
  h4 {
    line-height: 0.4pt;
  }
  p {
    margin-right: 10px;
  }
  span {
    margin-left: 53px;
    color: #959F2C;
  }
}

.progress-left-bar-footer-title {
  margin-top: 30px;
}

.progress-left-bar-footer-hr {
  margin-left: -30px;
}

// Next and before button
.mobile-next-button {
  margin: 0 25px 25px 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.mobile-before-button {
  margin: 0 0 25px 25px;
  position: absolute;
}

.btn-desktop-question-flow {
  button {
    bottom: 40px;
    position: fixed;
  }

  .desktop-next-button {
    margin: 0 40px 40px 0 !important;
    right: 164px !important;
  }

  .desktop-before-button {
    margin: 0 0 40px 300px !important;
  }
}

// Global changes
.no-scroll-bottom {
  position: absolute;
}

.item-p {
  color: $primary-text-color;
}

.save-btn-mobile {
  margin-right: 5px !important;
}
