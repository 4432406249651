@import '../base/colors';
.fixed-background-full-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: $secondary-bg-color;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
}

.check-icon-space-left {
  margin-left: 10px;
}

.check-icon-color {
  color: $green !important;
}

#rhv-registration-login {
  form {
    font-size: $rhv-registration-font-size !important;
  }
  label.uk-form-label {
    font-size: $rhv-registration-font-size !important;
  }
  input {
    font-size: $rhv-registration-font-size !important;
  }

  .forgot-password {
    font-size: $rhv-registration-font-size !important;
  }
}
#terms-of-use-dialog,
#forgot-password,
#establish-new-password-card{
  form {
    font-size: $rhv-registration-font-size !important;
    label{
      font-size: $rhv-registration-font-size !important;
    }
  }
  label.uk-form-label {
    font-size: $rhv-registration-font-size !important;
  }
  input {
    font-size: $rhv-registration-font-size !important;
  }

}

