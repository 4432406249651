@import '../base/colors';

.react-markdown-wrapper {
  code {
    text-decoration: underline;
    color: unset;
    background-color: unset;
    font-family: "Fira Sans Condensed", Montserrat, sans-serif;
    padding: 0;
    font-size: unset;
  }

  p {
    margin-bottom: unset;
  }
}

.details-dossier {
  font-size: 16px !important;

  .client-data-form {
    label {
      font-size: 16px !important;
    }
    input {
      font-size: 16px !important;
    }
    div.phone-permission-not-shared,
    div.phone-permission-shared {
      cursor: pointer;
      font-size: 14px!important;
      position: relative;
      max-height: 0;
      overflow: visible;
      margin-right: 0;
      right: 50px;
    }
    .phone-permission-shared {
      color: $red;

    }
    .phone-permission-not-shared {
      color: $green;
    }
  }
}
.react-markdown-wrapper {
  ul {
    font-size: 16px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    li {
      font-size: 16px !important;
    }
  }
}
.with-code {
  .italic-editor-class,
  .bold-editor-class {
    display: none;
  }
  .code-editor-class{
    border-left-width: 1px !important;
  }
}

.with-italic,
.with-bold
{
  .code-editor-class {
    display: none;
  }
}
.text-area-rich-text-editor {
  div[class*="InputPopover__checkOption___"] {
    display: none;
  }
}
