$suitable-border-radius: 16px;
/* CHANGE TO 2nd value for continuous scroll */

$scroll-rhv-settings: false;
$scroll-rhv-info-for-client: false;

$assignee-settings-height:  5000000px;
$assignee-settings-if-scroll:  hidden;
$assignee-settings-margin-bottom:  20px;
@if $scroll-rhv-settings {
  $assignee-settings-height: 100vh;  // 100vh | 5000000px;
  $assignee-settings-if-scroll: scroll; // scroll | hidden
  $assignee-settings-margin-bottom: 0; // 0 |  20px

}


$rhv-detail-header-size: 20px;
$rhv-detail-header-margin: 12px;

$rhv-registration-popup: false;

$registration-popup-max-height: 9999999999px !important;

@if $rhv-registration-popup {
  $registration-popup-max-height: 90vh !important;
}
$max-dialog-width: 80vw;

$rhv-card-height-closed: 50px;
$rhv-card-height-open: 250px;
$rhv-card-height-open-list-view: 500px;

$missing-photo-height:25px;

$missing-photo-wrapper-background:  url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1  SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1110 390' style='enable-background:new 0 0 1110 390%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%23E8EEF8%3B%7D.st1%7Bfill:none%3Bstroke:%2333669A%3Bstroke-width:7%3Bstroke-miterlimit:10%3B%7D.st2%7Bfill:%23445F98%3B%7D.st3%7Bfont-family:'FiraSansCondensed-Regular'%3B%7D.st4%7Bfont-size:36px%3B%7D%3C/style%3E%3Crect class='st0' width='1110' height='390'/%3E%3Cpath class='st1' d='M652.04 197.39c0 3.82-3.11 6.93-6.93 6.93H464.89c-3.82 0-6.93-3.11-6.93-6.93V72.61c0-3.82 3.11-6.93 6.93-6.93h180.22c3.82 0 6.93 3.11 6.93 6.93V197.39z M492.61 89.94c5.73 0 10.4 4.66 10.4 10.4c0 5.73-4.66 10.4-10.4 10.4c-5.73 0-10.4-4.66-10.4-10.4C482.22 94.61 486.88 89.94 492.61 89.94z M586.86 100.37l-48.03 47.27l-15.68-15.43c-4.74-4.66-12.42-4.66-17.16 0l-44.48 43.77c-2.28 2.24-3.55 5.28-3.55 8.44v13.93c0 3.3 2.72 5.97 6.07 5.97h181.96c3.35 0 6.07-2.67 6.07-5.97v-45.76c0-3.17-1.28-6.2-3.55-8.44l-44.48-43.77C599.28 95.71 591.59 95.71 586.86 100.37z'/%3E%3Ctext transform='matrix(1 0 0 1 365.7512 289.7476)' class='st2 st3 st4'%3ENog geen foto beschikbaar%3C/text%3E%3C/svg%3E");
