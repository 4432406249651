@import '../base/colors';

.uk-dropdown-nav {
  a {
    color: $primary-text-color !important;
    text-decoration: none !important;
    margin: 10px !important;
  }
  li {
    :hover {
      color: $primary !important;
    }
  }
}

.uk-dropdown {
  padding: 10px !important;
}

.rhv-colleague-autocomplete-dimmed {
  opacity: 0.5;
}
.rhv-colleague-autocomplete-dimmed-department {
  opacity: 0.5;

}

.client-action-menu {
  ul li {
    a {
      display: flex;
      margin: 10px
    }
    hr.client-action-menu-divider {
      color: red;
      border-top: thin $light-gray solid;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}
