@import '../base/colors';

.gi-law-error-text-wrapper {
  position: relative;
}
.gi-law-error-field-wrapper {
  position: relative;
  height: 0;
  overflow: visible;
  color: $red;
  font-size: 16px;
  padding: 0 10px;
  &.uk-grid {
    margin-top: -20px !important;
    margin-bottom: 20px !important;
    &.fix-permissions-position {
      margin-top: -1px !important;
    }
    &.transfer-dossier-internally-modal-error {
      margin-top: 0 !important;
    }
  }
  .gi-law-error-field-error {
    padding-left: 42px;
    &.rhv-company-profile-text-error {
      padding-left: 30px;
    }
    &.assignee-help-card-error{
      padding-left: 0;
      margin-top: 20px;
      margin-left: -20px;
    }
    &.rhv-company-qualities-error {
      margin-top: - 30px;
      padding-left: 30px;
    }
  }
}
.gi-law-error-field-outer-wrapper.uk-grid {
  margin-left: unset;
}
.gi-law-error-field-wrapper .gi-law-error-field-error {

  .help-card-error-wrapper {
    padding-left: 24px;
  }
  .assignee-help-card-error-wrapper {
    margin-left: -24px;
  }
}

.help-card-error-inner {
  width: 120px
}

.company-geographical-region-search-widget-error-wrapper {
  position: relative;
  user-select: none;
  pointer-events: none;

  .gi-law-error-field-error {
    top: 21px;
    left: 0;
    width: 50vw;
    max-width: 50vw;
    padding-left: 180px;
    position: absolute;
    overflow: visible;
  }
}
