@import '../base/colors';

.uk-tab > * > a {
  text-transform: none !important;
  padding: 10px !important;
}

.global-tabs {
  margin-left: 1px;
  ul {
    margin-bottom: 0;
    .first-tab ,
    .second-tab,
    .third-tab {
      &.sliding-tab {
        display: flex;
      }
      a.rhv-registration-tab {
        padding: 15px 14px !important;
        .rhv-registration-dialog-tab-title {
          font-size: 15px !important;
        }
      }
    }
    .first-tab {
      a {
        border-top-left-radius: 10px;
        border: 1px solid #653c6e;
        border-bottom: #f0edf2;
        text-decoration: none !important;
        padding: 15px 20px !important;

        color: #653c6e  !important;
        &.active {
          margin-right: 0;
          font-weight: 500;
          background-color: #F0EDF2;
        }
      }
    }
    .second-tab {
      padding-left: 0;
      margin-left: -1px;
      a {
        border: 1px solid #653c6e;
        border-bottom: #f0edf2;
        text-decoration: none !important;
        padding: 15px 20px !important;
        color: #653c6e  !important;
        &.active {
          font-weight: 500;
          background-color: #F0EDF2;
        }
      }
    }
    .third-tab {
      padding-left: 0;
      margin-left: -1px;
      a {
        border-top-right-radius: 10px;
        border: 1px solid #653c6e;
        border-bottom: #f0edf2;
        text-decoration: none !important;
        padding: 15px 40px !important;
        color: #653c6e !important;
        &.active {
          background-color: #F0EDF2;
          font-weight: 500;
        }
      }
    }
  }
}
.global-tabs.rhv-registration-dialog-tabs {
  ul {
    .first-tab,
    .second-tab,
    .third-tab {
      a {
        &.active {
          background-color: $white;
          border-bottom-color: $white !important;
        }
      }
    }

    .first-tab,
    .second-tab {
      a {

        border-right-color: $white !important;
      }
    }
  }
}
a.slider-slide-controller-next {
  text-decoration: none !important;
  z-index: 50000;
  right: calc(100% * 1 / 4.001);
  margin-right: -30px;
  color: $primary
}
a.slider-slide-controller-previous {
  text-decoration: none !important;
  z-index: 50000;
  left: 0;
  margin-left: -30px;
  color: red;
}

.global-tabs ul .first-tab a {
  @media only screen and (max-width : 960px) {
    padding: 10px !important;
  }
}

.global-tabs ul .second-tab a {
  @media only screen and (max-width : 960px) {
    padding: 10px !important;
  }
}

.global-tabs ul .third-tab a {
  @media only screen and (max-width : 960px) {
    padding: 10px !important;
  }
}


.assignee-dossier-list-tabs
{
  user-select: none;
  pointer-events: none;
  li {
    pointer-events: all;
    a {
    }
  }
  padding-left: 20px;
  z-index: 5;
  margin-bottom: -1px;
  &:before {
    border: none;
  }

  .assignee-dossier-list-tab {
    &:hover a{
      background-color: $assignee-tab-hover-background;
      &.active {
        background-color: $assignee-tab-hover-background;
      }
    }
    padding: 0 0 1px 0;
    .uk-badge.mail-badge {
      display: none;
      position: absolute;
      top: 20px;
      right: 24px;
      font-size: 10px !important;
      background-color: $primary-disabled !important;
      height: 17px;
      width: 17px;
      min-width: 12px;
    }
    &.uk-active {

      .uk-badge.mail-badge {
        background-color: $primary !important;
        padding-top: 2px;
      }
    }
    &:hover {
      .uk-badge.mail-badge {
        display: inline-flex;
      }
    }
  }
}

.assignee-dossier-list-header.uk-card-default.uk-card {
  border-top: none;
  border-top-left-radius: 0;
  &.empty-assignee-dossier-list-header {
    border-top-left-radius: 10px;
    .dossier-header {
      border-top-left-radius: 10px;
    }
  }
  .dossier-header {
    border-top-left-radius: 0;
  }
}

.assignee-settings-tab-wrapper.uk-margin-medium-top {
  ul.uk-flex-left.uk-tab {
    margin-left: 0;
    .active-tab {
      background-color: $primary-light;
      a {
        text-align: left;
        justify-content: flex-start;
        border-color: transparent;
      }
    }
    .uk-active {
      a {
        border-color: transparent;
      }
    }
    &:before {
      display: none;
    }
    .assignee-settings-tab {
      width: 100%;
      padding: 0 0 0 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      user-select: none;

      &:hover {
        background-color: $primary-light;
      }

      &.active-tab {
        background-color: $assignee-tab-hover-background;

        &:hover {
          background-color: $primary-light-hover;
        }
      }
      a {
        text-decoration: none !important;
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
  margin-top: 15px !important;
  .uk-tab::before {
    right: 10px;
  }
  .assignee-dossier-list-tabs::before {
    right: 10px;
  }
}
