.mijn-dashboard-extra-two-cards {
  margin-top: 100px !important;
}

.dashboard-check-circle {
  color: #95a126;
  font-size: 25px;
}

.dashboard-list-icons {
  font-size: 25px !important;
}

.progress-list {
  margin: 5px 0 10px 0 !important;
}

.light-purple-card {
  background-color: rgba(88,48,96,0.2) !important;
  .light-purple-card-header {
    color: #653c6e;
    font-weight: 500;
  }
  .light-purple-card-body {
    color: #653c6e;
    padding: 40px 40px 0 40px;
  }
}

.header-with-white-text {
  color: white;
}
