@import '../base/colors';


.dossier-container {
  .dossier-mobile-client-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;

    .dossier-mobile-client-footer-nav-wrapper {
      justify-content: space-around;
      align-items: center;
      .dossier-mobile-client-footer-link {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.dossier-container {
  .hulp-card-passende-hulp-header {
    position: relative;
    display: flex;
    .mobile-footer-scroll-to-suitable-help {
      top: -100px;
      position: absolute;
      z-index: 1;
      width: 0;
      height: 0;
    }
  }
}
