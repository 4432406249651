@import '../base/colors';
html {
  font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
}

h1, .uk-h1, h2, .uk-h2, h3, .uk-h3, h4, .uk-h4, h5, .uk-h5, h6, .uk-h6, .uk-heading-small, .uk-heading-medium, .uk-heading-large, .uk-heading-xlarge, .uk-heading-2xlarge, input {
  font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
}

input::-webkit-datetime-edit-minute-field {
  font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
}

.montserrat-font {
  font-family: 'Montserrat', Fira Sans Condensed, sans-serif !important;
}

p {
  @media only screen and (min-width : 481px) {
    font-size: 16px !important;
  }
}
.underline-text {
  text-decoration: underline;
}

h3 {
  @media only screen and (min-width : 481px) {
    font-size: 28px !important;
    &.find-suitable-help-header {
      font-size: 24px !important;
    }
  }
}

span:not(.ignore-span-font) {
  @media only screen and (min-width : 481px) {
    font-size: 16px !important;
    &.uk-badge {
      font-size: 15px !important;
    }
  }
}
.text-area-rich-text-editor span {
  font-size: unset !important;
  margin: unset !important;
}

.dossier-subtitle {
  @media only screen and (min-width : 481px) {
    font-size: 23px !important;
  }
}

.dossier-progress-details {
  @media only screen and (min-width : 481px) {
    font-size: 18px !important;
  }
}

form {
  @media only screen and (min-width : 481px) {
    font-size: 18px !important;
  }
}

label {
  @media only screen and (min-width : 481px) {
    font-size: 18px !important;
  }
}

.dossier-check {
  @media only screen and (min-width : 481px) {
    font-size: 18px !important;
  }
}

a {
  @media only screen and (min-width : 481px) {
    text-decoration: underline !important;
  }
}

html {
  @media only screen and (min-width : 481px) {
    font-size: 18px !important;
  }
}

button {
  @media only screen and (min-width : 481px) {
    font-size: 0.925rem !important;
  }
}

textarea {
  @media only screen and (min-width : 481px) {
    font-size: 18px !important;
  }
}

.text-middle-bold {
  font-weight: 500;
}

.react-markdown-wrapper em,
em.not-red{
  color: unset;
}
.react-markdown-underline {
  text-decoration: underline;
}

.uk-form-horizontal .uk-form-label.align-text-right {
  text-align: right;
  padding-right: 55px;
  margin-top: 0;
  width: 160px;
}
.font-size-fixed,
input.font-size-fixed{
  font-size: 16px !important;
  &::placeholder {
    font-size: 16px !important;
  }
}
.text-color-medium {
  color: $medium-text-color !important;
}
