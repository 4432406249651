@import '../base/colors';
@import '../base/constants';

.not-dialog-feedback-outer-wrapper {
  padding: 10px 50px 10px 250px;
  flex-direction: column;
  color: $white;
  max-width: 800px;
  display: flex;
  a ,
  a:link,
  a:visited,
  a:hover ,
  a:active {
    color: $white !important;
  }
}
