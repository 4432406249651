@import '../base/colors';
.company-type-autocomplete-option {
  font-size: 12px !important;
  color: blueviolet;
}
.test-outer {
  padding: 25px;
}
.center-middle {
  height: 100vh;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 120px;

}
.assign-case {
  z-index: 500;
  h3, form {
    background-color: $white;
  }
  padding: 25px;
  position: fixed;
  top: 10px;
  left: 300px;
  border: thin #ccc solid;
  width:900px;
  h3 {
    text-align: center;
  }
}
ol.test-dossier-list-wrapper {
  list-style: none;
  ul.test-dossier-list {
    list-style: none;
    display: flex;
    li {
      border: thin #999999 solid;
      padding: 10px;
      display: flex;
      line-height: 1.1;
      justify-content: center;
      align-items: center;
      margin-right: 3px;
    }
  }
}
.dossier-list-item-1 {
  width: 4%;
  max-width: 4%;
  min-width: 4%;
}
.dossier-list-item-2 {
  width: 25%;
  max-width: 25%;
  min-width: 25%;
}
.dossier-list-item-3 {
  width: 12%;
  max-width: 12%;
  min-width: 12%;
}
.dossier-list-item-4 {
  width: 12%;
  max-width: 12%;
  min-width: 12%;
}
.dossier-list-item-5 {
  width: 25%;
  max-width: 25%;
  min-width: 25%;
}
.test-dossier-list-uri {
  list-style: none;
  border: thin #999 solid;
  margin-top: -15px !important;
}
.update-able-dossier-wrapper {
  list-style: none;
  li {
    .update-able-dossier-inner {
      display: flex;
      align-items: baseline;
      align-content: space-between;
      padding:  10px 0;
      justify-content: flex-start;
      justify-items: center;
      .dossier-hash {
        width: 30%;
      }
      .user-email {
        width: 30%;
      }
      .subject {
        width: 20%;

      }
    }
  }
}

.copy-path-to-clipboard-wrapper {
  position: absolute;
  display : flex;
  background-color: black;
  max-width: 100%;
  top: -45px;
  left: 10px;
  right: 10px;
  align-items: center;

  .copy-path-to-clipboard {
    margin: 0;
    max-width: 95%;
    padding-left: 30px;
    text-align: left;
    color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    //style={{color: 'white', backgroundColor: "black", position: "absolute", left: 0, width: "100%", textAlign: "center", border: "thin red solid", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
  }
}
