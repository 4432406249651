@import '../base/colors';

.uk-checkbox:checked {
  background-color: $primary !important;
}

.uk-checkbox {
  border-radius: 4px !important;
}

.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {
  background-color: $primary !important;
}

.uk-checkbox-space-right {
  margin-right: 8px !important;
}
.dossier-container .dossier-p .transfer-case-to-new-client-checkbox-wrapper {
  @media only screen and (min-width : 960px) {
    margin-left: 144px !important;
  }
  input {
    margin-top: 10px;
    border: thin #CCCCCC solid !important;
  }
}

.modal-accept-checkbox  {
  margin-right: -40px;
}

.checkbox-label-note {
  margin-left: 80px;
  margin-top: -17px;
  font-size: 14px;
}
.share-with-client-section-checkbox:disabled {
  cursor: not-allowed;
}
