// encode svg https://yoksel.github.io/url-encoder/
// colours can be changed by changing fill # becomes %23

$upArrowImage:  url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-angle-up fa-w-8' aria-hidden='true' focusable='false' data-prefix='fal' data-icon='angle-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' data-fa-i2svg=''%3E%3Cpath fill='%23653c6e' d='M136.5 185.1l116 117.8c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L128 224.7 27.6 326.9c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l116-117.8c4.7-4.6 12.3-4.6 17 .1z'%3E%3C/path%3E%3C/svg%3E");

$downArrowImage: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-angle-down fa-w-8' aria-hidden='true' focusable='false' data-prefix='fal' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' data-fa-i2svg=''%3E%3Cpath fill='%23653c6e' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'%3E%3C/path%3E%3C/svg%3E");

$rightArrowImage: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-angle-right fa-w-6' aria-hidden='true' focusable='false' data-prefix='fal' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' data-fa-i2svg=''%3E%3Cpath fill='%23653c6e' d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z'%3E%3C/path%3E%3C/svg%3E");

$emptyArrowImage: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-minus fa-w-12' aria-hidden='true' focusable='false' data-prefix='far' data-icon='minus' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' data-fa-i2svg=''%3E%3Cpath fill='%23653c6e' d='M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'%3E%3C/path%3E%3C/svg%3E");

$checkedTaskImage:url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-check-circle fa-w-16' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check-circle' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' data-fa-i2svg=''%3E%3Cpath fill='%2395a126' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'%3E%3C/path%3E%3C/svg%3E");

