@import '../base/colors';
@import '../base/constants';
.choose-legal-aid-quality-autocomplete-popper {
  .MuiAutocomplete-noOptions {
    font-size: 16px !important;
  }
}
.dossier-hulp-container {
  .suitable-help-start-search-filter-view.dossier-hulp-body {
    position: relative;

    .company-geographical-region-search-widget {
      display: flex;
      .choose-legal-aid-location-holder-select .uk-select {
        background-color: transparent;
      }
      .input-slim.uk-input {
        height: 32px;
      }
    }

    .suitable-help-accent-wrapper {


      .suitable-help-sub-section-hr {
        margin: 0 -30px 20px -30px;
        border: thin $white solid;

      }
      .suitable-help-subtitle-accent {

        padding: 20px 30px 0 0;
        color: $accent-color;
        font-size: 20px !important;
        margin-bottom: 12px;
        font-family: "Fira Sans Condensed", sans-serif !important;

      }

      .suitable-help-para-accent {
        word-wrap: normal;
        max-width: 100%;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

}

.suitable-help-detail-list-view {
  .rhv-list-for-client.uk-grid {
    justify-content: center;
    margin-left: 0;
    .rhv-list-for-client-no-rhv {
      margin-bottom: 27px;
      user-select: none;
    }
    &.filter-submitting {
      opacity: 0.6;
    }
    .rhv-company-list-item-for-client-card-list-view {
      max-width: 370px;
      min-width: 370px;
      padding: 0 !important;
      margin: 25px;
      border: thin $light-gray solid;
      .rhv-company-list-item-for-client-card-list-view-inner {
        padding: 25px !important;
        .hover-transparent.accent-button:hover {
          background-color: $accent-color-light !important;
        }
      }
      border-radius: 10px;
      position: relative;
      box-shadow: 0 5px 15px #00000069 !important;
      .rhv-type-banner{
        padding: 10px 30px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        font-weight: 500;
        color: $accent-color;
        &.rechtswinkel {
          background-color: $rechtswinkel;
        }
        &.advocaat {
          background-color: $advocaat;
        }
        &.mediator {
          background-color: $mediator;
        }
        &.rechtswinkel {
          background-color: $rechtswinkel;
        }
        &.vakbond {
          background-color: $vakbond;
        }
        &.rechtsbijstandverzekeraar {
          background-color: $rechtsbijstandverzekeraar;
        }
        &.sociaal-raadslid {
          background-color: $sociaal-raadslid;
        }
        &.juridisch-loket {
          background-color: $juridisch-loket;
        }
      }
      .rhv-company-info-buttons {
        width: 320px;
        justify-content: flex-end;
      }

      .rhv-company-list-item-for-client-hr {
        margin: 10px -25px 10px -25px;
      }

      .rhv-company-company-name-sub-header {
        color: $accent-color;
        font-size: 18px !important;
        font-family: "Fira Sans Condensed", sans-serif !important;
        margin-bottom: 15px;
      }

      // list view
      .rhv-company-profile-area {
        max-height: $rhv-card-height-closed;
        min-height: $rhv-card-height-closed;
        transition: max-height 1s ease-out;
        &:hover {
          max-height: $rhv-card-height-open-list-view;
          transition: max-height 1s ease-in;
          overflow-y: auto;
          margin-right: -10px;
        }

        overflow: hidden;
        .react-markdown-wrapper {
          *,
          a{
            color: #333333 !important;
          }
        }
      }

      .rhv-company-company-name {
        color: $accent-color;
        font-size: 22px !important;
        max-height: 32px;

        span {
          font-size: 22px !important;
        }
      }
      // list view
      .info-window-image {
        display: flex;
        justify-items: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        &.missing-photo-wrapper {
          background: $missing-photo-wrapper-background;
        }

        img {
          width: 100%;
          height: 130px;
          object-fit: scale-down;
          overflow: hidden;
          max-width: 100% !important;
          &.missing-photo {
            opacity: 0 !important;
          }
        }

        margin: 10px -25px;

      }

      .rhv-company-info-window {
        position: relative;

        .rhv-company-info-window-tooltip-wrapper {
          position: absolute;
          width: 100%;

          .uk-active {
            margin-right: -100px;
          }
        }


        .rhv-company-info-window-qualities.uk-grid {
          margin-left: 0;
          max-height: 35px;
          overflow: hidden;

          .rhv-company-info-window-quality-item {
            font-size: 16px !important;
            margin: 0 10px 8px 0;
            padding: 2px 15px;
            border-radius: 16px;
            color: $accent-color;
            background-color: $accent-color-light;
            user-select: none;

          }
        }
      }

      .rhv-company-info-window-buffer {
        height: 45px;
      }

      .rhv-company-info-buttons {
        position: absolute;
        bottom: 25px;
      }
    }
  }
}
.company-filter-search-widget-autocomplete-item-outer-wrapper {
  .MuiAutocomplete-option {
    border-bottom: thin solid $primary;
    .company-filter-search-widget-autocomplete-item-wrapper {
      font-size: 16px!important;
      color: $primary;

      .company-filter-search-widget-autocomplete-item-name {
      }

      .company-filter-search-widget-autocomplete-item-address {
        font-size: 15px!important;

      }

      .company-filter-search-widget-autocomplete-item-type {
        font-size: 14px!important;
      }
      .company-filter-search-widget-autocomplete-item-qualities {
        font-size: 14px!important;
      }
    }
  }
}

.suitable-help-start-search-filter-view {
  padding: 20px 30px 30px 30px !important;
  .suitable-help-sub-section {
    position: relative;
    margin-right: 0;
    display: flex;
    flex-direction: column;

    .choose-legal-aid-quality {
      min-width: 250px;

      .MuiInput-root:before {
        display: none;
      }
    }

    .choose-legal-aid-quality-autocomplete-holder,
    .choose-legal-aid-location-holder-select,
    .choose-legal-aid-location-holder-text-field {
      border-radius: $suitable-border-radius;
      padding: 0 20px;
      background-color: $white;
      &.choose-legal-aid-location-holder-text-field-disabled {
        background-color: $very-light-gray !important;
        border: thin $very-light-gray solid;
        cursor: not-allowed;
        .uk-input:disabled {
          cursor: not-allowed;
        }
      }
      // max-width: 150px;
    }

    .suitable-help-inner-subtitle {
      margin-top: 12px;
      display: none;
      color: $accent-color !important;
      font-family: "Fira Sans Condensed", sans-serif !important;
      font-size: 16px !important;
    }

    .choose-legal-aid-location-holder-text-field {
      max-width: 120px;
      border-radius: 0;
      margin-right: 0;
      border: thin $white solid;
      background-color: $white;
      &.choose-legal-aid-location-holder-text-field-disabled {
        background-color: $very-light-gray !important;
        cursor: not-allowed;
        .uk-input:disabled {
          cursor: not-allowed;
        }

      }
      .no-border-legal-aid-location-holder {
        background-color: transparent;
        &.uk-input,
        &.uk-input:disabled {
          background-color: transparent;
        }
      }
      &.field-has-focus {
        border: thin $accent-color solid;
      }
      input.uk-input {
        font-size: 16px !important;
        min-width: 75px;
        width: unset;
        padding: 0;

        &::placeholder {

          font-size: 16px !important;
        }
      }
    }

    .suitable-help-sub-section-switch {
      max-width: unset !important;
    }
    .choose-legal-aid-location-holder-select {
      max-width: 180px;
      margin-left: 0;
      border-bottom-left-radius: 0;
      border: thin white solid;
      border-left-color: $accent-color-light;
      border-top-left-radius: 0;
      &.legal-aid-select-disabled {
        border: thin $very-light-gray solid;
        background-color: $very-light-gray;
        border-left-color: $accent-color-light;
        .uk-select:disabled {
          cursor: not-allowed;
        }
      }
      select.uk-select {
        min-width: 120px;
        &:disabled {
          background-color: transparent;
        }
      }
    }

    .choose-legal-aid-location.uk-select {
      font-size: 16px !important;
      border: none;
      min-width: 75px;
      padding: 0;
    }

    .form-with-only-border-bottom.no-border-legal-aid-location-holder {
      border-bottom: none !important;
    }

    .company-geographical-region-search-widget {
      display: flex;
      align-items: center;
      align-content: space-between;

      .choose-legal-aid-location-holder-select .uk-select {
        background-color: transparent;
      }
      .input-slim.uk-input {
        height: 32px;
      }
    }

    .choose-legal-aid-location.uk-select {

      height: 32px;
    }

    .suitable-help-sub-section-switch {
      min-width: 151px;
      max-width: 151px;

      &.switch-right-wide {
        min-width: 261px;
        max-width: 261px;

      }

      margin-bottom: 20px;

      .switch-right,
      .switch-center,
      .switch-left {
        pointer-events: all;
        user-select: none;
        cursor: pointer;
        text-align: center;
        font-size: 16px !important;
        display: inline-block;
        background-color: $white;
        padding: 5px 20px;
        color: $medium-text-color;

        &.active {
          background-color: $accent-color;
          color: $white;
        }
        &.api-submitting {
          cursor: not-allowed;
          background-color: $very-light-gray;
        }

      }

      .switch-right {
        width: 35px;

        border-top-right-radius: $suitable-border-radius;
        border-bottom-right-radius: $suitable-border-radius;

        &.switch-right-wide {
          width: 70px;

        }
        &.api-submitting {
          cursor: not-allowed;
          background-color: $very-light-gray;
        }

      }

      .switch-center {
        width: 35px;

      }

      .switch-left {
        width: 35px;
        border-top-left-radius: $suitable-border-radius;;
        border-bottom-left-radius: $suitable-border-radius;
        border-right: thin transparent solid;

        &.active {
          background-color: $accent-color;
          color: $white;
        }

        &.empty {
          border-right: thin $light-gray solid;
        }
        &.api-submitting {
          cursor: not-allowed;
          background-color: $very-light-gray;
        }
      }

    }


  }


}

.hulp-card-passende-hulp-no-postcode {
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-right: -25px;
  img.find-suitable-help-image {
    max-width: 65% !important;
  }
}

.suitable-help-start-search-filter-view-rhv-filtered-list {

  &.filter-submitting {
    opacity: 0.6;
  }
  .no-results-wrapper {
    padding: 30px;

    .no-results {
      color: $medium-text-color;
      font-size: 16px !important;
    }
  }
  .find-suitable-help-image {
    padding: 30px;
    max-width: 75% !important;
  }
  .no-postcode-wrapper {
    padding: 30px;
    &.anonymous {
      padding: 30px 0;
    }
    .no-postcode {
      color: $medium-text-color;
      font-size: 16px !important;
    }
  }

  .rhv-company-list-item-for-client-card-supplier {
    .rhv-company-list-item-for-client-card-supplier-hr {
      margin-bottom: 0;
    }
    .rhv-company-for-client-list-help-view-card-wrapper {
      .rhv-company-for-client-list-help-view-card {
        padding: 30px;

        .card-title {
          margin-bottom: 20px;
          font-size: 20px !important;
          color: $accent-color;

          .rhv-type {
            font-weight: 500;
          }

          .rhv-name {

          }
        }

        // first page
        .info-window-image {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          align-content: center;

          margin-bottom: 20px;
          margin-left: -30px;
          margin-right: -30px;
          &.missing-photo-wrapper {
            background: $missing-photo-wrapper-background;
          }

          img {
            width: 100%;
            height: 130px;
            object-fit: scale-down;
            overflow: hidden;
            max-width: 100% !important;
            &.missing-photo {
              opacity: 0 !important;
            }
          }
        }


        .rhv-company-info-window {
          position: relative;
          padding: 2px 20px 20px 0;

          .rhv-company-info-window-tooltip-wrapper {
            position: absolute;
            width: 100%;

            .uk-active {
              margin-right: -100px;
            }
          }


          .rhv-company-info-window-qualities.uk-grid {
            margin-left: 0;
            max-height: 35px;
            overflow: hidden;

            .rhv-company-info-window-quality-item {
              font-size: 16px !important;
              margin: 0 10px 8px 0;
              padding: 2px 15px;
              border-radius: 16px;
              color: $accent-color;
              background-color: $accent-color-light;
              user-select: none;

            }
          }
        }

        // first page
        .rhv-company-profile-area {
          color: $medium-text-color;
          max-height: $rhv-card-height-closed;;
          min-height: $rhv-card-height-closed;;
          transition: max-height 1s ease-out;
          &:hover {
            max-height: $rhv-card-height-open;
            transition: max-height 1s ease-in;
            overflow-y: auto;
          }

          overflow: hidden;

          .react-markdown-wrapper {
            * {
              color: $primary;
            }
          }
        }

        .buttons-wrapper {

        }
      }

    }

    .rhv-company-list-item-for-default-client {
      padding: 15px 30px 20px 30px;
      .rhv-company-list-item-for-default-client-company-name {
        color: $accent-color;
      }
      .rhv-company-list-item-for-default-client-company-address {
        font-size: 16px;
        color: $medium-text-color;
      }
      .rhv-company-list-item-for-default-client-submit {
        padding: 8px 20px  !important;
      }
      .rhv-company-info-buttons {
        display: flex;
        padding-top: 10px;
        justify-content: flex-start;
      }
      .rhv-company-list-item-for-default-client-more-info {
        user-select: none;
        border: none !important;
        color: $accent-color !important;
        margin-left: 5px;
        &:hover {
          background-color: $accent-color-light !important;
        }
      }
    }
  }

  .rhv-company-list-item-for-client-card-supplier-header {
    padding: 10px 30px;
    user-select: none;
    color: $accent-color;
    font-weight: 500;
    &.rechtswinkel {
      background-color: $rechtswinkel;
    }
    &.advocaat {
      background-color: $advocaat;
    }
    &.mediator {
      background-color: $mediator;
    }
    &.rechtswinkel {
      background-color: $rechtswinkel;
    }
    &.vakbond {
      background-color: $vakbond;
    }
    &.rechtsbijstandverzekeraar {
      background-color: $rechtsbijstandverzekeraar;
    }
    &.sociaal-raadslid {
      background-color: $sociaal-raadslid;
    }
    &.juridisch-loket {
      background-color: $juridisch-loket;
    }
  }
}

.suitable-help-map-view-wrapper {
  *::selection {
    background-color: $accent-color !important;
  }
  padding-top: 30px !important;
  .suitable-help-dossier-header-wrapper {
    &.uk-card-default {
      border: none;
    }
    .suitable-help-detail-map-view-wrapper {
      margin-top: 3px;
       .gm-style-iw-d{
         overflow: unset !important;
         max-height: 1000px !important;
         /* Note that adding colored header causes difficulty */
        }
    }
  }
  .suitable-help-dossier-show-other-view {
    position: fixed;
    display: flex;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
    color: $white;
    bottom: 20px;
    padding: 8px 20px;
    border-radius: 25px;
    background-color: $accent-color;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 115px;
    user-select: none;
    pointer-events: all;
    cursor: pointer;
    .text-wrapper {

    }
    .icon-wrapper {
      font-size: 22px !important
    }
  }

  .faded-text {
    color: $light-gray-accent;
  }

  .suitable-help-subtitle {
    padding: 20px 30px 10px 30px;
    color: $accent-color;
    font-size: 16px;
    user-select: none;
    font-weight: 500;
  }

  .suitable-help-sub-section {
    .MuiInput-root {
      font-size: 16px !important
    }
    .suitable-help-inner-subtitle {
      color: $accent-color !important;
      font-size: 16px;
      margin-top: 12px;
      margin-bottom: 10px;
      font-weight: 500;
      user-select: none;
    }

  }

  .legal-aid-search-and-filter {
    background-color: $accent-color-light;
    border: none !important;
  }
  .suitable-help-types,
  .legal-aid-search-and-filter,
  {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px 20px 30px;
    z-index: 10;

    .legal-aid-type-picker-item {
      color: $accent-color;
      margin-bottom: 6px;
      padding: 5px 20px;
      margin-right: 10px;
      border-radius: $suitable-border-radius;
      background-color: $assignee-tab-active-background;
      user-select: none;
      pointer-events: all;
      font-size: 16px!important;
      &.api-submitting {
        cursor: not-allowed;
      }
      cursor: pointer;
      &.active {
        border: thin solid $accent-color !important;
        font-weight: 600;
      }
      &.advocaat {
        background-color: $advocaat;
      }
      &.mediator {
        background-color: $mediator;
      }
      &.vakbond {
        background-color: $vakbond;
      }
      &.rechtswinkel {
        background-color: $rechtswinkel;
      }
      &.rechtsbijstandverzekeraar {
        background-color: $rechtsbijstandverzekeraar;
      }
      &.sociaal-raadslid {
        background-color: $sociaal-raadslid;
      }
      &.juridisch-loket {
        background-color: $juridisch-loket;
      }
      &.toezichthouder {
        background-color: $toezichthouder;

      }
      &.reset_filters {
        background-color: unset;
        color: $accent-color;
        font-weight: 600;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        &.active {
          background-color: unset;
          color: $primary;
        }
      }
    }

    .suitable-help-sub-section {
      margin-right: 25px;
      display: flex;
      flex-direction: column;

      .choose-legal-aid-quality {
        min-width: 250px;

        .MuiInput-root:before {
          display: none;
        }
      }
      .choose-legal-aid-quality-autocomplete-holder {
        .MuiAutocomplete-endAdornment {
          display: flex;
          flex-direction: row-reverse;
          top: 0;
          margin-right: -18px;
        }
      }

      .choose-legal-aid-quality-autocomplete-holder,
      .choose-legal-aid-location-holder-select,
      .choose-legal-aid-location-holder-text-field {
        border-radius: $suitable-border-radius;
        border: thin $white solid;
        background-color: $white;
        padding: 0 20px;
        &.api-submitting {
          background-color: $very-light-gray;
          border-color: $very-light-gray;
          cursor: not-allowed;
          .MuiInputBase-root {
            cursor: not-allowed;
            input:disabled,
            button:disabled {
              cursor: not-allowed;
            }

          }
        }
        .no-border-legal-aid-location-holder {
          background-color: transparent;
        }
        &.choose-legal-aid-location-holder-text-field-disabled {
          background-color: $very-light-gray;
          border: thin $very-light-gray solid;
          cursor: not-allowed;
          .uk-input:disabled {
            cursor: not-allowed;
          }
        }
        // max-width: 150px;
        &.field-has-focus {
          border: thin $accent-color solid;
        }
      }

      .choose-legal-aid-location-holder-text-field {
        max-width: 100px;
        margin-right: 10px;
      }

      .choose-legal-aid-location-holder-select {
        max-width: 100px;
      }

      .choose-legal-aid-location.uk-select {
        border: none;
      }

      .form-with-only-border-bottom.no-border-legal-aid-location-holder {
        border-bottom: none !important;
      }

      .company-geographical-region-search-widget {

        .choose-legal-aid-location-holder-select .uk-select {
          background-color: transparent;
        }
        .choose-legal-aid-location-holder-text-field {
          border-radius: 0;
          margin-right: 0;
        }
        .choose-legal-aid-location-holder-select{
          padding: 0 30px 0 20px;
          border-left: thin $accent-color-light solid;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &.legal-aid-select-disabled {
            background-color: $very-light-gray;
            border: thin $very-light-gray solid;
            border-left-color: $accent-color-light;
            cursor: not-allowed;
            .uk-select:disabled {
              cursor: not-allowed;
            }
          }
        }
        display: flex;
        .input-slim.uk-input {
          height: 32px;
        }
        input#postcode {
          min-width: 75px;
          font-size: 16px !important;
          border-radius: 0 !important;
          padding: 0;
        }
        select#distance.uk-select {
          min-width: 120px;
          font-size: 16px !important;
          padding: 0;
          &:disabled {
            background-color: transparent;
          }
        }
      }

      .choose-legal-aid-location.uk-select {
        height: 32px;
      }

      .suitable-help-sub-section-switch {
        min-width: 151px;
        max-width: 151px;
          font-size: 16px!important;
        .switch-right {
          pointer-events: all;
          user-select: none;
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: 35px;
          background-color: $assignee-tab-active-background;
          padding: 5px 20px;
          border-top-right-radius: $suitable-border-radius;
          border-bottom-right-radius: $suitable-border-radius;
          &.api-submitting {
            cursor: not-allowed;
            background-color: $very-light-gray;
          }

          &.active {
            background-color: $primary;
            color: $white;
          }
        }

        .switch-left {
          pointer-events: all;
          user-select: none;
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: 35px;
          padding: 5px 20px;
          border-top-left-radius: $suitable-border-radius;;
          border-bottom-left-radius: $suitable-border-radius;
          background-color: $assignee-tab-active-background;
          &.api-submitting {
            background-color: $very-light-gray;
            cursor: not-allowed;
          }
          border-right: thin $accent-color-light solid;

          &.active {
            background-color: $primary;
            color: $white;
          }

          &.empty {
            border-right: thin $accent-color-light solid;
          }
        }

      }
    }
  }
}

.rhv-list-for-client-show-list-view {
  background-color: $accent-color-light;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px 30px 30px 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px ;

  @media only screen and (max-width : 960px) {
    margin-bottom: 117px !important;
  }
  p {
    color: $medium-text-color;
    margin-bottom: 20px;
  }

}
