@import '../base/colors';
@import '../base/constants';

.rhv-terms-and-conditions-section {
  font-size: 16px !important;
  ol.lowercase-letters {
    list-style: none;
    counter-reset: list;
    padding-left: 0;
    margin-top: -0.9em;
    position: relative;

    li {
      text-indent: 1.4em;
      position: relative;
      font-size: 16px !important;
      //color: #653c6e;
      color: $primary;
    }

    li::before {
      counter-increment: list;
      content: "(" counter(list, lower-alpha) ") ";
      position: absolute; //color: #653c6e;
      color: $primary;
      font-size: 14px !important;
      left: -1.4em;
      top: 3px;
    }

  }

  ol.number-dot {
    list-style: none;
    padding-left: 0;
    counter-reset: list;

    li {
      text-indent: 1.4em;
      position: relative;
      font-size: 16px !important;
      color: $primary;
      &:before {
        counter-increment: list;
        content: counter(list, number) ". ";
        position: absolute;
        color: $primary;
        font-size: 16px !important;
        left: -1.4em;
        top: 0;
      }
      ul.inner-bullet {
        list-style: none;
        padding-left: 20px;
        li {
          text-indent: -10px;
          &:before {
            position: absolute;
            content: '.';
            top: -4px;
            left: -10px;
          }

        }
      }
    }

  }
}

.uk-form-horizontal {
  position: relative;

  .terms-of-service-select-wrapper.uk-form-label {
    width: unset;
    display: flex;
  }

  .terms-of-service-select-inner.uk-form-controls,
  .assignee-settings-contact-card-general-information-section.uk-form-controls{
    margin-left: 255px;
  }
  .terms-of-service-select-info-icon-wrapper {
    position: relative;
    display: flex;
    span {
      bottom: 0;
      margin: 0 0 0 8px !important;
      font-size: 16px !important;
      display: inline-block;
    }

  }
  .rhv-terms-of-service-markdown-card-wrapper,
  .rhv-terms-of-service-languages-wrapper,
  .rhv-terms-of-service-markdown-wrapper {
    margin: 20px 0 20px 255px;

    &.last-wrapper {
      margin-bottom: 50px;
    }
    &.first-wrapper {
      margin-top: 0;
    }
    .text-area-rich-text-editor {
      min-height: 140px;
      .DraftEditor-root {
        .public-DraftEditorPlaceholder-root {
          .public-DraftEditorPlaceholder-inner {
            font-size: 16px !important;
            font-family: "Fira Sans Condensed", Montserrat, sans-serif;
          }
        }
      }
    }
    .company-conditions-wrapper {
      position: absolute;
      margin-top: 10px;
      border: thin green solid;
    }
    label {
      position: absolute;
      left: 0;
      margin-left: 30px;
    }
    .languages-input-wrapper {
      input:focus {
        border: thin solid $light-gray;

      }
    }
  }
  .rhv-terms-of-service-markdown-card-wrapper {
    margin-left: 200px;
    &.first-wrapper,
    &.last-wrapper {
      margin-left: 0;
    }
    label {
      margin-left: 0;

    }
  }
  .rhv-terms-of-service-error-card-wrapper {
    position: relative;
    margin: -45px 0 45px 208px  !important;
    .gi-law-error-field-buffer {
      padding: 0;
      margin: 0;
    }
    .gi-law-error-field-error {
      padding: 0;
      margin: 0;
    }
    &.last-error{
      margin: -75px 0 75px 208px  !important;
    }
  }
  .terms-of-service-select-field.uk-select {

    width: 300px;
  }
}
.rhv-update-feedback-snackbars {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  pointer-events: none;
  .MuiSnackbar-root {
    margin: 1em 0;
    position: relative;
    top: 0;
    left: 0;
  }

}
.rhv-final-registration-terms-of-service-modal-wrapper {
  .rhv-terms-of-service-error-wrapper {
    position: relative;
    margin: 0;
    padding-left: 260px;
    .gi-law-error-field-buffer {
      padding-left: 0;
    }
    .gi-law-error-field-error {
      font-size: 16px !important;
      position: absolute;
      bottom: -21px;
      padding-left: 5px;
    }
    &.last-wrapper {
      .gi-law-error-field-error {
        bottom: 9px;
      }
    }
  }
}


.ignore-height-if-requested.MuiDialog-root {

  @if $rhv-registration-popup {

  }@else {
    position: relative !important;
    overflow-y: scroll;
    max-height: 100vh;
    .MuiPaper-root {
      max-height: 999999px !important;
      .start-registration-modal-body-wrapper {
        margin-bottom: 105px;
      }
    }
  }

}
.rhv-registration-start-registration,
.assignee-settings-outer-wrapper{
  .MuiPaper-root {
    overflow-y: hidden;

  }
  #start-registration-modal{
    max-height: $registration-popup-max-height;
    padding: 0 !important;
  }
  #start-registration-modal,
  .assignee-settings-wrapper{
    .gi-law-close-modal-button {
      z-index: 1;
    }
    .disabled-but-showing {
      &:disabled {
        background-color: transparent;
        color: $medium-text-color;
      }

    }
    padding: 0;
    display: flex;
    form {
      top: 0;
      position: relative;
      pointer-events: none;
      input, select {
        pointer-events: all;
      }button {
         pointer-events: all;
      cursor: pointer;
      &:disabled {
        cursor: progress;
        &.invalid-submit-button{
          cursor: not-allowed;
        }
      }
       }
      width: 100%;
      .start-registration-modal-header-wrapper {
        padding: 30px 30px 0 30px;
        position: absolute;
        background-color: $white;
        &.with-bottom-border {
          border-bottom: thin $light-gray solid;
        }
        top: 0;
        right: 0;
        left: 0;
      }

      .assignee-settings-contact-card,
      .assignee-settings-profile-card,
      .start-registration-modal-body-wrapper {
        .rhv-settings-header {
          font-size: 20px !important;
        }

      }
      .assignee-settings-contact-card,
      .start-registration-modal-body-wrapper{
        pointer-events: all;
        padding: 0 30px 15px 30px;
        margin-top: 86px;
        &.not-dialog {
          margin-top: 0;
        }
        overflow-y: auto;
        @if $rhv-registration-popup {
          max-height: calc(100% - 203px);
        }

        .uk-form-label {
          font-weight: 500;
        }
        .areas-of-law-widget-top {
          margin-top: 8px;
        }
        .start-registration-modal-body-wrapper-intro {
          margin-top: 15px;
        }
        .dossier-link-or-zip-widget-wrapper {
          .uk-form-label {
            width: 255px;
          }
          .dossier-link-or-zip-widget-wrapper-switch-wrapper {
            padding-left: 0;
            .dossier-link-or-zip-widget-switch {
              .switch-left,
              .switch-right {
                border: thin $light-gray solid;
                pointer-events: all;
                user-select: none;
                cursor: pointer;
                text-align: center;
                font-size: 16px !important;
                display: inline-block;
                background-color: $primary-light;
                padding: 5px 20px;
                color: $medium-text-color;
                margin-left: 0;
                margin-right: 0;

                &.active {
                  background-color: $primary;
                  border-color: $primary;
                  color: $white;
                }
              }
              .switch-left {
                border-top-left-radius: $suitable-border-radius;;
                border-bottom-left-radius: $suitable-border-radius;
              }
              .switch-right {
                border-top-right-radius: $suitable-border-radius;
                border-bottom-right-radius: $suitable-border-radius;
              }
              .dossier-link-or-zip-widget-switch-inner {
                padding-left: 33px;
                &.not-from-registration {
                  padding-left: 1px;
                  margin: 0 !important;
                }
              }
            }
          }
          //
        }
        .select-office-type.uk-select:disabled {
          color: #666666;
          cursor: not-allowed;
          opacity: 1;
          -moz-appearance:none; /* Firefox */
          -webkit-appearance:none; /* Safari and Chrome */
          appearance:none;
        }
        .areas-of-law-label {
          margin-top: 9px;
          align-self: self-start;
        }

        .rhv-registration-start-registration-areas-of-law {
          font-size: 16px!important;
          list-style: none !important;
          padding-left: 0;
          .areas-or-law-checkbox {
            margin-right: 8px;
            border-width: 1px !important;
          }
          .outer-list-item{
            margin: 12px 0;
            .inner-list {
              list-style: none;
            }
            .inner-list-item {
              margin-top: 10px;
            }
          }
        }

      }

      .start-registration-modal-footer-wrapper{
        display: flex;
        justify-content: space-between;
        pointer-events: all;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px 30px 30px 30px;
        border-top: thin $light-gray solid;
        margin: 0;
        button:disabled {
          cursor: progress;
          &.invalid-submit-button {
            cursor: not-allowed;

          }
        }
        .MuiButtonBase-root.MuiButton-root {
          &.underline-text {
            text-decoration: underline ;

          }
          &:disabled {
            cursor: progress;
            &.invalid-submit-button {
              cursor: not-allowed;

            }
          }
          text-transform: none;
          cursor: pointer;
          color: $primary;
          &:hover {
            background-color: $primary-light;
          }
        }
      }

    }
    label.uk-form-label {
      font-size: 16px !important;
    }
    .uk-input,
    .uk-select{
      font-size: 16px !important;
    }

    .accepted-subscription-terms-error.gi-law-error-field-wrapper {
      height: unset;
      pointer-events: none;
      margin-bottom: -4px;
      .gi-law-error-field-error {
        margin-top:13px;
        padding-left: 0;
        margin-bottom: -13px;
      }
    }


    .background-overflow-auto-toc {
      background-color: rgba(88,48,96,0.2);
      border-radius: 10px;
      max-height: 0;
      padding: 0;

      .toc-inner-wrapper {
        padding: 30px;
        position: relative;

        .toc-inner-wrapper-toggle {
          z-index: 2;
          position: absolute;
          right: 15px;
          top: 10px;
          cursor: pointer;
        }
      }

      &.toc-open{
        transition: max-height linear 1s ;
        max-height: 30vh;

      }
      &.toc-closed{
        transition: max-height linear 1s !important;
        max-height:0 !important;
      }
    }

    .office-type-wrapper {
      .gi-law-error-field-error {
        margin-top: 0;

      }

    }

    .confirm-terms-rhv-registration{
      margin-top: 20px;
      margin-bottom: 15px;
      display: flex;
      .confirm-terms-rhv-registration-checkbox{
        margin-right: 10px;
      }
    }

    .rhv-type {
      display: flex;
      align-items: center;
      font-style: italic;
      font-size: 16px !important;
      height: 40px;
      color: $medium-text-color;
      font-weight: 600;
      padding-left: 50px;
    }

    .subscription-pricing-type {
      font-size: 16px !important;
      background-color: $accent-color-light;
      padding: 30px;
      margin-right: -30px;
      margin-left: -30px;
      .subscription-pricing-type-accent {
        color: $accent-color;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
$bottom-clearance: 20px;
.uk-grid {

  .rhv-opt-out-of-registration-error {
    padding-left: 52px;
  }

}
#rhv-opt-out-of-registration{
  display: flex;
  max-width: 740px;
  max-height:$registration-popup-max-height;
  @if $rhv-registration-popup {

    overflow-y: hidden;
  }
  h3 {
    margin-top: 0;
  }
  .gi-law-close-modal-button {
    z-index: 2;
  }
  form {
    position: relative;
    top: 0;
    .rhv-opt-out-of-registration-header-wrapper {
      padding: 30px 30px 0 30px;
      position: absolute;
      background-color: #ffffff;
      top: 0;
      right: 0;
      left: 0;
      border-bottom: thin $light-gray solid
    }

    .rhv-opt-out-of-registration-holder-wrapper {
      padding: 0 30px 15px 30px;
      @if $rhv-registration-popup {
        overflow-y: scroll;
      }
      margin-bottom: 105px;
      margin-top: 86px;

      @if $rhv-registration-popup {
        max-height: calc(100% - 203px);
      }
      .rhv-opt-out-of-registration-disabled.uk-input:disabled {
        background-color: $white;
        border: none;
        color: $medium-text-color;
      }
      .left-welcome-text {
        padding-top: 15px;
      }
      .rhv-opt-out-of-registration-wrapper.uk-grid {
        margin-top: 0 !important;
        &.reason-opt-out {
          margin-top: 20px;
          margin-bottom: $bottom-clearance;
        }
        &.explanation-opt-out {
          padding-top: 20px;
          margin-bottom: $bottom-clearance;
        }
        .rhv-opt-out-of-registration-radio-group {
          display: flex;
          flex-direction: column;
          label .uk-radio {
            margin-right: 8px;
            margin-left: 10px;
          }
        }
      }
    }
    .rhv-opt-out-of-registration-footer-wrapper {
      display: flex;
      justify-content: space-between;
      position: absolute;
      border-top: thin $light-gray solid;
      padding: 30px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      .MuiButtonBase-root.MuiButton-root {
        text-transform: none;
        text-decoration: underline;

        cursor: pointer;
        color: $primary;
        &:hover {
          background-color: $primary-light;
        }
      }
    }

    .rhv-opt-out-of-registration-hr {
      margin-top: 20px !important;

    }

  }

}
