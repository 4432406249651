// The colors that we used for Gilaw

// 1. Your custom variables and variable overwrites. Doesn't work
//$button-primary-color: #fff;
//$button-primary-background: #DA7D02;
//$normal-blue-background: #004899;
//$dark-blue-background: #151F4E;
//$normal-orange-background: #F39200;
//$light-orange-background: #F9AF00;
//$hover-orange-background: #a7690b;
//$hover-background-dark-orange: #ae7e03;
//$hover-background-light-orange: #dd880c;
//$red: #ff0000;
// Gilaw Start
$primary: #653c6e !default;
$primary-dark: #4b2653 !default;
$secondary-bg-color: #4067A1;
$assigneeLogbookColor: #4067A1;
$primary-disabled: rgba(101,60,110, 0.3) !default;
$assignee-tab-disabled: rgba(88, 47, 96, 0.5) !default;
$assignee-tab-hover-background: lighten($primary, 60%) !default;
$primary-light: lighten($primary, 64%) !default;
$primary-light-hover: lighten($primary, 56%) !default;
$dropdown-bg-color: #88afe9;
$primary-text-color: #272727;
$white: #ffffff;
$assignee-tab-active-background: #F0EDF2 !default;
$accent-color: #445F98;
$accent-color-disabled: lighten(#445F98, 20%);
$accent-color-hover: darken(#445F98, 10%);
$accent-color-light: #e7efff;
$medium-text-color: #666666;
$green: #95a126;
$yellow: #FCD274;
$light-green: #EDF2DF;
$warning: #D0A45D;
$danger: #C43C3C;
$red: #C43C3C;
$light-gray: #e5e5e5;
$light-gray-accent: #cccccc;
$very-light-gray: #f5f5f5;
$almost-white: #fafafa;
$gray: #7c7c7c;
$secondary-disabled: #EEEEEE;
$orange: orange;
$rhv-registration-font-size: 16px;
.primary-color {
  color: $primary;
}

.accent-color {
  color: $accent-color;
}

a {
  color: $primary !important;
  &.navbar {
    color: $white !important;
  }
  &.warning {
    color: $red !important;
  }
  &.positive {
    color: $green !important;
  }
}

$debug-border: thin solid rgba(255, 0 ,0, 0.3);

// RHV colors for RZ
$advocaat: #E3D8EA;
$mediator:  #FFE5E5;
$rechtswinkel: #E4E8C3;
$vakbond:  #F7E2BB;
$rechtsbijstandverzekeraar:  #C8F3FD;
$sociaal-raadslid: #FFC7C7;
$juridisch-loket:  #FFF7CB;
$toezichthouder:  lighten(#E4E8C3, 7%);
// Gilaw End
