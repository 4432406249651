@import '../base/colors';
@import '../base/constants';

.assignee-incoming-dossiers-header {

  flex-direction: row;
  align-content: flex-end;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
}

.assignee-dashboard-wrapper.mijn-dashboard-extra-two-cards {
  margin-top: 10px !important;
}
.assignee-dashboard-wrapper-dashboard.mijn-dashboard-extra-two-cards {
  margin-top: 50px !important;
}

.assignee-dashboard-rhv-company-profile.uk-card-default {


  border-radius: 3px;
  border: thin #E4E4E4 solid;

  .rhv-company-profile-divided {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: stretch;
    justify-content: space-between;

    .rhv-company-profile-info-wrapper {
      width: 64%;
      display: flex;
      flex-direction: column;

      .rhv-company-profile-info-header {
        margin-top: 20px;
        font-size: 20px;
      }

      .rhv-company-profile-qualities-header {
        margin-top: 25px;
      }

      .rhv-company-profile-profile-text {
        max-height: 75px;
      }

      div {
        margin-left: 30px;
      }
    }

    .rhv-company-profile-info-hr {
      margin-right: -10px;
    }

    .rhv-company-profile-image-wrapper {
      display: flex;
      width: 35%;
      border-left: thin #E4E4E4 solid;
      background-color: #d8d0dc;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;

      .rhv-company-profile-image {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        display: flex;
        flex-direction: column;
        background-color: #d8d0dc;
        align-items: center;
        justify-content: center;
        justify-items: center;
        align-content: center;
        flex-wrap: wrap;

        img {
          object-fit: cover;
          max-width: 95% !important;
          padding: 25px;
        }
      }
    }
  }
}

.sticky-fixed-bottom .bottom-nav-desktop a.rhv-add-new-dossier-button {
  font-size: 18px !important;
  pointer-events: all;
}

.rhv-company-profile-quality-list {
  margin-top: 2px;
  font-size: 14px;
}

.assignee-inbox-header-wrapper {
  align-items: center;
  justify-content: space-between;

  h2 {
    padding-left: 30px;
  }
}

.rhv-company-quality-item {
  margin-top: 10px;
  input {
    border: none;
    border-bottom: thin $light-gray solid;
    @media only screen and (min-width: 1400px) {
      width: 80%;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1399px){
      width: 70%;
    }
    @media only screen and (min-width: 1100px) and (max-width: 1199px){
      width: 65%;
    }
    @media only screen and (min-width: 960px) and (max-width: 1099px){
      width: 55%;
    }
  }

  .rhv-company-widget-trash-add-icons {
    span {
      cursor: pointer;
    }

    &.disabled-rhv-company-quality-item {
      span {
        cursor: not-allowed !important;
      }
    }
  }
}
input, input:active, input:focus {
  outline: none !important;
}
.rhv-company-quality-widget-wrapper {
  margin-top: 30px;
  margin-bottom: 50px;
  .rhv-company-quality-item input{
    font-size: 16px !important;
    &:focus::-webkit-input-placeholder {
      color: #bdc1c9;
    }
    &::-webkit-input-placeholder {
      color: #9197a3;
    }
  }
  input:focus::-webkit-input-placeholder {
   // color: #bdc1c9;
  }
}

.assignee-snackbars-and-button-wrapper {
  min-height: 55px;
}

.gi-law-modal-sub-title {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 23px;
  font-weight: 400;
}

.rhv-registration-dialog-tabs {
  ul {
    margin-right: 150px;
    margin-left: 10px;
    margin-bottom: -5px;
    a {
      min-height: 31px;
      .rhv-registration-dialog-tab-title {
        margin-left: 5px;
        color: $assignee-tab-disabled !important;

      }
      &.active {
        .rhv-registration-dialog-tab-title{
          color: $primary !important;
        }
      }
    }
  }
}
//.global-tabs ul .first-tab a
.assignee-settings-tab-wrapper.global-tabs {
  ul {
    li:hover {
      a {
        background-color: $assignee-tab-hover-background !important;
      }
    }
    .rhv-settings-tab-title {
      color: $assignee-tab-disabled !important;
    }
    .active {
      background-color: $assignee-tab-active-background !important;;
      .rhv-settings-tab-title {
        color: $primary !important;
      }
    }
  }
}

.assignee-inbox-header-button {
  margin: 0 !important;

}

.assignee-incoming-dossiers-button {
  margin: 0 !important;
}

.rhv-final-registration-subsidy-wrapper {
  .rhv-final-registration-subsidy.uk-grid {
    margin-left: -25px;

    label input {
      margin-right: 6px;
    }
  }

  height: 40px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: baseline;
  align-content: center;
}

.rhv-final-registration-clear-form-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}

.red-border {
  border: thin red solid !important;
}
.assignee-layout-settings {
  pointer-events: all;
}
.assignee-layout-settings.uk-card-header.second-card-header {
  .accordion-title-with-trash-icon {
    display: inline-block !important;
    &.uk-hidden {
      display: none !important;
    }
    bottom: unset;
    a.accordion-title-with-trash-icon-inner {
      margin: 0;
    }
    margin: 0;
    top: 11px;
    z-index: 5;
    font-size: 20px;
  }

  .autocomplete-new-employee-email{
    input{
      margin-left: 15px;
      padding-left: 10px;
    }
    .MuiInput-underline:before {
      display: none;
    }
  }
  .autocomplete-new-employee-name{
    input{
      padding-left: 10px;
    }
    .MuiInput-underline:before {
      display: none;
    }
  }
  .rhv-company-user-department-new-employee-list {
    padding-left: 0;
    li {
      margin-bottom: 20px;

    }
  }
  .rhv-assignee-association-wrapper {
    position: relative;
    &::before {
      display: none !important;
    }
    .rhv-settings-name {
      margin-left: -3px;
      padding-left: 0 !important;
      .rhv-company-user-department-association {
        padding-left: 10px !important;
      }
    }
    margin-bottom: 20px;
    .remove-rhv-from-company-icons-wrapper {
      position: absolute;
      right: 10px;
    }
  }
  .second-card-header-inner {
    margin-left: 0;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-caret-down fa-w-10' aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' data-fa-i2svg=''%3E%3Cpath fill='%23653C6E' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
    }
    .uk-accordion-title::before {
      margin-top: 20px;
      margin-right: 41px;
    }
  }

  &.uk-open {
    .second-card-header-inner {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg data-uk-tooltip='fas fa-caret-up' class='svg-inline--fa fa-caret-up fa-w-10' title='' aria-expanded='false' tabindex='0' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='caret-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' data-fa-i2svg=''%3E%3Cpath fill='%23653C6E' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
}

.rhv-final-registration-department.uk-card-header.second-card-header {

  background-color: $white !important;
  border-radius: 10px;

    .accordion-title-with-trash-icon {
      display: inline-block !important;
      bottom: unset;
      .accordion-title-with-trash-icon-inner {
        color: $white !important;
        margin: 0;
      }
      margin: 0;
      top: 11px;
      cursor: pointer;
      z-index: 5;
      font-size: 20px;
    }
  .rhv-assignee-association-wrapper {
    &::before {
      display: none !important;
    }
    margin-bottom: 25px;
    .remove-rhv-from-company-icon {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0 0 0 15px;
    }

  }
  .second-card-header-inner {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-caret-down fa-w-10' aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' data-fa-i2svg=''%3E%3Cpath fill='%23FFF' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
    }

    background-color: $primary !important;
    margin-left: 0;
    border-radius: 10px;


    .uk-accordion-title::before {
      margin-top: 20px;
      margin-right: 41px;
    }


  }

  &.uk-open {

    .second-card-header-inner {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:before {
        fill: $white;

        background-image: url("data:image/svg+xml,%3Csvg data-uk-tooltip='fas fa-caret-up' class='svg-inline--fa fa-caret-up fa-w-10' title='' aria-expanded='false' tabindex='0' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='caret-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' data-fa-i2svg=''%3E%3Cpath fill='%23FFFFFF' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }

  color: $white !important;
  margin: 0 30px 20px 30px !important;
  border: thin $light-gray solid;

  .open-accordion-title {
    color: $white !important;
  }

  .uk-accordion-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    .rhv-company-user-department-new-employee {
      .rhv-company-user-department-new-employee-list {
        padding-left: 0;
        li {
          margin-bottom: 25px;
        }
        .rhv-company-office-layout-add-new-employee {
          position: absolute;
          top: 0;
          right: -30px;

        }
        .autocomplete-new-employee-email{
          input{
            margin-left: 15px;
            padding:0 0 0 4px;
            border-bottom: $light-gray thin solid;
          }
          .MuiInput-underline:before {
            display: none;
          }
        }
        .autocomplete-new-employee-name{

          input{
            padding:0 0 0 4px;
            border-bottom: $light-gray thin solid;
          }
          .MuiInput-underline:before {
            display: none;
          }
        }
      }
    }
    .rhv-settings-email {
      width: 59%;
    }
    .rhv-company-user-department-association-wrapper {
      .gi-law-error-field-error {
        padding-left: 45px;
        margin-top: 17px;
      }

      .MuiAutocomplete-inputRoot {
        padding-right: 0;
      }

    }
    .rhv-company-user-department-association-wrapper-right {
      .gi-law-error-field-error {
          padding-left: 60px;
      }
      width: 59%;
      .MuiAutocomplete-inputRoot {
        padding-right: 0;
      }
      .MuiAutocomplete-endAdornment {
        right: 0;
        top: -4px;
      }
      .rhv-company-office-layout-add-new-employee {
        position: absolute;
        top: 0;
        right: 5px;

      }
      .rhv-company-office-layout-delete-new-employee {
        position: absolute;
        top: 0;
        right: -30px;
      }
    }
    .rhv-settings-name {

    }
    .remove-rhv-from-company-icons-wrapper {
      width: 7%
    }
    .rhv-company-user-department-association,
    .rhv-company-user-rhv-settings-email {
      padding-left: 4px;
    }
  }
}

.rhv-company-user-department-association {
  input {
    border-top: none;
    border-left: none;
    border-right: none;
  }

}
.rhv-company-user-department-association-outer-wrapper.uk-grid-small {
  margin-left: 0;

}
.rhv-company-user-department-association-wrapper {
  padding-left: 12px !important;
}
.rhv-name-email-wrapper.uk-grid-small {
  margin: 0;
  .rhv-settings-name {
    padding-left: 12px;
    .rhv-company-user-department-association {
      padding-left: 12px;
      border-bottom: thin $light-gray solid !important;

    }
  }
  .rhv-settings-email {
    .rhv-company-user-rhv-settings-email {
      padding-left: 12px;
      border-bottom: thin $light-gray solid !important;

    }
  }

}
.rhv-new-employee-single-line-form {
  margin-left: 0 !important;
  width: 100%;
  display: flex;
}

ul.rhv-row-wrapper-unordered-list {
  margin: 0;
  padding: 0;
  .rhv-row-wrapper-list-item {
    margin: 0;
    padding: 0;
  }
}
.border-red {
  border: thin red solid !important;
}
.border-green {
  border: thin green solid !important;
}
.border-blue {
  border: thin blue solid !important;
}
.border-orange {
  border: thin orange solid !important;
}
.border-white {
  border: thin white solid !important;
}
.assignee-settings-layout-card.uk-card.uk-card-default {
  padding: 0 !important;
  .assignee-settings-layout-card-content-wrapper {
    overflow-y: $assignee-settings-if-scroll;
    overflow-x: hidden;
    padding: 30px 40px 30px 40px;
    max-height: calc(#{$assignee-settings-height}  - 354px);
    margin-bottom: 105px;
  }
  .assignee-settings-layout-card-widget {
  }
  .assignee-settings-layout-card-footer {
    position: absolute;
    left: 0 ;
    right: 0;
    bottom: 0;
    padding: 30px;
    background-color: $white;
    border-top: thin solid $light-gray;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.assignee-settings-layout-card-dialog {
    box-shadow: none;
  }
  .assignee-settings-layout-card-accordion {
    margin-left: -41px;
    margin-right: -41px;
    margin-bottom: 0;
    .uk-accordion-content {

    }
    .rhv-company-user-department-new-employee {
      margin-left: 0 !important;
      .rhv-company-user-department-association-outer-wrapper {
        .rhv-company-user-department-association-wrapper-right {
          .MuiAutocomplete-inputRoot {
            padding-right: 0;
          }
          .MuiAutocomplete-endAdornment {
            right: 30px;
            top: 0;
          }
          .rhv-company-office-layout-add-new-employee {
            position: absolute;
            top: 0;
            right: 5px;

          }
          .rhv-company-office-layout-delete-new-employee {
            position: absolute;
            top: 0;
            right: 6px;
          }

        }
        .rhv-company-user-department-association-wrapper {
          margin-left: -3px;
          padding-left: 0 !important;
          .rhv-company-user-department-association input {
           // padding-left: 10px !important;
          }
          .MuiAutocomplete-inputRoot {
           padding-right: 0;
          }
        }
      }
    }
  }
  .company-department-tooltip-wrapper {

    margin-top: 0 !important;

    padding: 20px 31px 20px 41px;
    background-color: $assignee-tab-active-background;

    div {
      margin-bottom: 0;

      select {
        min-width: 250px;
        border: thin #767676 solid;
        border-radius: 4px;
      }
    }

  }
}

.rhv-profile-dialog-photo-wrapper {
  .rhv-profile-dialog-photo {
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;

    span {
      @media only screen and (min-width: 1100px) and (max-width: 1199px) {
        font-size: 14px !important;
        img {
          margin-right: 15px !important;
        }
      }
      @media only screen and (min-width: 960px) and (max-width: 1099px) {
        font-size: 12px !important;
        img {
          margin-right: 5px !important;
        }
      }
    }

    span.rhv-left-span-wrapper {
      display: inline-block;
      margin: 0 !important;

      span.rhv-left-span {
        margin: 20px 0 !important;
        display: inline-block;
        width: 100%;

        span {
          margin: 0;
        }

        img {
          max-width: 50px !important;
          margin-right: 25px;
        }
      }
    }

    span.rhv-right-span-wrapper {
      border-left: thin $light-gray solid;
      display: inline-block;
      margin-left: unset;
      margin-right: unset;

      span.rhv-right-span {
        margin: 20px 0 !important;
        display: inline-block;

        span {
          margin: 0;
        }
      }

    }

    .rhv-profile-dialog-photo-upload-button {
      padding: 10px;
      border-radius: 5px;
      border: 2px $primary solid;

      .uk-link {
        color: $primary;
        font-weight: 500;
      }
    }
  }
}
.color-primary {
  color: $primary;
}
.color-red {
  color: $red;
}

.rhv-registration-final-complete-dialog {
  max-width: 700px !important;
  img {
    max-width: 60% !important;
  }
  .rhv-registration-final-complete-dialog-button-wrapper {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
.rhv-final-registration-phases {
  min-width: 600px;
  .rhv-final-registration-phases-list {
    font-size: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    span.phases-list-element {
      display: inline-block;
      width: 17px;
    }
  }
  .rhv-final-registration-phases-button-wrapper {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
.rhv-profile-dialog-photo.uk-placeholder {
  background-color: #EEF2F6;
}
.final-hr-assignee-settings {
  margin-left: -31px;
  margin-right: -31px;
  margin-bottom: 0;
}
.assignee-settings-sticky-footer {
  box-shadow:  0px -4px 3px 0px rgba(0, 0, 0, 0.04);
  .final-hr-assignee-settings-inside {
    margin-right: -31px;
    margin-left: -1px;
  }
  position: sticky;
  position: -webkit-sticky;
  background-color: $white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  bottom: 0;
  padding: 0 30px 20px 0;
  z-index: 10;
  pointer-events: none;
  .negate-transparency {
    display: inline-block;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: $white;
    margin: 0 !important;
  }
  button {
    pointer-events: all;
  }
}

.global-one-card-container .one-card-left-with-form.assignee-settings-change-password-wrapper {
  padding-left: 30px !important;
}

.uk-grid .assignee-settings-qualities-wrapper {
  padding-left: 75px;
}
.update-employee-layout-list {
  list-style: none;
  margin: 0;
  padding: 0;
  .update-employee-layout-list-item {

  }
}
.rhv-office-layout-progress-icon {
  position: absolute;
  right: -30px;
  top: 8px;
  color: $primary;
  &.rhv-office-layout-progress-icon-settings {
    right: -50px;
  }
}

.rhv-registration-dialog-department-widget {
  width: 50% !important;
  div {
    margin-bottom: 0;

    select {
      min-width: 250px;
      border: thin #767676 solid !important;
      border-radius: 4px;
    }
  }
  &.rhv-registration-dialog-department-widget-dialog div{

    margin-bottom: 20px;
  }
}
.rhv-registration-dialog-footer {
  display: flex;
  justify-content: space-between;
  .company-department-tooltip-wrapper {
    margin-bottom: -25px;
    padding-left: 2px !important;
    div {
      margin-bottom: 0;

      select {
        min-width: 250px;
        border: thin #767676 solid !important;
        border-radius: 4px;
      }
    }
  }
  .rhv-registration-dialog-buttons {
    padding-right: 11px !important;

  }
}
.global-one-card-container .assignee-settings-profile-card .rhv-company-profile-text-error {
  &.negative-margin-30 {
    margin-top: -35px !important;
  }
}

