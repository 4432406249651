@import '../base/colors';

// hr
.hr-without-margin {
  margin: 0;
}

.hr-without-space {
  @media only screen and (max-width : 960px) {
    margin: 20px -25px !important;
  }
  @media only screen and (min-width : 961px) {
    margin: 20px -50px !important;
  }
}

.hr-without-list-space {
  @media only screen and (max-width : 960px) {
    margin: 20px -25px !important;
  }
  @media only screen and (min-width : 961px) {
    margin: 20px -52px !important;

  }
  &.question-and-answers {
    margin: 20px -30px !important;
  }
}

.share-screen-list-item-hr.hr-without-space ,
.client-dossier-list-item-hr.hr-without-space,
.help-card-documents-hr.hr-without-space {
  @media only screen and (max-width : 480px) {
    margin: 20px -20px !important;
  }
  @media only screen and (min-width : 481px) {
    margin: 20px -31px !important;
  }
  @media only screen and (min-width : 961px) {
    margin: 20px -31px !important;
  }
  @media (min-width: 1200px) {
    margin: 20px -42px !important;
  }
}

.uk-accordion .hr-without-space-list,
.uk-list .hr-without-space-list {
  @media only screen and (max-width : 960px) {
    margin: 20px -25px !important;
  }
  @media only screen and (min-width : 961px) {
    margin: 20px -30px !important;
  }
}

.hr-less-space-list {
  margin: 20px -50px !important;
  &.hr-assignee-dossier-summary {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
}
.hr-mobile {
  @media only screen and (max-width : 470px) {
    margin: 20px -20px !important;
  }
}

// the dossier container
.dossier-container {

  padding: 20px 50px 50px 50px;
  @media only screen and (max-width: 960px) {
    margin: 30px 20px 100px 20px!important;
    padding: 0 !important;
    .uk-grid {
      margin-left: 0 !important;
    }
  }

  @media only screen and (min-width: 961px) {
    margin: 152px 100px 60px 100px;
  }

  &.list-container-all-dossiers {
    @media only screen and (max-width: 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width: 961px) {
      margin: 60px 50px 95px 50px;
      &.list-container-all-dossiers-messages {
        margin-top: 80px;
        padding-left: 90px;
        padding-right: 90px;

        .mijn-dossiers-title {
          padding-left: 30px;

          h2 {
            padding-bottom: 0;
            padding-left: 0;
            font-weight: 500;
          }
        }
      }
    }
  }

  &.dossier-container-organisation {
    @media only screen and (max-width: 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width: 961px) {
      margin: 80px 100px 95px 100px;
    }
  }

  &.list-container-support {
    @media only screen and (max-width: 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width: 961px) {
      margin: 80px 50px 95px 50px;
      padding-left: 90px;
    }
  }

  &.list-container-all-dossiers {
    @media only screen and (max-width: 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width: 961px) {
      margin: 60px 50px 95px 50px;
    }
  }

  &.list-container-all-dossiers-dashboard {
    @media only screen and (max-width: 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width: 961px) {
      margin: 80px 50px 95px 50px;
    }

    .list-container-all-dossiers-dashboard-inner {
      margin: 0;
      padding: 0;
      .inner-dossiers-dashboard-inner {
        padding-left: 0;
      }
    }
  }

  // Card body
  .uk-card-body {
    @media only screen and (max-width: 480px) {
      padding: 30px 20px;
    }
  }

  .circle-text-and-button {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-items: flex-end;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    .button-holder {
      max-width: 50%;
      .uk-button {
        margin: 0;
      }

    }
    .circle-and-text {
      max-width: 50%;
    }
  }


  .details-dossier {
    margin-bottom: 20px;
  }

  .the-red-flag {
    @media only screen and (min-width: 1420px) {
      width: 8%;
    }
  }

  .fa-pennant {
    color: #C43C3C;
  }

  // Card radius
  .uk-card {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  // The tabs
  .dossier-tabs {
    margin-bottom: -2px;

    ul {
      margin-bottom: 0;

      &.uk-tab > * {
        &.right-tab {
          padding-left: 0 !important;
        }
      }

      .active-tab {
        padding: 10px;
        background-color: #F0EDF2;
        color: #5C2C5A;
      }

      .left-tab {
        border-top-left-radius: 5px;
      }

      .right-tab {
        border-top-right-radius: 5px;
      }

      .no-active-tab {
        margin-left: -20px;
        padding: 10px;
        border: 1px solid #F0EDF2;
        border-top-right-radius: 5px;
        background-color: #ffff;
        color: #5C2C5A;
      }
    }
  }

  // dossier header - white
  .dossier-header-white {
    background-color: $white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid $light-gray;
    padding: 20px 30px;
    color: $primary-text-color;
    font-weight: 450;
    @media only screen and (max-width: 480px) {
      padding: 15px 25px;
    }
    @media only screen and (max-width: 960px) {
      border-top-left-radius: 3px;
    }
  }

  // dossier header
  .dossier-header {
    background-color: #582F60;
    border-top-right-radius: 10px;
    color: $white;
    // remove top left radius - if the tabs are available
    border-top-left-radius: 10px;
    padding: 20px 30px;
    @media only screen and (max-width: 480px) {
      padding: 15px 25px;
    }
    @media only screen and (max-width: 960px) {
      border-top-left-radius: 3px;
    }

    &.accent-header {
      background-color: $accent-color;
    }
    h3 {
      color: #fff;
    }

    .status-header {
      margin-top: -30px;

      p {
        color: $white;
      }

      span {
        color: $warning;
      }
    }
  }

  // New message
  .card-new-message {
    .fa-envelope {
      font-size: 3.75rem !important;
      color: #653c6e !important;
    }

    .uk-badge {
      margin: -91px 0 0 -16px !important
    }

    .uk-button {
      margin-top: 13px;
    }
  }

  .send-mail-button {
    margin-left: 20px !important;
  }

  // Subheader - alert message
  .message-alert {
    padding: 15px 50px;
    background-color: #F4DADA;
    @media only screen and (max-width: 480px) {
      padding: 15px 25px;
    }

    svg {
      color: $red !important;
      margin-right: 10px;
    }
  }

  // dossier subtitle
  .dossier-subtitle {
    padding: 20px 30px;
    background-color: #F0EDF2;
    color: #582F60;
    font-weight: 600;
    font-size: 20px;
    @media only screen and (max-width: 480px) {
      padding: 20px 25px;
    }
  }

  // dossier paragraph
  .dossier-p {
    padding: 20px 30px;

    .design-status {
      color: $warning;
      margin-left: 20px
    }

    input {
      font-size: 14px;
    }

    .dossier-progress-gilaw {
      margin-bottom: 30px;
    }

    .label-inputfield {
      @media only screen and (min-width: 481px) {
        width: 120px !important;
        &.client-label-input-field {
          width: 200px !important;

        }
        &.assignee-label-input-field {
          width: unset !important;
        }
      }
    }

    .form-inputfield {
      input {
        padding: 0 !important;
      }

      @media only screen and (min-width: 960px) {
        margin-left: 144px !important;
        input:not(.ignore-field-width) {
          width: 200px !important;
        }
        input.ignore-field-width {
          margin-left: 0 !important;
        }
        &.assignee-input-field-wrapper {
          margin-left: 12em !important;
          .uk-input:disabled {
            background-color: $white;
          }
        }
      }
    }

    @media only screen and (max-width: 480px) {
      padding: 25px;
    }

    .space-list-mobile {
      @media only screen and (max-width: 960px) {
        padding-top: 20px;
      }
    }

    input {
      border-bottom: 1px solid #e5e5e5 !important;
      border-top-style: none !important;
      border-left-style: none !important;
      border-right-style: none !important;
      &.assignee-input-field:not(.anonymous-email) {
        border-bottom-style: none !important;
      }
    }
  }

  // accordion title
  .uk-accordion-title {
    font-size: 16px;
  }

  // accordion content
  .uk-accordion-content {
    p {
      font-size: 14px;
    }
  }

  // the pagination
  .dossier-pagination {
    margin-bottom: 0;

    .dossier-pagination-active {
      border-radius: 50%;
      background-color: #5C2C5A;

      a {
        color: #fff !important;
      }
    }
  }

  // The paperclip in the textarea
  .paperclip-mailbox {
    margin-top: 10px;
    position: absolute;
    border-radius: 5px;
    border: 1px solid #5C2C5A;
    padding: 5px 7px;
    color: #5C2C5A;
    height: 30px;
    width: 30px;
    top: 0;
    @media only screen and (max-width: 480px) {
      right: 34px;
    }
    @media only screen and (min-width: 481px) {
      right: 60px;
    }
  }

  // The switcher in the 'berichtenbox' - active
  .active-switcher {
    a {
      background-color: transparent;
      color: #5C2C5A;
      border: 1px solid #5C2C5A;
      text-transform: capitalize;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  // The switcher in the 'berichtenbox' - Not active
  .not-active-switcher {
    padding-left: 0;

    a {
      background-color: transparent;
      color: #cbb3cd;
      border: 1px solid #CBB3CD;
      text-transform: capitalize;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  // The body space in different lists
  .dossier-list-body {
    font-size: 16px!important;
    @media only screen and (max-width: 480px) {
      padding: 25px;
    }
    @media only screen and (min-width: 481px) {
      padding: 20px 50px 20px 50px;
    }
  }

  .no-space-bottom {
    @media only screen and (min-width: 481px) {
      margin-bottom: -40px;
    }
  }

  // The body space in a list with documents
  .dossier-list-with-documents {
    font-size: 16px !important;
    &.dossier-list-with-no-documents {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }

    @media only screen and (min-width : 768px) {
      .the-document {
        padding-left: 20%;
        word-break: break-all;
      }
      .the-document-name {
        width: 30%;
      }
      .the-real-document {
        width: 45%;
      }

    }

    @media only screen and (max-width : 767px) {
      .the-document-name:not(.empty-repeat-document-question) {
        width: 100%;
      }
      .the-real-document.the-first-real-document {
        padding-left: 32px;
      }
      .upload-delete-icons.the-first-upload-delete-icon{
        margin-top: 57px !important;
      }
      .the-document-name:after {

      }
    }
  //}

  // The body space in a list with pagination
  .dossier-list-with-pagination {
    @media only screen and (max-width : 480px) {
      padding: 25px;
      .expand-question-answer-icon {
        right: -15px !important;
      }
    }

  @media only screen and (min-width : 481px) {
      padding: 20px 50px 50px 50px;
    }

    @media only screen and (min-width : 768px) and (max-width : 959px) {
      .the-question {
        width: 35%;
      }
      .the-answer {
        width: 40%;
      }
    }

    @media only screen and (max-width : 767px) {
      .the-answer-header:before {
        margin-left: - 25px;
      }
      .the-question {
        width: 40%;
      }
      .the-answer {
        width: 0;
        padding-left: 40px;
      }
    }

    @media only screen and (min-width : 960px) {
      .the-question {
        width: 25%;
      }
      .the-answer {
        width: 50%;
      }
    }
  }

  .help-card-answer-wrapper {
    position: relative;
    .expand-question-answer-icon {
        position: absolute;
        right: -25px;
        top: 0;
    }
  }

  // The body space in the 'berichtenbox'
  .dossier-messagebox {
    &.no-padding-bottom {
      padding-bottom: 0 !important;
    }
    @media only screen and (max-width : 480px) {
      padding-left: 30px !important;
    }
    .dossier-messagebox-header-date {
      width: 30%;
    }
    .dossier-messagebox-header-sender {
      width: 25%;
    }
    .dossier-messagebox-header-subject {
      @media only screen and (max-width : 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width : 961px) {
        width: 15%;
      }
    }
    .dossier-messagebox-details-date {
      @media only screen and (max-width : 480px) {
        width: 50%;
      }
      @media only screen and (min-width : 481px) {
        width: 30%;
      }
    }
    .dossier-messagebox-details-sender {
      margin-right: 20px;
      width: 22%;
    }
    .dossier-messagebox-details-subject {
      @media only screen and (max-width : 960px) {
        .uk-align-right {
          float: left !important;
          margin-left: 0 !important;
        }
      }
      @media only screen and (min-width : 961px) {
        width: 19%;
      }
    }
    .accordion-position {
      margin-top: -30px;
    }
    .dossier-messagebox-message-litigant {
      padding: 20px;
      margin: 20px 0 20px 40px;
      &.home {
        margin: 20px 40px 20px 0;
        background-color: #F0EDF2;
        color: darken(#F0EDF2, 50%);
      }
      &.away {
        background-color: #F0F0F0;
        margin: 20px 0 20px 40px;
        color: darken(#F0EDF2, 50%);
      }
    }
    .dossier-messagebox-message-text {
      word-break: break-all;
    }
    .dossier-messagebox-message-counselor {
      padding: 20px;
      margin: 20px 40px 20px 0;
      &.home {
        margin: 20px 40px 20px 0;
        background-color: #F0EDF2;
        color: darken(#F0EDF2, 50%);
        
      }
      &.away {
        background-color: #F0F0F0;
        margin: 20px 0 20px 40px;
        color: darken(#F0EDF2, 50%);
      }
    }
    @media only screen and (max-width : 480px) {
      padding: 25px;
    }
    @media only screen and (min-width : 481px) {
      padding: 20px 30px 50px 30px;
    }
  }
}

// The hulp card that we used for the mobile nav
.hulp-card {
  @media only screen and (max-width : 480px) {
   // display: none;
  }

  @media only screen and (min-width : 480px) {
    &.card-bm {
      width: 80%;
      margin: auto;
    }
  }

  &.show-card {
    display: inline;
  }
}
.help-card-answers-markdown-wrapper {
  font-style: italic !important;
}
// The dossier hulp container
.dossier-hulp-container {
  @media only screen and (max-width : 480px) {
    //display: none;
  }

  // The hulp container body
  .uk-card-body {
    @media only screen and (max-width : 480px) {
      padding: 30px 25px;
    }
  }

  // The ranking number for a organisation
  .ranking-number {
    @media only screen and (max-width : 480px) {
      padding-top: 73px;
    }
    i {
      color: orange;
    }
  }

  // hr
  .hulp-hr {
    margin: 0;
  }

  // The container hulpheader
  .hulp-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #582F60;
    min-height: 62.5px;
    &.status-null,
    &.status-vragenflow-afgerond {
      background-color: $accent-color !important;
      display: flex;
    }
    padding: 20px 30px;
    @media only screen and (max-width : 480px) {
      padding: 15px 25px;
    }
    h3 {
      color: #fff;
    }
  }

  // The hulp container subtitle
  .dossier-hulp-subtitle {
    padding: 20px 30px;
    background-color: #F0EDF2;
    color: #272727;
    font-size: 13px;

    @media only screen and (max-width : 480px) {
      padding: 15px 20px;
    }
  }

  .dossier-message-body {
    @media only screen and (min-width : 480px) {
      padding: 40px 50px;
      .margin-none {
        margin: 0 !important;
      }
    }
    .fa-envelope {
      font-size: 55px;
      color: $primary;
      margin-right: 35px;
    }
    .badge-with-envelope {
      margin: -4px 0 0 -48px !important;
    }
    .uk-badge {
      background: $green !important;
    }
    .button-show-message {
      margin-left: 20px;
      margin-top: 5px;
      right: 50px;
      position: absolute;
    }
  }

  // The hulp container body
  .dossier-hulp-body {

    .hulp-card-passende-hulp-first-status {
      border: $debug-border !important;
      display: flex;
      flex-direction: column;
      justify-items: flex-end;
      justify-content: flex-end;
      align-items: flex-end;
      .MuiButton-root {
        text-transform: unset;
      }
      .grant-phone-permission-button {
        background-color: $green;
        color: $white;
      }
    }

    &.hulp-card-request-help-modal,
    &.suitable-help-start-search-filter-view {
      background-color: $accent-color-light !important;
      margin-bottom: 0 !important;
      padding: 20px 30px !important;
      .suitable-help-subtitle {

      }
    }
    @media only screen and (min-width : 480px) {
      padding: 20px 30px;
      .margin-none {
        margin: 0 !important;
      }
      .margin-bottom-five {
        margin-bottom: 5px !important;
      }
      .margin-bottom-twenty {
        margin-bottom: 20px !important;
      }
    }
    &.dossier-hulp-body-name-organisation {
      padding-bottom: 15px;
    }
    .dossier-hulp-select-organisation-title {
      .uk-badge-notification {
        background-color: #5C2C5A !important;
      }
      span {
        padding-left: 10px;
        color: #272727;
      }
    }
    .dossier-hulp-title {
      margin-top: -35px !important;
      .uk-badge-notification {
        background-color: #5C2C5A !important;
      }
      span {
        padding-left: 10px;
        color: #272727;
        &.uk-badge {
          padding-left: 5px;
          color: $white;
        }
      }
    }
    .second-btn {
      margin-left: 10px;
      margin-top: 6px;
    }
    .first-btn {
      margin-top: 6px;
    }
    p {
      padding-top: 20px;
    }
    .hulp-organisation-name {
      padding-top: 0;
    }
    .hulp-organisation-status {
      padding-top: 0;
      margin-bottom: 0;
    }
    .hulp-organisation-time {
      padding-top: 0;
      margin-top: 5px;
    }
    .two-buttons {
      margin-left: 10px;
    }
  }

  // The persons that can see the dossier
  .dossier-shared-persons {
    li {
      height:40px !important;
    }
  }
}
.list-container-all-dossiers-dashboard-inner.uk-grid,
.dossier-container-organisation{
// Menu for mobile
.sticky-fixed-bottom {
  position: fixed;
  pointer-events: none;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  z-index: 1030;
  &.dossier-assignment-client-footer{
    pointer-events: all;
  }
  .dossier-assignment-client-footer-info-wrapper {
    background-color: $very-light-gray;
    margin: -1px;
    .dossier-assignment-client-footer-info {
      padding: 20px;
      max-width: 950px;
      margin: auto;
      .assign-dossier {
        color: $green;
      }
      .more-info-dossier {
        color: $primary;
      }
      .reject-dossier {
        color: $red;
      }
    }

    button.uk-button {
      pointer-events: all;
    }
  }
  .uk-card-default {
    box-shadow: 0 5px 15px #00000069 !important;
  }
  .bottom-nav-mobile {
    z-index: 980;
    padding: 5px;
    a {
      text-align: center;
      font-size: 13px !important;
    }
  }

  .bottom-nav-desktop {
    z-index: 980;
    padding: 0;

    .stick-bottom-multi {
      position: relative;
      display: flex;
      align-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
      justify-items: center;
      bottom: 0;
      .create-dossier-and-links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .rhv-add-new-dossier-button {
        }
      }
      .integrate-iframe {
        pointer-events: all;

      }
      .rhv-get-flow-link-button {
        pointer-events: all;
        color: #999 !important;
        width: 40px;
        height: 40px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
        border-radius: 50%;
        margin-bottom: 20px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 13px !important;
        }
        i {
          font-size: 50px;
        }
      }
    }
    a:not(.rhv-get-flow-link-button) {
      text-align: center;
      font-size: 13px !important;
    }

    .navbar-footer-beheerder {
      padding: 10px 0;

    }
  }
}
}
// selection for example a checkbox
::selection {
  background: #5c2c5a !important;
}

.uk-width-2-3-gi-large {
  @media only screen and (min-width: 1400px) {
    width: calc(100% * 2 / 3.001) !important;
  }
}
