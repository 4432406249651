@import '../base/colors';
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@400;500;600;700&family=Montserrat:wght@400;500;700&display=swap");

.MuiSnackbar-anchorOriginTopCenter {

  @media (min-width: 600px) {
    top: 25% !important;
  }
  @media (min-width: 960px) {
    //margin-left: 130px;
  }

  .MuiAlert-filledWarning {
    color: $primary;
    background-color: $white;
    border: thin $primary solid;
  }
}

.MuiPaper-root {
  color: #666;
  font-family: "Fira Sans Condensed", Montserrat, sans-serif;
  .MuiAutocomplete-listbox {
    font-family: "Fira Sans Condensed", Montserrat, sans-serif;
    .MuiAutocomplete-option {
      font-family: "Fira Sans Condensed", Montserrat, sans-serif;
    }
  }
}

.MuiButton-root {
  min-width: 0 !important;
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
  letter-spacing: normal !important;
}

.MuiButton-root:hover {
  background-color: transparent;
}

.MuiButton-text {
  padding: 0;
}

.MuiButtonBase-root {
  font-size: 16px !important;
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
  &.gi-law-close-modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;

    .MuiButton-label {
      width: unset;
    }
  }
}

.MuiTouchRipple-root {
  display: none;
}
.date-picker-wrapper:hover  {
  .c-btn.c-datepicker-btn {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.c-btn.c-datepicker-btn {
  .MuiButton-label {
    color: #5a5858;
    text-transform: none;
    font-size: 16px;
  }

  .keyboard-date-picker {
    .MuiIconButton-label {
      svg {
        transform: scale(1.3);
      }
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInput-underline {
    padding: 20px;

    &:before,
    &:after {
      border: thin transparent solid !important
    }
  }

  .MuiInput-input {
    text-align: center;
    margin-right: 37px;
  }

  .MuiDialog-root {
    font-family: "Montserrat", sans-serif;
  }

  .MuiInputBase-input {
    font-family: "Montserrat", sans-serif;
  }
}

.MuiPickersModal-dialogRoot {

  .MuiPickersToolbar-toolbar {
    background: linear-gradient(to right, #4067A1 0%, #5C2C5A 100%);
    height: 200px;
  }

  .MuiPickersCalendarHeader-dayLabel {
    color: #0000008a;
    font-weight: 700;
    font-family: "Fira Sans Condensed", sans-serif;
  }

  .MuiPickersDay-day {
    color: #000000cc;

    &:hover {
      background-color: #5c2c5a;
      color: $white;
    }

    font-family: "Fira Sans Condensed", sans-serif;
  }

  .MuiPickersDay-daySelected {
    background-color: #0000000d;
    color: #000000cc;

    &:hover {
      background-color: #5c2c5a;
      color: $white;
    }

  }

  .MuiTypography-body1 {
    font-family: "Fira Sans Condensed", sans-serif;
    font-weight: 800;
    color: #666;
    font-size: 14px;
  }

  .MuiTypography-body2 {
    font-family: "Fira Sans Condensed", sans-serif;
  }

}
.hide-snackbar {
  display: none;
}

.client-share-case-modal-dialog {
  border: $debug-border;
  label {
    font-size: 16px !important;
    color:$medium-text-color;
  }
  .client-permission-share-rhv-company-modal-fixed-footer {
    position: absolute;
    bottom: 0;
    background-color: $white;
    border-bottom-right-radius: 10px;
    width: 50%;
    hr {
      margin-bottom: 0;
    }

    .git-law-modal-button-wrapper {
      padding: 30px;
    }
  }
  .client-permission-share-rhv-company-modal.uk-modal-body {
    overflow-x: hidden;
    .gi-law-close-modal-button {
      z-index: 100;
    }
    padding: 0;
    .client-share-case-modal-wrapper {
      pointer-events: all;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 20px;
      min-height: 60vh;
    }
    .client-share-case-modal-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .client-share-case-modal-title-inner-wrapper {
        margin-left: -30px;
        margin-right: -30px;
        background-color: $accent-color;
        h4 {
          color: $white;
          margin-top: 20px;
          font-size: 20px !important;
        }
      }
      hr {
        margin: 0 0 10px 0;
      }
      .client-share-case-modal-title {
        margin-top: 0;

        padding-left: 30px;
      }

    }
    hr.modal-second-hr {
      margin-top: 20px;
    }
  }
  .MuiPaper-root {
    color: #666 !important;
    overflow: hidden;
  }
}
.MuiSnackbar-anchorOriginTopCenter.must-continue-snackbar-alert {
  top: unset !important;
}
