@import '../base/colors';
@import '../base/constants';
$maxWidthDialog: 1050px;

.modal-with-checkbox-first-hr {
  margin: 20px -30px 50px -30px; }

.modal-first-hr {
  margin: 20px -30px; }

.modal-second-hr-top-green {
  margin: 0 -30px 30px -30px;
}

.modal-with-checkbox-hr {
  margin: 30px -30px 30px -30px; }

.modal-second-hr {
  margin: 40px -30px 30px -30px; }

.modal-hr-without-margin-top {
  margin: 0 -30px 30px -30px; }

.uk-modal-title {
  &.primary {
    color: $primary;
  }
  &.warning {
    color: $red;
  }
  &.positive {
    color: $green;
  }
}

.flow-link-dialog-autocomplete {
  padding-left: 15px;
  padding-right: 5px;
  border: thin $light-gray solid;
  border-radius: 4px;
  .MuiInput-root.MuiInput-underline.MuiInputBase-root {
    &:before,
    &:after {
      display: none;
    }

  }
}

.modal-content-no-margin-bottom {
  margin-bottom: -70px !important;
  @media only screen and (max-width : 960px) {
    margin-bottom: 0 !important;
  }
  img {
    max-width: 80% !important;
  }
}

.gi-law-small-padding-modal {
  padding: 10px 30px;
}

.gi-law-less-bottom-padding-modal {
  padding: 30px 30px 10px 30px;
}

.gi-law-all-padding-modal {
  padding: 30px;
}
.not-dialog-feedback-header {
  font-weight: 600;
}
.assignee-dashboard-bespoke-dossier-dialog.MuiDialog-root  {
  position: fixed;
  top: 0;
  left: unset;
  right: unset;
  bottom: unset;
  overflow-y: auto;
  width: 100vw;
  max-height: 100vh;
  .MuiDialog-container {
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    .MuiPaper-root {
      color: $medium-text-color;
      font-family: "Fira Sans Condensed", Montserrat, sans-serif;
    }
  }
  .bespoke-dossier-label-wrapper {
    margin-top: 7px;
  }
  .dialog-footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: thin $light-gray solid;
    padding: 30px;
  }

  #make-bespoke-dossier-dialog {

    .summary-wrapper {
      position: relative;
      overflow-y: auto;
      .summary-section-uploaded-files-list {
        max-height:60px;
        width: calc(100% - 100px);
        overflow-y: auto;
        z-index: 150;
      }
      &.summary-with-attachments {
        &.summary-with-1-attachments {
          .DraftEditor-editorContainer {
            padding-bottom: 25px;
          }
        }

        &.summary-with-2-attachments {
          .DraftEditor-editorContainer {
            padding-bottom: 45px;
          }
        }

        &.summary-with-3-attachments,
        &.summary-with-many-attachments{
          .DraftEditor-editorContainer {
            padding-bottom: 65px;
          }
        }
      }
    }
    .summary-section-uploaded-files-list {
      position: absolute;
      bottom: 0;
      left:50px;
      .summary-section-uploaded-file-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
        .summary-section-uploaded-file-item-trash {
          cursor: pointer;
          z-index: 200;
        }
      }
    }

    .summary-section-upload-files {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 100;
      a {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 3px;
        border: thin $light-gray solid;
        font-size: 20px !important;
      }
      input {

      }
    }

    .shared-worker-list {
      padding: 10px 0;
      position: relative;
      .shared-worker-item {
        float: left;
        margin-right: 10px;
        margin-bottom: 3px;
        padding: 2px 4px 2px 15px;
        background-color: $primary;
        color: $white;
        font-size: 16px !important;
        border-radius: 14px;
        .shared-worker-item-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          .shared-worker-item-name {
            font-size: 16px !important;
            margin-right: 10px;
          }

          .MuiButtonBase-root {
            padding: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            &:hover {
              background-color: $primary-dark;
            }

            img {
              width: 10px;
              height: 10px;
              max-width: unset !important;
            }
          }
        }
      }
    }

    .rich-text-editor-wrapper {
      .DraftEditor-root {
        font-family: "Fira Sans Condensed", Montserrat, sans-serif;
        min-height: 100px;
        .DraftEditor-editorContainer {
          font-family: "Fira Sans Condensed", Montserrat, sans-serif;
        }
      }

      .code-to-underline-class span{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=") !important;
      }
    }
  }
}
.wider-min-width-popup-dialog {
  position: relative;
  .MuiDialog-container {
    .MuiPaper-root {
      min-width: 750px;
      max-width: 750px;
      max-height: unset;
    }
  }
}
.medium-min-width-popup-dialog {
  min-width: 550px;
}
.fix-modal-no-scroll-width {
  .MuiPaper-root {
    max-width: unset;
  }
}
.medium-max-width-popup-dialog {
  max-width: 550px;
}

.assignee-dashboard-create-new-dossier-dialog {

  .button-footer-wrapper {
    pointer-events: all;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .filled-accent-button.uk-button {
      pointer-events: all;
      padding: 3px 20px !important;
      border: thin $accent-color solid;
      margin-right: 8px;
      &:hover {
        border-color: $accent-color-hover;
        background-color: $accent-color-hover !important;
        color: $white !important;
      }
      &:disabled {
        background-color: $accent-color-disabled !important;
        border-color: $accent-color-disabled;
        cursor: progress;
        &:hover {
          background-color: $accent-color-hover !important;
          border-color: $accent-color-hover;
        }
        &.invalid-submit-button {
          cursor: not-allowed;
        }
      }
    }
    .close-modal-button.uk-button {
      padding: 3px 20px !important;
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    max-width: $maxWidthDialog;
  }
  .MuiInput-root.MuiInput-underline,
  .MuiInput-root.MuiInput-underline:hover{
    &::before{
      border-bottom-width: 1px !important;
      border-color: $light-gray;
    }
    color: #666 !important;
  }
}

.assignee-dashboard-get-link-flow-dialog {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    max-width: 700px;
  }
  .show-code-to-embed-wrapper {
   // border: thin red solid ;
    display: flex;
  }
}

.gi-law-no-padding-bottom-modal {
  padding: 30px 30px 0 30px;
}
.gi-law-modal-header {
  margin-top:10px;
}

.gi-law-modal-title {
  margin-top: 0;
  &.gi-law-modal-title-warning {
    color: $red ;
  }
}

.git-law-modal-button-wrapper {
  margin-bottom: 0;
}

.gi-law-close-modal-button {
  img {
    height: 25px;
  }
}
.modal-no-padding-bottom {
  padding-bottom: 0 !important;
}

.modal-green-bg-helpdesk {
  background-color: #EDF2DF;
  margin: 20px -30px 0 -30px;
  padding: 30px;

  .modal-code-helpdesk-block {
    border: 1px solid #95a126;
    padding: 20px;
    font-size: 20px;
    width: 50%;
    border-radius: 4px;
    .modal-code-helpdesk-text {
      color: #95a126;
      text-transform: uppercase;
    }
  }
}

.modal-code-helpdesk-block {
  border: 1px solid #95a126;
  padding: 20px;
  font-size: 20px;
  width: 50%;
  border-radius: 4px;
  .modal-code-helpdesk-text {
    color: #95a126;
    text-transform: uppercase;
    span {
      color: #B7B7B7;
      font-size: 20px !important;
    }
  }
}

.modal-p-margin-bottom {
  margin-bottom: 40px;
}

.modal-accept-checkbox {
  margin: 40px 0 -20px 0;
  max-width: 120px !important;
}

.modal-accept-checkbox-new-dossier {
  padding-top: 70px;
  user-select: none;
  .modal-accept-checkbox {
    margin-right: 8px;
  }
}

h3 {
  &.primary-color {
    color: $primary !important;
  }

  &.success-color {
    color: $green !important;
  }

  &.warning-color {
    color: $warning !important;
  }

  &.danger-color {
    color: #C43C3C !important;
  }
}

.modal-important-information {
  .first-block {
    background-color: #F0EDF2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 30px;
  }
  .second-block {
    background-color: #D9D9D9;
    padding: 30px 30px 30px 0;
    margin-left:0;
    svg {
      font-size: 35px !important;
    }
  }
}

.uk-modal-dialog-large {
  width: 740px !important;
  min-width: 740px !important;
  &.no-top-padding {
    padding-top: 0 !important;
  }
}

.uk-modal-dialog-medium {
  width: 540px !important;
}

.error-message {
  color: $red;
  font-size: 13px;
  margin-top: -13px !important;
}

.modal-important-information .first-block {
  background-color: #F0EDF2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 30px;
}

.modal-important-information .second-block {
  background-color: #D9D9D9;
  padding: 30px 30px 30px 0;
  margin-left: 0;
}

.modal-important-information .second-block svg {
  font-size: 35px !important;
}


.modal-with-less-padding-bottom {
  padding: 30px 30px 10px 30px !important;
}

.modal-normal-padding {
  padding: 30px !important;
}

.check-password-eye {
  user-select: none;
  position: absolute;
  right: 0;
  margin-top: 10px;
  cursor: pointer;
}

.change-password-page {
  .check-password-eye {
    top: 10px;
    right: -37px;
  }
}

.global-one-card-container .one-card-left-with-form {
  .change-password-page-indicator-wrapper {
    span {
      margin: 0;
    }
  }
}

.rhv-registration-no-user-select {
  user-select: none;
}
.position-relative {
  position: relative;
  margin-top: 0 !important;
}
.position-relative-only {
  position: relative;
}

.background-overflow-auto {
  background-color: rgba(88,48,96,0.2);
  border-radius: 10px;
  max-height: 35vh;
}

.important-subtitles {
  font-size: 20px !important;
  font-weight: 500;
  &.second-subtitle {
    margin-top: 40px;
  }
}
.fix-top-margin{
  margin-top: 20px !important;
}
.uk-modal-dialog-large-wrapper {
  &.rhv-registration-large-window {
    .MuiPaper-root {
      min-height: 90vh;
    }
  }


  &.forgotten-password-email-sent-wrapper  {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    .forgotten-password-email-sent {
      background-color: $white;
      margin: auto;
      border-radius: 5px;
    }
  }
}
.uk-alert-primary.password-changed-message {
  padding: 10px 20px;
  color: $primary;
  border-radius: 5px;
}
.forgot-password {
  margin: -10px 0 10px 20px;
}

.light-red-warning-block {
  width: 45% !important;
  background-color: #F3D8D8 !important;
  padding: 10px !important;
  margin-left: 140px !important;
  position: absolute;
  border-radius: 10px
}

.modal-header-hr-more-top {
  margin-top: 60px !important;
}

.light-red-warning-block-alert-icon {
  margin-right: 5px;
}

.modal-textarea {
  display: none;
}

.share-error {
  color: $red;
  font-style: italic;
}
.search-for-assignee-wrapper,
.client-search-for-company-form-wrapper{
  .MuiInput-underline:before {
    display: none;
  }
  #assign-rhv-to-case {
    padding: 8px 3px 8px 35px;
    border: 1px solid #653c6e !important;
    border-radius: 4px;
   // border: thin red solid !important;
  }
  button.MuiAutocomplete-clearIndicator,
  button.MuiAutocomplete-popupIndicator,
  span.MuiTouchRipple-root,
  .MuiAutocomplete-endAdornment
  {
    display: none !important;
  }
  .MuiAutocomplete-root.Mui-focused {

  //  border: thin red solid !important;
  }
  .MuiInputBase-root {
   // border: thin green solid !important;

  }
}

.MuiInput-underline:after
{
  border-bottom-width: 0 !important;
}
.MuiAutocomplete-popper {
//  border: thin red solid !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
  padding-right: 6px !important;
}

.left-welcome-text {
  padding-top: 5px;
}

.right-welcome-illustration {
  margin: 20px 75px 0 0;
}


.gi-law-login-forgotten-password-wrapper {
  background-color: white;
  div {
    width: 40vw;
  }
}

.client-share-case-modal-dialog-confirm {
  .MuiDialog-paperWidthSm{
    max-width: unset;
  }
}
.rhv-final-registration-modal-wrapper {
  max-width: $maxWidthDialog;
  min-width: $maxWidthDialog;
  padding: 30px 0;
  h3, p , .uk-form-horizontal, .accept-padding  {
    padding-left: 30px;
    padding-right: 30px;
  }
  .rhv-final-registration-second-hr {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .rhv-final-registration-modal-window { // 94 at bottom + 63 + 40 + 37 + 30
    max-height: calc(90vh - 275px);
    min-height: calc(90vh - 275px);
    overflow-y: scroll;
    margin-top: -1px;
    border-top: thin $light-gray solid;

    .office-hours-widget-day-label {
      font-size: 18px;
      min-width: 5em;
    }
  }
}

.terms-and-conditions-main-header {
  color: $primary;
  font-size: 24px !important;
  font-weight: 500  ;
}
.terms-and-conditions-definition-header {
  color: $primary;
  font-size: 23px !important;
  font-weight: 400  ;
}
.terms-and-conditions-definition-paragraph {
  color: $primary;
}
.space-request-help-modal.uk-margin,
.space-request-help-modal {
  margin-top: 20px !important;
}

.share-dossier-internally-modal-header {
  margin-top: 0;
  font-weight: 600;
  label {

  }
}

.share-dossier-internally-modal {
  display: flex;
  flex-direction: column;

  .uk-radio {
    margin-right: 1em;
  }
  .transfer-dossier-internally-snackbar {
    position: absolute;
    left: 150px;
  }

  .gi-law-margin-modal-sub-header {
    margin-top: 1.4em ;
    margin-bottom: 0.8em ;
    font-weight: 600;
  }
  p.final-paragraph {
    margin-bottom: 0;
  }
  .gi-law-modal-checkbox-wrapper.uk-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 1.4em 0 0 0;
    .modal-accept-checkbox {
      padding-left: 0;
      width: calc(100% * 1 / 12.001) !important;
    }
    .modal-accept-checkbox-label {
      padding-left: 0;
      width: calc(100% * 11 / 12.001) !important;

    }
  }
}

.no-vertical-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.rhv-external-autocomplete-email {
  font-size: 16px !important;
  border-bottom: thin $light-gray solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  padding-left: 0.5em;
}
#rhv-external-autocomplete-id {
  max-width: 100%;
  width: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#rhv-external-autocomplete-id-listbox {
  li {
    span.admin-dossier-list-search-filter-option {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        transition: all 0.3s ease-in;
        transition-property:max-height, overflow, white-space, text-overflow;
        text-overflow: unset;
        white-space: pre-wrap;
        word-break: break-all;
      }
      &.client-with-single-dossier:hover{
        &:before {
          content: '-';
        }
      }
      &.client-with-multiple-dossiers:hover {
        &:before {
          content: '+';
        }
      }

    }
    div.assignee-dossier-list-search-filter-option {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        transition: all 0.3s ease-in;
        transition-property:max-height, overflow, white-space, text-overflow;
        text-overflow: unset;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
}

.rhv-external-autocomplete {
  overflow: hidden;
}
.rhv-colleague-autocomplete,
.rhv-external-autocomplete{
  overflow-x: hidden;
  font-size: 16px !important;
  border: thin $light-gray solid;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .rhv-external-autocomplete-search-icon,
  .rhv-colleague-autocomplete-search-icon {
    padding: 5px;
  }
  #rhv-colleague-autocomplete-id,
  #rhv-colleague-autocomplete-id.MuiInput-input.MuiInputBase-input {
    width: 100% !important;
  }
  input::placeholder {
    font-size: 16px !important;
  }
  .MuiAutocomplete-root{
    width: 100%;
    .MuiAutocomplete-inputRoot {
      padding-right: 0 !important;
      &:before {
        display: none !important;
      }
      .MuiAutocomplete-endAdornment {
       // display: none;
      }

    }

    .MuiInput-root {
      font-size: 16px !important;
    }
  }

}
.rhv-action-dropdown-modal {
  label,
  form ,
  div{
    font-size: 16px !important;
  }
}
.transfer-dossier-internally-modal-textarea,
.rhv-opt-out-of-registration-modal-textarea {
  border: thin $light-gray solid;
  border-radius: 4px;
  resize: none;
  &.other-reason {
    margin-left: 30px;
  }
  &.other-reason-align-radio {
    margin-left: 10px;
  }
  padding: 10px;
}
.modal-accept-non-checkbox-text {
  margin-left: 40px;
  font-size: 18px;
}
.link-dialog {
  .list-type-options {
    padding-right: 15px;
    margin-right: 40px;
  }
  .choose-options {
    padding-right: 40px;
    margin-left: -40px;
    padding-left: 15px;
  }
}
.code-to-embed-dialog {
  .automatically-assign-dossier-to-admin {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
    width: 100%;
    .label {
      padding: 15px 15px  0 15px;
    }
    .checkbox {
      padding: 15px 0 0 0 ;
    }
  }

  .show-custom-color-scheme {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
    width: 100%;
    .label {
      padding: 15px 15px  0 15px;
    }
    .checkbox {
      padding: 15px 0 0 0 ;

    }
  }
  .color-picker-wrapper {
    display: flex;
    justify-content: center;
    font-family: "Fira Sans Condensed", Montserrat, sans-serif;
    margin-right: 40px;
    &.color-picker-open {
      margin-bottom: 125px;
    }
    &.color-picker-closed {
      margin-bottom: 10px;
    }
    .colorPickerArea {
      input {
        font-family: "Fira Sans Condensed", Montserrat, sans-serif;
        border-color: $light-gray;
      }
      .colorSquareInner {
        border-color: $light-gray;
      }
      .chrome-picker {
        border-right: 10px !important;
        font-size: 18px;
        min-height: 120px;
        .flexbox-fix {
          label {
            display: none;
            opacity: 0;
            width: 0;
            height: 0;
          }
          div[style="height: 10px; position: relative;"] {
            display: none;
          }
          div[style="height: 10px; position: relative; margin-bottom: 8px;"] {
            margin-top: 8px;
          }

          div[style*="width: 32px; text-align: right;"] {
            display: none;
          }

        }
      }
    }
    .color-picker-right-wrapper {
      margin-left: 15px;
      .color-picker-right.invalid-color {
        input,
        .colorSquareInner {
          border-color: $red;
        }
      }
    }
    .color-picker-left-wrapper {
      margin-right: 15px;
      .color-picker-left.invalid-color {
        input,
        .colorSquareInner {
          border-color: $red;
        }
      }

    }
  }
}
.code-to-embed-dialog,
.assignee-dashboard-get-link-flow-dialog{

  .copy-to-clipboard-outer-wrapper {
    position: relative;
    max-width: 0;
    padding: 0 !important;
    margin: 0;
    left: -40px;
    overflow: visible;
    .generate-code-button-wrapper,
    .copy-to-clipboard-wrapper {
      position: absolute;
      width: 41px !important;
      max-width: 41px !important;
      top: 0;
      left: 0;
      button {
        width: 35px !important;
        max-width: 35px !important;
        height: 35px !important;
        max-height: 35px !important;
        padding: 10px!important;
        border-radius: 50% !important;
        margin-left: 3px;
        color: $primary;
        &:disabled {
          color: #ccc;
        }
        svg {
          width: 16px !important
        }
      }
    }
  }
  .show-code-to-embed-wrapper {
    .show-code-to-embed {
      display: flex;
      white-space: break-spaces;
    }

    .copy-code-to-clipboard-outer-wrapper {
      position: relative;
      padding: 0 !important;
      margin: 0;
      overflow: visible;
      .copy-code-to-clipboard-wrapper {
        width: 41px !important;
        max-width: 41px !important;
        top: 0;
        left: 0;
      }
    }
  }
  button.round-button {
    width: 35px !important;
    max-width: 35px !important;
    height: 35px !important;
    max-height: 35px !important;
    padding: 10px!important;
    border-radius: 50% !important;
    margin-left: 3px;
    color: $primary;
    &:disabled {
      color: #ccc;
    }
    svg {
      width: 16px !important
    }
    &.round-button-panel {
      margin-right: 50px;
      border: thin $primary-light-hover solid;
      svg {
        width: 16px !important;
        max-width: unset;
      }
    }
  }
}

#confirm-profile-download {
  .download-icon-wrapper {
    margin: 0 !important;
    text-align: right;
  }
  .modal-second-hr {
    margin-top: 20px;
  }
}

.client-delete-profile-rescinded {
  .MuiPaper-root {
    max-width: unset;
  }
}


.phone-permission-shared-not-shared {
  #phone-permission-shared-not-shared-dialog {
    position: relative;

    .phone-permission-shared-not-shared-first-para {

    }

    .phone-permission-shared-not-shared-phone-number-wrapper {
      display: flex;
      font-size: 16px !important;
    }

    .phone-permission-phone-number-error-wrapper {
      position: relative;
      .error-inner {
        position: absolute;
        overflow: visible;
        top: -7px;
      }
      margin-bottom: 20px;

      max-height: 0;
    }

    .phone-permission-shared-not-shared-confirm-wrapper {
      display: flex;

      .permission-checkbox {
        margin-right: 10px;
      }

      .permission-text {
        font-size: 16px !important;
      }
    }

    .phone-permission-change-confirmed-error-wrapper {

    }
  }
}
$border-radius: 10px;
.client-permission-share-rhv-company-modal-outer.MuiDialog-root {

  @if not ( $scroll-rhv-info-for-client) {

  }
  .MuiDialog-container {
    @if not ( $scroll-rhv-info-for-client) {
      max-height: unset !important;
      overflow-y: scroll;
      display: grid;
    }
    .MuiPaper-root {
      border-radius: $border-radius;
      @media only screen and (min-width: 1400px) {
        max-width: 70vw;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px){
        max-width: 80vw;
      }
      @media only screen and (min-width: 960px) and (max-width: 1199px){
        max-width: 90vw;
      }
      @media (min-width: 1600px) {
        max-width: 70vw;
      }
      @if not ($scroll-rhv-info-for-client) {
        max-height: unset;
        overflow: visible;
        margin-top: 20px ;
        margin-bottom: 20px ;
      }

      .client-permission-share-rhv-company-modal-header {
        padding: 20px 30px;
        background-color: $primary;
        border-top-right-radius: $border-radius - 2px;;
        border-top-left-radius: $border-radius - 2px;;
        color: $white;
        font-size: 22px !important;
      }

      .client-permission-share-rhv-company-modal-wrapper.uk-grid {
        margin-left: unset;
        .submit-form-left-panel {
          padding-left: unset;
          .organisation-details-first-block-inner {


          }

          .submit-form-left-panel-header {
            padding: 20px 30px;
            background-color: $primary-light !important;
            font-size: 20px !important;
            line-height: 26px;
          }

          .rhv-terms-of-service-section-wrapper {
          }

          .organisation-details-wrapper {
            padding: 30px;
            @if $scroll-rhv-info-for-client {
              overflow-y: scroll;
              max-height: 60vh;
            }
            padding-top: 20px !important;

            .organisation-details-inner {

              .rhv-detail-header {
                color: $primary !important;
                margin-bottom: 5px !important;
                margin-top: 20px !important;
                font-size: $rhv-detail-header-size !important;
              }

              .organisation-details-left-block {

                padding: 0 !important;
              }

              .organisation-details-right-block {
                .rhv-terms-of-service-section-wrapper {
                  .rhv-terms-of-service-section-line-wrapper {
                    display: flex;
                    margin-bottom: 10px;

                    .term-of-service-value {
                      font-weight: 600;
                    }

                    div {
                      font-size: 16px !important
                    }
                  }
                }

                .rhv-terms-of-service-section-wrapper {
                  .rhv-terms-of-service-section-line-wrapper {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px;

                    .term-of-service-name {
                      display: flex;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      width: 40%;
                      padding: 0;
                      font-size: 16px !important;

                      .terms-of-service-select-info-icon-wrapper {
                        position: relative;
                        bottom: -2px;
                        left: 5px;

                        svg {
                          margin: 0 !important;
                        }
                      }
                    }

                    .term-of-service-markdown-value,
                    .term-of-service-value {
                      width: 59%;
                      font-size: 16px !important;
                      padding: 0 16px;
                    }

                    .term-of-service-value {
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }

        }

        .gi-law-close-modal-button {
          img {
            svg {
              fill: #FFFFFF;
            }

            color: $white !important;
          }
        }

        .submit-form-right-panel {
          background-color: $accent-color-light !important;
          padding-left: 0;
          border-bottom-right-radius: $border-radius;
          .git-law-modal-button-wrapper {
            background-color: $accent-color-light;
            border-bottom-right-radius: $border-radius;
          }

          .client-permission-share-rhv-company-modal-fixed-footer {
            border-bottom-right-radius: $border-radius;
          }

          .uk-checkbox {
            background-color: $white;
          }
        }
      }
    }
  }
}
