@import '../base/colors';

.green-banner {
    background-color: $green;
    color: $white;
    border: 1px solid $white;
    border-radius: 8px;
    margin-left: 285px;
    padding: 8px 15px;
    width: 600px;
    .green-banner-text {
      padding-bottom: 2px;
    }
    .green-banner-text {
      padding-bottom: 2px;
    }
    .green-banner-button {
      margin-bottom: 0;
    }
  @media (max-width: 1204px) {
    width: 550px;
  }
  @media (min-width: 1205px) {
    width: 600px;
  }
}