@import '../base/colors';

.toaster-succes {
  color: $green !important;
}

.toaster-warning {
  color: $warning !important;
}

.toaster-danger {
  color: $red !important;
}

.uk-notification-message-success {
  color: $green !important;
}

.uk-notification-message-warning {
  color: $warning !important;
}

.uk-notification-message-danger {
  color: $red !important;
}