@import '../base/colors';

textarea {
  font-family: 'Fira Sans Condensed', sans-serif !important;
  outline-color: $white;
  ::selection {
    border-color: #5C2C5A !important;
  }
  &.uk-textarea.message-box {
    resize: none;
    border-color: transparent;
    &:focus {
      outline: none;
      border-color: transparent;
    }
  }
}
.message-box-wrapper {
  font-size: 16px !important;
   border: thin $light-gray solid;
  textarea {
    font-size: 16px !important;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: -40px;
    margin-bottom: 10px;
  }
}

.uk-input {
  &.disabled-but-showing {
    &:disabled {
      background-color: transparent;
      color: $medium-text-color;
    }
    border: none;
  }
  &.form-with-only-border-bottom {
    border-bottom: 1px solid #e5e5e5 !important;
    border: none;
    &.form-field-with-errors {
      div {
        color: $red !important;
      }
    }
  }
  &.date-of-birth.empty-input-field {
    &::-webkit-datetime-edit-fields-wrapper{
      color: #999999 !important;
    }
  }
}
.uk-select {
  &:focus {
    outline: none !important;
    border-color: $light-gray !important;
  }
  &.form-field-with-errors {
  }
}

.uk-search-input {
  height: 44px !important;
}
.client-permission-remark-textarea {
  min-height: 145px;
  font-size: 16px !important;
}
.get-help-modal-phone-permissions.uk-margin {
 margin-top: -10px !important;
}
input {
  //-webkit-background-clip: text !important;
}

.no-border-on-focus:focus:not([tabindex^='-'])
{
  outline: none !important;
}
.no-border-on-focus {
  button:focus,
  svg:focus{
    outline: none !important;
  }
}

.no-border-on-focus:focus:not(:focus-visible){
  outline-color: red !important;;

}
:focus:not([tabindex^='-']){

  outline: none !important;
}
