@import '../base/colors';

.uk-alert-danger.uk-alert {
  color: $red;
}


 .MuiAlert-filledInfo  {
   background-color: $primary !important;
   border: thin white solid;
 }

.login-failed-alert {
  display: flex;
  background: #fef4f6;
  color: #f0506e;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 10px;
  opacity: 1;
  transition: opacity 1s ease-out;

  &.not-login-failed {
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  span {

    padding:  15px 10px 15px 20px;
    &::selection {
      background-color:$red !important;
    }
  }
  .MuiButtonBase-root {
    margin-right: 5px !important;
    width: 44px;
    height: 44px;
    padding:  10px;
    img {
      height: 25px;
    }
  }
}
