@import '../base/colors';

.width-login-card {
  @media only screen and (min-width : 700px) {
    width: 40vw;
  }
}

.card-radius {
  border-radius: 5px;
}

.button-login-card {
  margin-top: 60px;
  margin-bottom: -30px !important;
  &.login-form {
    margin-top: 20px;
  }
}

.logo-login-card {
  img {
    height: 50px;
    margin: 35px 35px 0 0;
  }
}
