@import '../base/colors';
// Buttons that we have changed

.uk-button {
  font-size: 1rem !important;
  border-radius: 4px !important;
  padding: 4px 20px !important;
  line-height: 34px !important;
  text-transform: initial !important;
  font-weight: 400 !important;
  &.before-and-next-button {
    padding: 13px 25px !important;
    font-size: 1.2rem !important;
  }
  svg {
    &.before {
      @media only screen and (max-width : 480px) {
        margin-right: 7px;
      }
      @media only screen and (min-width : 481px) {
        margin-right: 20px;
      }
    }
    &.after {
      @media only screen and (max-width : 480px) {
        margin-left: 5px;
      }
      @media only screen and (min-width : 481px) {
        margin-left: 17px;
      }
    }
  }
}
.submitting-second-button:disabled {
  cursor: progress;
}
.close-modal-button.uk-button {
  margin-right: 5px;
}

.uk-button-default-gl {
  color: $white !important;
  background-color: transparent !important;
  border: 1px solid $white !important;
}

.uk-button-default-gl:hover {
  color: $white !important;
  background: rgba(255, 255, 255, 0.3) !important;
}

.uk-button-primary {
  background-color: $white !important;
  color: $primary  !important;
}

.uk-button-primary:hover {
  color: $white;
  background: rgba(255, 255, 255, 0.3);
}

.uk-button-stop {
  background-color: $red !important;
  color: $white !important;
}

.uk-button-stop:hover {
  background-color: #A22b2b !important;
  color: $white !important;
}

.uk-button-accept {
  background-color: $green !important;
  color: $white !important;
}

.uk-button-accept:hover {
  background-color: #5b6418 !important;
  color: $white !important;
}

.uk-button-secondary {
  background-color: $primary !important;
  color: $white !important;
}
.filled-accent-button {
  background-color: $accent-color !important;
  color: $white !important;
  user-select: none;
}
.button-default-purple-border,
.button-default-purple-border.MuiButtonBase-root.Mui-disabled
{

  &:disabled {
    pointer-events: all;
    cursor: progress;
    &.invalid-submit-button,
    &.screen-share-completed{
      cursor: not-allowed;
    }

  }
}

.rhv-company-info-button-default,
.rhv-company-info-button-default.MuiButtonBase-root.Mui-disabled {
  &.accent-button {
    color: $accent-color !important;
  }
  &.not-accent {
    color: $primary !important;
  }
  opacity: 1;
  background-color: transparent;
  &:disabled {
    pointer-events: all;
    cursor: progress;

    &.invalid-submit-button,
    &.screen-share-completed {
      cursor: not-allowed;
    }

  }
}

.uk-button-secondary,
.uk-button-secondary.MuiButtonBase-root.Mui-disabled{

  &:disabled {
    background-color: $primary-disabled !important;
    pointer-events: all;
    cursor: progress;
    &.invalid-submit-button {
      cursor: not-allowed;
    }
    &:hover {
      background-color: $primary-dark !important;
    }
  }
}

.hover-dark-purple:hover {
  background-color: $primary-dark !important;
}

.uk-button-secondary:hover  {
  background-color: $primary-dark !important;
}

.button-default-purple-border {
  color: $primary !important;
  background-color: $white;
  border: 1px solid $primary !important;
  &.accent-button {

    color: $accent-color !important;
    border: 1px solid $accent-color !important;
  }
  &:disabled {
    color: #BBB !important;
    border: 1px solid #E0D8E0 !important;
    background-color: $secondary-disabled;
    &:hover {
      color: $primary !important;
    }
  }
}

.hover-transparent:hover {
  background-color: #CCC0CC !important;
  border-radius: 4px !important;
  &.circle-btn {
    border-radius: 50% !important;
  }
}

.hover-transparent.accent-button:hover {
  background-color: $white !important;
}

.hover-transparent.filled-accent-button:hover {
  color: $accent-color !important;
  background-color: $white !important;
}
.hover-dark-purple:hover {
  background: $primary-dark !important;
  border-radius: 4px !important;

}

.hover-white:hover {
  background: $white !important;
  border-radius: $primary !important;
}

.uk-subnav > .uk-active > a {
  background-color: #5c2c5a !important;
  color: $white !important;
}

.sticky-fixed-bottom {
  a, .uk-link {
    color: #cec0d6 !important;
    &.white {
      color: $white !important;
    }
    &.active {
      color: $primary !important;
    }
  }
}

a {
  &.active-pagination {
    color: $white !important;
  }
}

a {
  color: $primary !important;
}

.button-before-search {
  float: right;
  margin-right: 10px !important;
}

.caret-down-left-space {
  margin-left: 20px;
}

.sign-out-icon {
  padding-left: 10px;
  .uk-button {
    .sign-out-anchor {
      font-size: 16px !important;
      color: $white;
      svg.svg-inline--fa {
        padding: 0;
        height: 16px !important;
      }
    }

  }
}

#provides-assistance {
  .uk-button-secondary:disabled {
    cursor: wait;
  }
}
.refresh-screen-share-list {
  cursor: pointer;
  &.screen-share-list-refreshing {
    cursor: progress;
  }
}
.rejected-dossier-container {
  padding: 40px;
  .reject-assignment-button:disabled {
    background-color: lighten($red, 20%) !important;
  }

}
.accept-assignment-button:disabled {
  background-color: lighten($green, 20%) !important;
  cursor: progress;
}
.rhv-company-info-buttons {
  display: flex;
  padding-top: 10px;
  justify-content: flex-end;

  .help-from-rhv-company-request-button.uk-button {
    font-size: 16px !important;
    line-height: 20px !important;
    user-select: none;
    &.default-view {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      font-size: 18px !important;
      line-height: 24px !important;
    }
  }
  .more-info-about-rhv-company-button.uk-button {
    margin-right: 10px;
    line-height: 20px !important;
    font-size: 16px !important;
    &.default-view {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      font-size: 18px !important;
      line-height: 24px !important;
    }
  }
}

.uk-icon-button {
  &.add-dossier {
    width: auto !important;
    height: 40px !important;
    text-decoration: none !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.add-dossier-bottom {
  margin: 0 150px 50px 150px !important;
}

@media only screen and (max-width : 960px) {
  .uk-align-right {
    &.mobile-no-margin-left {
      margin-left: 0 !important;
    }
  }
}

.margin-top-button.uk-button {
  margin-top: 10px
}

.dossier-p p.margin-top-button-wrapper {
  margin-top: 1em;
}
.submit-button-inside-tooltip-wrapper{
  z-index: 5;
}

.delayed-cancel-button:disabled {
  cursor: progress;
}

.rhv-company-widget-trash-add-icons{
  span {
    margin-left:10px !important;
    margin-right: 0 !important;
  }
}

.dossier-container {
  .send-message-in-thread,
  .send-new-message-after-thread,
  .zero-threads-new-message-wrapper,
  .message-in-assignee-inbox{
    border: thin transparent solid;

    .message-box-send-message-button {
      margin-bottom: 0;
    }

    .message-box-file-upload-list {

      pointer-events: none;
      .delete-message-box-file {
        pointer-events: all;
      }
      position: relative;
    }

    .paperclip-mailbox {
      right: 11px !important;
    }
  }

}
.message-box-send-message-button {
  position: relative;
  right: 0;
  margin-right: 10px !important;
}
