.uk-icon-button {
  width: 45px !important;
  height: 45px !important;
}

.fa-lock-alt {
  color: $gray;
}

.link-is-submitting:hover {
  cursor: progress;
}
a.dossier-is-locked {
  cursor: not-allowed;
}
a.no-cursor {
  cursor: default;
}

.fa-filter {
  margin-right: 10px;
  margin-left: -10px;
}
.dossier-detail-shared-icon {
  font-size: 22px !important;
}
