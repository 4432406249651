#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

#g-map {
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
  border-radius: 10px;
  .gmnoprint {
    display: none;
    &.gm-style-cc {
      display: block;
    }
  }
  .gm-control-active.gm-fullscreen-control {
    //display: none;
  }
  img {
    max-width: 100% !important;
  }
}
