@import '../base/colors';


// The dossier hulp container
.dossier-hulp-container {
  @media only screen and (max-width: 480px) {
    //display: none;
  }

  // The hulp container body
  .uk-card-body {
    @media only screen and (max-width: 480px) {
      padding: 30px 25px;
    }
  }

  // The ranking number for a organisation
  .ranking-number {
    @media only screen and (max-width: 480px) {
      padding-top: 73px;
    }

    i {
      color: orange;
    }
  }

  // hr
  .hulp-hr {
    margin: 0;
  }

  // The container hulpheader
  .hulp-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #582F60;
    min-height: 61.5px;
    display: flex;
    border-bottom: none !important;
    &.status-null,
    &.status-vragenflow-afgerond {
      background-color: $primary !important;
      display: flex;
    }

    padding: 20px 30px;
    @media only screen and (max-width: 480px) {
      padding: 15px 25px;
    }

    h3 {
      color: #fff;
    }
  }
}

// The hulp container body
.dossier-hulp-body {

  .hulp-card-passende-hulp-first-status {
    border: $debug-border !important;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: flex-end;

    .MuiButton-root {
      text-transform: unset;
    }

    .grant-phone-permission-button {
      background-color: $green;
      color: $white;
    }
  }

  &.hulp-card-request-help-modal,
  &.suitable-help-start-search-filter-view {
    background-color: $white !important;
    margin-bottom: 10px;
    .suitable-help-subtitle {

    }
  }
}
