@import '../base/colors';
@import '../base/constants';

$tab-panel-width: 210px;
$main-content-width: 60vw;
.assignee-settings-outer-wrapper {
  .assignee-settings-wrapper {
    display: flex;

    .assignee-settings-tabs {
      position: fixed;
      width: $tab-panel-width;
      overflow: hidden;
    }

    .assignee-settings-content {
      width:$main-content-width;
      margin-left: $tab-panel-width;
      margin-bottom: $assignee-settings-margin-bottom;
      .assignee-settings-profile-card {
      .assignee-settings-profile-card-grid {
        overflow-y: hidden;
        &.specific-profile-card {
          margin-bottom: 0 !important;
        }
      }
        .assignee-settings-profile-card-footer {
         // border: thin green solid;
          padding: 30px;
          border-top: thin $light-gray solid;

        }
      }
      .assignee-settings-contact-card-form {
        .assignee-settings-contact-card {
          .assignee-settings-contact-card-scroll {
            /* if full page NOT */
            max-height: calc(#{$assignee-settings-height} - 411px);
            padding: 30px 40px;
            overflow-y: $assignee-settings-if-scroll;
            overflow-x: hidden;
            margin-bottom: 105px;
            .assignee-settings-contact-card-inner {
              padding: 0;
            }
            .assignee-settings-contact-card-outer {
              .general-information {
                margin-top: 40px;
              }
              .areas-of-law {
                margin-top: 40px;
                .assignee-settings-contact-card-content {
                  margin-left: 255px;
                }
                .from-contact-card {
                  border: thin transparent solid;
                }
              }
            }
            .assignee-settings-contact-card-footer {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              padding: 30px ;
              border-top: thin $light-gray solid;
            }
          }
        }
      }
      .assignee-settings-change-password-card-header,
      .assignee-setting-profile-card-header,
      .assignee-settings-layout-card-header,
      .assignee-settings-contact-card-header {
        padding-left: 40px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .settings-card-header {
      }
      .assignee-settings-change-password-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        width: $main-content-width;
        position: relative;
        pointer-events: all;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        .one-form {
          padding: 0!important;
          display: flex;
          pointer-events: all;
        }
        .assignee-settings-change-password-card-content-wrapper {
          padding: 30px 40px 30px 40px;
          max-height: calc(#{$assignee-settings-height} - 354px);
          margin-bottom: 105px;
          overflow-y: $assignee-settings-if-scroll;
          pointer-events: all;
          .label-wrapper {
            width: 255px;
          }
          .change-password-page {
            padding-left: 0;
            width: calc(100% - 295px);
          }

        }
        .assignee-settings-change-password-card-footer-wrapper {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 30px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $white;
          border-top: thin solid $light-gray;
          button.MuiButtonBase-root {
              font-size: 18px !important;
            }
        }
      }
      .assignee-settings-profile-visibility-switch-wrapper {
        pointer-events: all;
        padding:15px 40px;
        background-color: $accent-color-light;
        .assignee-settings-profile-visibility-switch {
          display: flex;
          justify-content: space-between;
          width: 380px;
          label {
            font-size: 16px !important;
          }
          .assignee-settings-profile-visibility-switch-object {
            .MuiSwitch-thumb {
            //  background-color: $white;

            }

            /*&.MuiSwitch-root {
              background-color: $primary;
              color: $primary;
              margin-top: 1px;
              margin-bottom: 1px;
              border-radius: 12px;
            }*/
          }
        }
      }
    }
  }
}
