@import '../base/colors';
@import '../base/constants';

.organisation-details-details-dossier {
  &.uk-align-right {
    @media only screen and (max-width : 960px) {
      margin-left: 0 !important;
    }
  }
  @media only screen and (max-width : 960px) {
    margin-bottom: -20px !important;
  }
  .header {
    margin: 5px;
    color: #653c6e;
  }
  .dossier-details {
    margin: 5px;
  }
}
.organisation-details-right-block {

  .rhv-detail-header {
    margin-bottom: $rhv-detail-header-margin;
    font-size: $rhv-detail-header-size!important;
  }
}
.organisation-details{
  .rhv-detail-header {
    margin-bottom:  $rhv-detail-header-margin !important;
    font-size: $rhv-detail-header-size!important;
  }
  @media only screen and (max-width: 960px) {
    padding: 30px !important;
  }
  @media only screen and (min-width : 961px) {
    padding: 20px 0 20px 30px !important;
  }
  .uk-search {
    span {
      width: 20px !important;
    }
  }
  .uk-search-input {
    font-size: 1.05rem !important;
    padding-left: 40px !important;
  }
  .list-hr {
    @media only screen and (max-width : 960px) {
      margin-left: -30px !important;
    }
    @media only screen and (min-width : 961px) {
      margin: 0 -40px 20px -30px !important;
    }
  }
  .organisation-description-title {
    color: #653c6e;
    margin-bottom: 10px;
  }
  .organisation-description-text {
    margin-top: 5px;
  }
  .organisation-characteristics-title {
    color: #653c6e;
    margin-bottom: 10px;
  }
  .organisation-characteristics-list {
    li:before {
      content: '';
      position: relative;
      left: -30px;
      width: 35px;
      height: 1.5em;
      margin-bottom: -1.5em;
      display: list-item;
      list-style-position: inside;
      text-align: right;
    }
    font-size: 16px;
    list-style: none !important;
    &.uk-list-disc {
      list-style: none;
    }
  }
  .organisation-details-second-block {
    @media only screen and (min-width : 961px) {
      margin-top: 20px;
      .uk-responsive-width {
        max-width: 50% !important;
      }
    }
  }
  .organisation-details-movie {
    color: #653c6e;
    margin-bottom: 10px;
  }
  .organisation-details-button {
    margin: 20px 0 40px 0;
  }
  .organisation-details-contact-details-header {
    color: #653c6e;
    margin-bottom: 10px;
  }
  .organisation-details-contact-details-list {
    font-size: 16px;
  }
  .organisation-details-hr-mobile {
    margin: 0 -30px;
  }
  .organisation-details-right-block {
    @media only screen and (max-width : 960px) {
      margin-top: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      margin-top: 0px;
    }
    .rhv-terms-of-service-section-wrapper {
      .rhv-terms-of-service-section-line-wrapper {
        display: flex;
        margin-bottom: 10px;
        .term-of-service-value {
          font-weight:  600;
        }
        div {
          font-size: 16px !important
        }
      }
    }
  }
  .organisation-details-right-block-conclusion {
    @media only screen and (max-width : 960px) {
      margin: 20px 0;
      .uk-align-right {
        margin-left: 0 !important;
      }
    }
    @media only screen and (min-width : 961px) {
      margin: 0 30px;
    }
    .fa-star {
      color: $yellow !important;
    }
    .the-progressbar {
      margin-top:15px;
      .the-communication-progressbar {
        margin-right: 10px;
        .uk-progress {
          margin: 5px 5px 0 0;
        }
      }
      .the-expertise-progressbar {
        margin-top: 5px;
      }
    }
    .name-person-review {
      margin: 0;
    }
    .date-and-year-review {
      margin: 0;
    }
    .total-score-review {
      .fa-star {
        color: #FCD274;
        margin-right: 10px;
      }
    }
    .the-progressbar {
      margin-top:15px;
      .the-progressbar-communication {
        margin-right: 10px;
        .the-progressbar-progress {
          margin: 5px 5px 0 0;
        }
      }
      .the-progressbar-expertise {
        margin-top: 5px;
      }
    }
  }

}
