@import '../base/colors';

nav {
  &.background-gradient-nav {
    background: $secondary-bg-color;
    background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
    background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
    background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  }
}
#confirm-profile-deletion,
#confirm-profile-restoration {
  .checkbox-left-flex-container {
    display: flex;
    .checkbox-left-flex-container-checkbox {

    }
    .checkbox-left-flex-container-text {
      margin-left: 20px;
    }
  }
}
#delete-dossier-for-client-dialog {
  .confirm-dossier-deletion {
    display: flex;
  }
  .confirm-dossier-deletion-text {
    margin-left: 20px;
  }
}
.navbar-desktop {
  img {
    height: 30px;
    margin: 20px
  }
  .navbar-space-right {
    padding-left: 45px;
  }
  .navbar-name-person {
    i {
      margin-bottom: 8px;
      padding-left: 10px;
    }
  }
  &.navbar-rechtzoekende {
    @media only screen and (min-width : 961px) {
      .uk-navbar-item {
        margin-right: 40px;
      }
    }
    .name-person {
      font-size: 18px !important;
      padding-right: 10px;
    }
  }
}
.uk-dropdown-nav,
.uk-navbar-item{
  font-size: 16px !important;
}
#client-profile-deleted.medium-min-width-popup-dialog {
  min-width: 530px;
}
.navbar-sidenav {
  right: 0;
  position: absolute;
}

.navbar-space-right {
  @media only screen and (max-width : 960px) {
    padding-left: 0 !important;
  }
}

.status-switcher {
  margin-right: 50px;

  .uk-subnav {
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 11px;
  }

  a {
    color: #a286a1 !important;
    &.sign-out-anchor {
      color: $white !important;
    }
  }

  .li-status-beschikbaar {
    width: 135px;
    padding-left: 0;
    border-right: 1px solid white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .li-status-bezet {
    width: 135px !important;
    padding-left: 0 !important;
    border-left: 1px solid white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    span {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .uk-subnav > .uk-active > a {
    &.status-beschikbaar {
      background-color: #95a126 !important;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-transform: none;
    }
  }
  .uk-subnav > .uk-active > a {
    &.status-bezet {
      background-color: #C43C3C !important;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      text-transform: none;
    }
  }

  .status-bezet {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-transform: none !important;
    span {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .status-beschikbaar {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-transform: none !important;
  }

  ::selection {
    color: #ffffff !important;
  }
}

.uk-navbar-nav > li > a {
  text-transform: none !important;
  color: rgba(255, 255, 255, 0.75) !important;
}

.uk-navbar-item {
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
}
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: $white !important;
}

.navbar-footer-beheerder {
  margin: 25px 280px !important;
}
a.navbar.uk-active {
  color: #ffffff !important;
}


.uk-navbar-nav,
.uk-navbar-nav  li,
.uk-navbar-nav  li  a{
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;

}
.uk-navbar-nav > li > a,{
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
}
