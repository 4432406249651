@import '../base/colors';

$widthThin: 8%;
$widthVeryThin: 5%;
$widthUltraThin: 3%;
$widthMedium: 12%;
$widthMediumWide: 15%;
$widthWide: 18%;
$refreshingOpacity: 0.2;
$badge-width: 16px;
$assigneeIconSpace: 15px;

.uk-list-disc {
  padding-left: 0 !important;
}
.hover-able-global-overview-list-wrapper {
  border-top: 0 !important;
}
.gi-law-urgent {
  color: $red;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-before:before {
  //display: none !important;
}
.global-overview-list{
  padding: 20px 30px 20px 30px !important;
  &.no-padding {
    padding: 0 !important;
  }
  &.no-margin {
    margin: 0 !important;
  }
  &.hover-able-global-overview-list {
    padding: 0 !important;
    .assignee-dossiers-summary-incoming {
      &:before {
        display: none;
      }
      padding: 20px 30px;
      .summary-total-wrapper{
        position: relative;
        height: 29px;

      }

      .summary-total {
        z-index: 1;
        pointer-events: all;
        position: absolute;
        margin-left: -10px;
        padding: 0 10px;
        top: 0;
      }
    }
    .hover-able-global-overview-list-inner {
      margin-left: 0;

      .no-padding-left {
        padding-left: 0 !important;
      }

      .mijn-dossiers-list {
        &.dossiers-list-refreshing {
          opacity: $refreshingOpacity;
        }
        list-style: none;
        position: relative;
        padding-left: 0 !important;
        margin-bottom: 0;
        .hover-able-global-overview-list-item,
        .hover-able-global-overview-list-item-off{
          padding: 20px 30px 20px 0;
          &.hover-able-global-overview-list-item-left {
            padding-left: 30px;
          }
          .padding-left-list {
            padding-left: 40px !important;
          }
          .width-8-percent {
            width: 8% !important;
          }
          .width-15-percent {
            width: 15% !important;
          }
          .width-5-percent {
            width: 5% !important;
          }
          .width-12-percent {
            width: 12% !important;
          }
          .width-20-percent {
            width: 18% !important;
          }
          .hover-able-global-overview-list-item-chevron {
            color: $light-gray;
          }
          &.hover-able-global-overview-list-item-slimmer {
            padding: 15px 30px;
            margin: 0 -1px !important;
          }
          margin-top: 0 !important;

          &:not(.uk-open) {
            overflow-y: auto;
            &:hover {
              background-color: $very-light-gray;
              cursor: pointer;
              .hover-able-global-overview-list-item-chevron {
                color: $primary;
              }
            }

            .dossier-id-wrapper {
              position: relative;
              display: flex;
              align-items: center;

              .dossier-has-not-been-read-icon {
                position: absolute;
                left: 10px;
                color: $primary;
                font-size: 12px;
                padding: 0 !important;
                margin: 0 !important;

                i {

                  background-color: $primary;
                  border-radius: 50%;
                  padding: 0 !important;
                  margin: 0 !important;
                }
              }
            }
          }

        }
        .hover-able-global-overview-list-item-off{
          &:not(.uk-open) {
            &:hover {
              background-color: transparent !important;
              cursor: auto;
            }
          }
        }
      }
      .pagination-widget-wrapper {
        margin: 20px 0 25px 0 !important;
      }

      .hover-able-global-overview-list-hr {
        padding: 0 !important;
        margin: 0 !important;
        &.pad-in {
          margin: 0 -1px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 20px !important;
  }

  .the-document {
    padding-left: 20%;
  }
  .admin-dossier-or-screen-list {
    list-style: none;
    &.dossiers-list-refreshing {
      opacity: $refreshingOpacity;
    }
  }
}
.client-search-for-company-form-hr {
  margin-top: 2px !important;

}

.overview-list-google {
  @media only screen and (max-width : 960px) {
    padding: 0 !important;
  }
  @media only screen and (min-width : 960px) {
    padding: 0 !important;
    margin-left: 30px !important;
  }

  .uk-search {
    span {
      width: 20px !important;
    }
  }
  .uk-search-input {
    font-size: 1.05rem !important;
    padding-left: 40px !important;
  }
  .list-hr {
    @media only screen and (max-width : 960px) {
      margin-left: -30px !important;
    }
    @media only screen and (min-width : 961px) {
      margin: 0 -40px 20px -30px !important;
    }
  }
}

.width-5-percent {
  width: 5% !important;
}
.vertical-hr {
  border:none;
  border-left: 1px solid #e5e5e5;
  height:100%;
  margin-top: -20px !important;
  width: 1px;
  position:absolute;
}
.vertical-hr-in-card {
  border:none;
  border-left: 1px solid #e5e5e5;
  height:100%;
  margin-top: -73px !important;
  width: 1px;
  position:absolute;
}


.active-tab-list {
  background-color: #F0EDF2;
  color: #5C2C5A;
  padding: 10px 0;
}

.no-active-tab-list {
  border: 1px solid #F0EDF2;
  background-color: #ffff;
  a {
    color: #a286a1 !important;
  }
}
$radius: 10px;
.tab-list-first {
  padding-right: 20px;
  border-top-left-radius: $radius;
  a {
    border-top-left-radius: $radius;
  }
}
.right-tab-extra {
  border-top-right-radius: $radius !important;
  a.right-tab-extra {
    border-top-right-radius: $radius !important;
  }

}

.fa-exclamation-triangle {
  color: $red;
}

.tab-list-second {
  padding: 0 0 0 -20px;
  margin-left: -20px;
  border-top-right-radius: 4px;
}

.tab-list-third {
  padding: 0 0 0 -20px;
  margin-left: 40px;
  border-top-right-radius: 4px;
}

.no-padding-left {
  @media only screen and (min-width : 961px) {
    padding: 0 !important;
  }
}
.mijn-dossiers-list-wrapper {
  padding-left: 0 !important;
}
.mijn-dossiers-list {
  &.dossiers-list-refreshing {
    opacity: $refreshingOpacity;
  }
  &.header {
    color: $white
  }
  .margin-center-left {
    margin: auto 0;
  }
  .mijn-dossiers-number {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 10%;
      &.assignee-dossiers-number {
        width: 8%;
      }
    }
  }
  .mijn-dashboard-number-inbox {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 20%;
    }
  }
  .mijn-dashboard-actie-inbox {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 45%;
    }
  }
  .mijn-dossiers-subject {
    @media only screen and (max-width : 370px) {
      width: 32% !important;
    }
    @media only screen and (min-width : 482px) and (max-width : 960px)  {
      &.header {
        padding-left: 0;
      }
    }
    @media only screen and (max-width : 960px) {
      width: 31%;
    }
    @media only screen and (min-width : 961px) {
      &.assignee-dossiers-subject {
        width: $widthWide;
      }
      width: 13%;
    }
  }
  .mijn-dossiers-date {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 11%;
    }
    @media only screen and (min-width : 1500px) {
      width: 10%;
    }
  }
  .mijn-dossiers-support-name {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 11%;
    }
    @media only screen and (min-width : 1500px) {
      width: 20%;
    }
  }
  .mijn-dashboard-date {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 15%;
    }
    @media only screen and (min-width : 1500px) {
      width: 20%;
    }
  }
  .mijn-dashboard-support-name {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 18%;
    }
    @media only screen and (min-width : 1500px) {
      width: 22%;
    }
  }
  .mijn-dossiers-status,
  .mijn-dashboard-status{
    @media only screen and (min-width : 482px) and (max-width : 960px)  {
      &.header {
        padding-left: 50px;
      }
    }
    @media only screen and (max-width : 960px) {
      width: 30%;
    }
    @media only screen and (min-width : 961px) {
      width: 9%;
    }
    @media only screen and (min-width : 1500px) {
      width: 10%;
    }
    .status-nog-niet-afgerond {
      color: $red;
    }
    .status-vragenflow-afgerond,
    .status-lives-in-leiden-area{
      color: $primary;
    }
    .status-wacht-op-reactie {
      color: $warning;
    }
    .status-wachten-op-helpdesk {
      color: $warning;
    }
    .status-wachten-op-hulpverzoek {
      color: $warning;
    }
    .status-rhv-assignee-internal-transfer,
    .status-dossier-goedgekeurd {
      color: $green;
    }
    .status-dossier-in-behandeling {
      color: $primary;
    }
    .status-dossier-afgerond {
      color: $green;
    }
    .status-client-deleted-dossier {
      color: $warning;

    }
    .status-elapsed-but-not-accepted-by-assignee-admin,
    .status-elapsed-after-accepted-by-assignee-admin,
    .status-stopped-by-assignee-worker,
    .status-rejected-by-assignee-admin,
    .status-client-stopped-dossier
    {
      color: $danger;
    }
  }
  .mijn-dashboard-status {
    @media only screen and (min-width : 482px) and (max-width : 960px)  {
      &.header {
        padding-left: 50px;
      }
    }
    @media only screen and (max-width : 960px) {
      width: 30%;
    }
    @media only screen and (min-width : 961px) {
      width: 13%;
    }
    @media only screen and (min-width : 1500px) {
      width: 14%;
    }
    .status-nog-niet-afgerond {
      color: $red;
    }
    .status-wacht-op-reactie {
      color: $warning;
    }
  }
  a {
    &.share-icon {
      margin-right:20px;
      &.share-icon-open {
        margin-right:13.25px
      }
      svg:focus {
        outline-color: transparent;
      }
    }
  }
  .mijn-dossiers-actions{
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 22%;
    }
    @media only screen and (min-width : 1500px) {
      width: 14%;
    }
  }
  .mijn-dashboard-actions{
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 7%;
    }
    @media only screen and (min-width : 1500px) {
      width: 6%;
    }
    .fa-exclamation-triangle {
      margin-left: 20px;
      color: $red;
    }
  }
  .mijn-dashboard-time-left{
    text-align: left !important;
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 12%;
    }
    @media only screen and (min-width : 1500px) {
      width: 10%;
    }
    .fa-exclamation-triangle {
      margin-left: 20px;
      color: $red;
    }
  }
}

.mijn-dossiers-title {
  @media only screen and (max-width : 960px) {
    padding-left: 20px;
  }
  @media only screen and (min-width : 961px) {
    padding-left: 50px;
  }
  font-weight: 400;
  h2 {
    padding-bottom: 10px;
  }
}
a {
  &.share-icon {
    svg:focus {
      outline-color: transparent;
    }
  }
}

.list-button {
  margin-top: 25px !important;
}

.mijn-dashboard-title {
  cursor: pointer;
  @media only screen and (max-width : 960px) {
    padding-left: 20px;
    &.suitable-help-pre-header {
      padding-left: 0;
    }
  }
  @media only screen and (min-width : 961px) {
    padding-left: 41px;
    &.suitable-help-pre-header {
      padding-left: 3px;
      h2 {
        font-size: 30px !important;
        padding-left: 0;

        .suitable-help-pre-header-left-arrow {
          margin-right: 15px;
          color: $accent-color;
        }
      }
    }
  }
  font-weight: 400;
}


.button-bottom-list {
  @media only screen and (max-width : 960px) {
    padding-right: 20px;
  }
  @media only screen and (min-width : 961px) {
    padding-right: 50px;
  }
  font-weight: 400;
}

.assignee-dossier-list-header,
.assignee-dossier-list-list,
.assignee-incoming-dossier-header-wrapper{
  border-bottom: 0 !important;
  margin-bottom: -1px;
  .dossier-header {
    padding-left: 0 !important;
  }
  .assignee-incoming-dossier-header-inner-wrapper {

  }


  .helpdesk-list {
    &.header {
      color: $white;
    }

    .margin-center-left {
      margin: auto 0;
    }

    .helpdesk-list-number {
      @media only screen and (max-width: 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width: 961px) {
        width: $widthThin;
        &.helpdesk-list-number-header {
          padding-left: 40px !important;
        }
      }
    }

    .helpdesk-list-sender {
      @media only screen and (max-width: 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width: 961px) {
        width: $widthWide;
      }
    }

    .helpdesk-list-subject {
      @media only screen and (max-width: 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width: 961px) {
        width: $widthWide;
      }
    }

    .helpdesk-list-date {
      @media only screen and (max-width: 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width: 961px) {
        width: $widthThin;
      }
    }

    .helpdesk-list-status {
      @media only screen and (max-width: 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width: 961px) {
        width: $widthMediumWide;
      }

    }
    .helpdesk-list-status-time-left {
      @media only screen and (max-width : 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width : 961px) {
        width:$widthMedium;
      }

    }
    .helpdesk-list-support-name {
      @media only screen and (max-width : 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width : 961px) {
        width: $widthMedium;
      }
    }
    .helpdesk-list-extra-space {
      @media only screen and (max-width : 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width : 961px) {
        width: $widthWide;
      }
    }
    .rechtzoekende-list-actions {
      @media only screen and (max-width : 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width : 961px) {
        width: $widthMediumWide;
      }
    }
  }

}
.helpdesk-list {
  &.header {
    color: $white;
  }
  .margin-center-left {
    margin: auto 0;
  }
  .helpdesk-list-number {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: $widthThin;
      &.helpdesk-list-number-header {
        padding-left: 10px !important;
      }
    }
  }
  .helpdesk-list-sender {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: $widthWide;
    }
  }
  .helpdesk-list-subject {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: $widthWide;
    }
  }
  .helpdesk-list-date {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: $widthThin;
    }
  }
  .helpdesk-list-status {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 12%;
    }

  }
  .helpdesk-list-support-name {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 12%;
    }
  }
  .helpdesk-list-extra-space {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 20%;
    }
  }
  .rechtzoekende-list-actions {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 16%;
    }
  }
}

.layout-list-btn-and-search {
  margin-top: -5px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .uk-search-default {

  }
  .admin-dossier-and-screen-share-list-list-search {
    user-select: none;
  }
  .admin-dossier-and-screen-share-list-list-search-select {
    margin-right: 8px;
  }

  .admin-dossier-and-screen-share-list-list-search-wrapper {
    width: 370px;
    .MuiAutocomplete-root {
      width: 100%;
      .MuiFormControl-root {
        margin-right: 0 !important;
        padding-right: 50px !important;
        .MuiInput-root {
          padding-left: 15px;
          padding-bottom: 0;
          //margin-right: -50px !important;
          .MuiInput-input {
            max-width: 270px;
          }
          .MuiAutocomplete-endAdornment {
            margin-right: -50px !important;
            .MuiButtonBase-root {

              &.MuiAutocomplete-clearIndicator {
              }
            }
          }

          &:after,
          &:before {
            display: none;
          }
        }
      }
    }
    .search-icon-wrapper {
      width: 40px;
      span {
        z-index: 2;
        width: 40px;
      }
    }
  }
}
.global-list {
  width: 25% !important;
}

.one-icon-list-right {
  margin-right: 35px !important;
  height: 1.4em;
  cursor: pointer;
}

.list-tree-icons {
  margin-right: 30px !important;
  .first-icon {
    height: 1.4em;
    margin-right: 11px;
    cursor: pointer;
  }
  .second-icon {
    margin-right: 5px;
    height: 1.4em;
    cursor: pointer;
  }
}

.ul-no-space-top {
  margin-top: -20px;
}

.hr-list-one-block {
  margin: 20px -40px !important
}

.inbox-list {
  .inbox-list-dossier-nr {
    width: 8%;
  }
  .inbox-list-sender {
    width: 10%;
  }
  .inbox-list-subject {
    width: 15%;
  }
  .inbox-list-date {
    width: 8%;
  }
  .inbox-list-time {
    width: 8%;
  }
  .inbox-list-status {
    width: 10%;
  }
  .inbox-list-actions {
    width: 18%;
    &.submit-button-inside-tooltip-wrapper {
      width: 12%;
    }
    a {
      margin: 0 35px;
    }
  }
  .accordion-position {
    position: relative;
  }
  .fa-paperclip {
    right: 90px !important;
  }
  .uk-accordion .hr-inbox-list.hr-without-space-list {
    margin: 0 -30px 20px -30px  !important;
  }
  .status-afwachting-reactie {
    color: $warning
  }
  .status-reactie-vereist {
    color: $green
  }
  .status-afgehandeld {
    color: $primary;
  }
}
.message-box-file-list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: $primary;
  a {
    text-decoration: none !important;
  }
}
.message-box-file-upload-list {
  list-style: none;
  text-align: right;
  margin: -10px 0 0 0 ;
  padding: 15px;
  color: $primary;
}
.hidden-delete-spacer span{
  display: inline-block;
  min-width: 0.875em;
}
.hidden-upload-spacer span{
  display: inline-block;
  min-width: 0.75em;
}
.help-card-document-wrapper {

  .upload-delete-icons,
  .missing-document-icons {
    position: absolute;
    right: 63px;

  }
  @media only screen and (max-width : 767px) {
    .upload-delete-icons,
    .missing-document-icons {
      right: 15px;
    }
    .the-document-name {
      max-width: 80%
    }
  }
}
.help-card-answer-wrapper {
  @media only screen and (max-width : 767px) {

  }
}
.new-mail-item-in-list {
  .fa-envelope {
    font-size: 1.5em;
    color: #653c6e;
  }
  .mail-badge {
    margin: -33px 0 0 -10px !important;
  }
}
.reject-dossier-item-in-list {
  z-index: 20;
}

// list header with tabs
.dossier-header-with-tabs {
  background-color: #582F60;
  border-top-right-radius: 3px;
  padding: 20px 30px;
  @media only screen and (max-width : 480px) {
    padding: 15px 25px;
  }
  @media only screen and (max-width : 960px) {
    border-top-left-radius: 3px;
  }
  h3 {
    color: #fff;
  }
  .status-header {
    margin-top: -30px;
    p {
      color: $white;
    }
    span {
      color: $warning;
    }
  }
}
.message-box-list-item
{
  margin: 0 0 23px 0;
}

.help-card-suitable-help-rhv-list {
  list-style: none;
  padding: 0;
  font-size: 16px;
  font-style: italic;
  a,
  a:link,
  a:visited,
  a:active {
    color: #666 !important;
  }
  .help-card-suitable-help-rhv-list-header {
    font-weight: 700;
    font-style: normal;
    display: flex;
    position: relative;
    div.phone-permission-not-shared,
    div.phone-permission-shared {
      cursor: pointer;
      font-size: 14px!important;
      max-height: 0;
      overflow: visible;
      margin-left: 10px;
    }
    .phone-permission-shared {
      color: $red;

    }
    .phone-permission-not-shared {
      color: $green;
    }
  }
}
.rhv-company-list-for-client {
  &.company-list-refreshing {
    opacity: $refreshingOpacity
  }
}
.client-dossier-list {
  list-style-type: none;
  padding-left: 0;
  &.client-dossier-list-refreshing {
    opacity: $refreshingOpacity;
  }
}
.assignee-dossier-list-outer-wrapper {
  @media only screen and (min-width : 1550px) and (min-width : 1700px) {
    zoom: 100%;
  }
  @media only screen and (min-width : 1450px) and (max-width : 1549px)  {
    zoom: 95%;
  }
  @media only screen and (min-width : 1370px) and (max-width : 1449px)  {
    zoom: 90%;
  }
  @media only screen and (min-width : 1300px) and (max-width : 1369px)  {
    zoom: 85%;
  }
  @media only screen and (min-width : 1220px) and (max-width : 1299px)  {
    zoom: 80%;
  }
  @media only screen and (min-width : 1150px) and (max-width : 1219px)  {
    zoom: 75%;
  }
  @media only screen and (min-width : 1050px) and (max-width : 1149px)  {
    zoom: 70%;
  }
  @media only screen and (max-width : 1049px)  {
    zoom: 65%;
  }

  .assignee-dossier-list-wrapper,
  .assignee-dossier-list-header {
    .assignee-dossier-list-header,
    .assignee-dossier-list-item {
      .assignee-dossier-list-header-hash-id,
      .assignee-dossier-list-item-hash-id {
        width: $widthThin;

      }
      .assignee-dossier-list-header-client-name,
      .assignee-dossier-list-item-client-name
      {
        width: $widthMedium;

      }
      .assignee-dossier-list-header-subject,
      .assignee-dossier-list-item-subject{
        width: $widthMedium;

      }

      .assignee-dossier-list-header-submission-date,
      .assignee-dossier-list-item-submission-date {
        width: $widthThin;

      }

      .assignee-dossier-list-header-status,
      .assignee-dossier-list-item-status {
        width: $widthMedium;

      }

      .assignee-dossier-list-item-status {
        // These classes correspond to the status constants DOSSIER.STATUS.PAIRS
        // might also want to check the texts there as well

        .status-vragenflow-afgerond,
        .status-lives-in-leiden-area,
        .status-lives-in-leiden-area,
        .status-wacht-op-reactie,
        .status-more-info-requested,
        .status-wachten-op-hulpverzoek,
        .status-wachten-op-helpdesk,
        .status-rhv-assignee-internal-transfer,
        .status-rhv-assignee-external-transfer,
        .status-rejected-by-assignee-admin,
        .status-rejected-by-assignee-worker,
        .status-dossier-afgerond,
        .status-client-deleted-dossier,
        .status-dossier-in-behandeling,
        .status-rhv-assignee-external-transfer-proposed,
        .status-elapsed-but-not-accepted-by-assignee-admin,
        .status-dossier-goedgekeurd {
          color: white;
          border-radius: 5px;
          text-align: center;
          padding: 4px;
        }

        .status-vragenflow-afgerond {
          background: $primary;
        }

        .status-lives-in-leiden-area {
          background: $primary;
        }

        .status-wacht-op-reactie {
          background: $warning;
        }

        .status-more-info-requested {
          background: $warning;
        }

        .status-wachten-op-hulpverzoek {
          background: $warning;
        }

        .status-rhv-assignee-external-transfer-proposed {
          background: $warning;
        }
        .status-wachten-op-helpdesk {
          background: $warning;
        }

        .status-rhv-assignee-internal-transfer,
        .status-rhv-assignee-external-transfer{
          background: $primary;
        }


        .status-rejected-by-assignee-admin,
        .status-rejected-by-assignee-worker,
        .status-stopped-by-assignee-worker,
        .status-rejected-by-assignee-admin,
        .status-client-stopped-dossier
        {
          background: $danger;
        }

        .status-dossier-afgerond {
          background: $green;
        }

        .status-dossier-in-behandeling {
          background: $warning;
        }

        .status-elapsed-but-not-accepted-by-assignee-admin {
          background: $danger;
        }

        .status-dossier-goedgekeurd {
          background: $primary;
        }
        .status-client-deleted-dossier {
          background: $warning;

        }
      }

      .assignee-dossier-list-header-assignee-worker,
      .assignee-dossier-list-item-assignee-worker {
        width: $widthMedium;

      }

      .assignee-dossier-list-header-notifications {
        width: 15% !important;
      }

      .assignee-dossier-list-header-notifications,
      .assignee-dossier-list-item-notifications {
        width: $widthMedium;
        position: relative;
        margin-bottom: -5px;

        .assignee-dossier-list-item-notification-tasks,
        .assignee-dossier-list-item-notification-email{
          .total-tasks,
          .total-emails {
            font-size: 25px !important;
            z-index: 20;
            display: block;
            position: absolute;
            top: -5px;
            left: 17px;
            padding: 0;
            background-color: $red ;
            height: $badge-width;
            width: $badge-width;
            min-width: $badge-width;
            min-height: $badge-width;
            max-width: $badge-width;
            max-height: $badge-width;
            border-radius: 50%;
            text-align: center;
            .notification-badge-text {
              line-height: 16px;
              position: relative;
              color: $white !important;
              font-size: 11px !important;
              top: -14px;
              left: -0px;
            }
          }
          .total-emails {
            //left: 39px;
          }
        }
        .assignee-dossier-list-item-notification-shared{
          margin-right: $assigneeIconSpace;
          .is-shared {
            font-size: 25px !important;
            z-index: 20;
            display: inline-block;
            color: $green;
            &.by-department {
              color: $orange;
            }
          }
        }
        .assignee-dossier-list-item-notification-email {
          position: relative;
          font-size: 25px !important;
          margin-right: $assigneeIconSpace;
          img {
            position: relative;
            top: -1px;
          }
        }
        .assignee-dossier-list-item-notification-tasks {
          position: relative;
          font-size: 25px !important;
          margin-right: $assigneeIconSpace;
          img {
            margin-left: 14px;
            position: relative;
            top: -3px;
          }

        }

      }

      .assignee-dossier-list-header-loading-icon,
      .assignee-dossier-list-item-chevron {
        width: $widthUltraThin;
        padding-left: 0;
      }
    }
  }
}

.pagination-widget-wrapper {
  margin: 20px 0 25px 0 !important;
}
